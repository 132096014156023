import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Radio, Tooltip, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { Checkbox } from 'components/Checkboxes'
import LibraryTypeIcon from 'components/LibraryTypeIcon'
import { Text, TextWithTooltip } from 'components/Typography'
import { ActiveStatus, InactiveStatus } from 'components/StatusText'
import { getUrlPrefix } from 'utils'
import {
  entityConstants,
  routeByName,
  templateConstants
} from 'constants/index'
import { getTemplatePreview } from 'actions/templateActions'
import { libraryViewSelector } from 'selectors/libraryViewSelectors'

const styles = ({ palette, type, spacing }) => ({
  listItem: {
    padding: '8px 0',
    borderBottom: `1px solid ${palette[type].sideModal.content.border}`
  },
  spacing: {
    margin: `0 ${spacing(1)}px`
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none'
  }
})

const TemplateListRow = ({
  item,
  onSelect,
  selectType = 'none',
  selected,
  withLink,
  classes
}) => {
  const dispatch = useDispatch()
  const {
    id,
    templateType,
    title,
    status,
    noOfZone,
    width,
    height,
    orientation
  } = item

  const { t } = useTranslation('translations')
  const viewSettings = useSelector(libraryViewSelector)
  const view = viewSettings[entityConstants.TemplateLibrary]

  const handleSelect = useCallback(() => {
    onSelect(item)
  }, [onSelect, item])

  const templateTypeInfo = useMemo(
    () =>
      templateConstants.templateTypes[templateType] ||
      templateConstants.templateTypes.Standard,
    [templateType]
  )

  const resolution = useMemo(() => {
    return width && height && ![width, height].includes('x')
      ? `${width} x ${height}`
      : t('Responsive')
  }, [width, height, t])

  const renderTitle = useMemo(() => {
    return withLink ? (
      <Link
        to={getUrlPrefix(
          `${
            view === 'grid'
              ? routeByName.template.grid
              : routeByName.template.list
          }?id=${id}&exact=true`
        )}
        target="_blank"
        className={classNames(classes.link)}
      >
        <TextWithTooltip
          color="title.primary"
          weight="bold"
          maxWidth={120}
          rootClassName={classes.spacing}
        >
          {title}
        </TextWithTooltip>
      </Link>
    ) : (
      <TextWithTooltip
        color="title.primary"
        weight="bold"
        maxWidth={120}
        rootClassName={classes.spacing}
      >
        {title}
      </TextWithTooltip>
    )
  }, [id, title, withLink, classes, view])

  const handlePreviewClick = useCallback(() => {
    dispatch(getTemplatePreview({ id, orientation }))
  }, [id, dispatch, orientation])

  return (
    <Grid container alignItems="center" className={classes.listItem}>
      <Grid container alignItems="center" item xs={5}>
        {selectType === 'checkbox' && (
          <Checkbox
            checked={selected}
            onClick={handleSelect}
            className={classes.spacing}
          />
        )}
        {selectType === 'radio' && (
          <Radio checked={selected} onClick={handleSelect} />
        )}
        <Tooltip arrow title={templateTypeInfo.title}>
          <LibraryTypeIcon
            onClick={handlePreviewClick}
            color={templateTypeInfo.color}
            icon={templateTypeInfo.icon}
            iconHelperClass={templateTypeInfo.iconHelperClass}
            className={classes.spacing}
          />
        </Tooltip>
        {renderTitle}
      </Grid>
      <Grid container item xs={7} justify="space-between" alignItems="center">
        <Grid item>
          <Text>{resolution}</Text>
        </Grid>
        <Grid item>
          <Text>{t('count zone', { count: noOfZone || 0 })}</Text>
        </Grid>
        {status === 'Active' ? (
          <ActiveStatus title={t('Active')} className={classes.spacing} />
        ) : (
          <InactiveStatus title={t('Inactive')} className={classes.spacing} />
        )}
      </Grid>
    </Grid>
  )
}

TemplateListRow.propTypes = {
  item: PropTypes.object.isRequired,
  selectType: PropTypes.oneOf(['checkbox', 'radio', 'none']),
  selected: PropTypes.bool,
  withLink: PropTypes.bool,
  onSelect: PropTypes.func
}

export default withStyles(styles)(TemplateListRow)

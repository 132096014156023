import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Grid, useMediaQuery, withStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import PageContainer from 'components/PageContainer'
import { CircularLoader } from 'components/Loaders'
import useUrlSearchParams from 'hooks/useUrlSearchParams'
import { approveContent, clearContentApproveData } from 'actions/configActions'
import { contentApproveSelector } from 'selectors/configSelectors'
import useSnackbar from 'hooks/useSnackbar'
import { isTruthy } from 'utils/generalUtils'
import { configService } from 'services'
import { BlueButton } from 'components/Buttons'
import Spacing from 'components/Containers/Spacing'
import PreviewCard from './PreviewCard'
import { MediaDependencyDialog, ScreenPreviewModal } from 'components/Modal'
import { getPreviewFromUrl } from 'actions/mediaActions'
import { useNotifyAnalyzer } from 'hooks/tableLibrary'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import TableLibrarySelectAllCheckbox from 'components/TableLibrary/TableLibrarySelectAllCheckbox'
import { Text } from 'components/Typography'

const HEADER_HEIGHT = 65
const FOOTER_HEIGHT = 39

const styles = ({ colors, spacing, palette, type }) => ({
  root: {
    minHeight: `calc(99vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
    padding: spacing(3)
  },
  rootWithFixedFooter: {
    minHeight: `calc(99vh - ${HEADER_HEIGHT}px)`,
    paddingBottom: spacing(5)
  },
  contentWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(2)
  },
  contentWrapperSmall: {
    justifyContent: 'center'
  },
  pageContainer: {
    maxWidth: 1600,
    margin: '0 auto'
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 3
  },
  pageContainerSmall: {
    '& h2, h3': {
      fontSize: '1.25rem'
    }
  },
  icon: {
    color: colors.highlight
  },
  footerWrap: {
    padding: `8px 21px`,
    backgroundColor: palette[type].pages.fonts.footer.background,
    borderTop: `1px solid ${palette[type].pages.fonts.footer.border}`
  },
  footerWrapFixed: {
    position: 'fixed',
    bottom: 0
  }
})

const exceptions = ['SaveMediaRelatedToSmartPlaylistErrorException']

const ContentApprove = ({ classes, t }) => {
  const dispatch = useDispatch()
  const params = useUrlSearchParams()
  const { showSnackbar } = useSnackbar()
  const matches = useMediaQuery('(max-width:1600px)')
  const matchesSm = useMediaQuery('(max-width:550px)')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const contentApproveReducer = useSelector(contentApproveSelector)

  const { response, error, isFetching } = contentApproveReducer

  const [status, setStatus] = useState('')
  const [content, setContent] = useState([])
  const [loading, setLoading] = useState(true)

  const [selected, setSelected] = useState([])

  const singleContent = !!params.id

  const getSelectedIdsByContentType = (contentType, outputName) => {
    const selectedItemsByType = selected.filter(
      item => item.contentType === contentType
    )

    return selectedItemsByType.length
      ? { [outputName]: selectedItemsByType.map(({ id }) => id).join(',') }
      : {}
  }

  const handleApproveContent = ({ confirmationToSave }) => {
    const {
      expires,
      userId,
      signature,
      contentType,
      id,
      clientId,
      start,
      end
    } = params

    let requestData

    if (singleContent) {
      requestData = {
        contentType,
        expires,
        id,
        userId,
        signature,
        confirmationToSave
      }
    } else {
      requestData = {
        clientId,
        end,
        expires,
        start,
        userId,
        signature,
        confirmationToSave,
        ...getSelectedIdsByContentType('Media', 'mediaIds'),
        ...getSelectedIdsByContentType('Playlist', 'playlistIds'),
        ...getSelectedIdsByContentType('Template', 'templateIds'),
        ...getSelectedIdsByContentType('Schedule', 'scheduleIds')
      }
    }

    dispatch(approveContent(requestData))
  }

  const fetchPreviewData = async () => {
    const {
      clientId,
      start,
      end,
      id,
      contentType,
      expires,
      userId,
      signature
    } = params

    try {
      //the order of the fields is important for the BE
      const response = await configService.approveContent({
        ...(contentType && { contentType }),
        ...(clientId && { clientId }),
        ...(end && { end }),
        expires,
        ...(id && { id }),
        ...(start && { start }),
        userId,
        signature,
        action: 'preview'
      })
      if (response?.content) {
        setContent(response.content)
        if (!response.content.length) {
          setStatus(t('No data to approve'))
        }
      }
    } catch (e) {
      setStatus(t('Invalid approval URL'))
    } finally {
      setLoading(false)
    }
  }

  const toggleSelect = data => {
    if (singleContent) {
      return
    }
    const target = selected.find(item => item.id === data.id)

    if (target) {
      setSelected(prevState => prevState.filter(item => item.id !== data.id))
    } else {
      setSelected(prevState => [...prevState, data])
    }
  }

  const unselectAll = () => {
    setSelected([])
  }

  const handleSelectAll = ({ target: { checked } }) => {
    if (content.length && checked) {
      setSelected(content)
    } else {
      unselectAll()
    }
  }

  const showPreview = url => {
    dispatch(getPreviewFromUrl(url))
  }

  useNotifyAnalyzer(
    () => {
      unselectAll()
      if (singleContent || selected.length === content.length) {
        setContent([])
        setStatus(t('No data to approve'))
      } else {
        fetchPreviewData()
      }
    },
    () => {},
    enqueueSnackbar,
    closeSnackbar,
    'Content',
    [contentApproveReducer]
  )

  useEffect(() => {
    const { clientId, start, end, id, contentType } = params

    if (isTruthy(clientId, start, end) || isTruthy(id, contentType)) {
      fetchPreviewData()
    } else {
      setStatus(t('Invalid approval URL'))
    }
    // eslint-disable-next-line
  }, [params])

  useEffect(
    () => {
      const hideErrorNotification =
        error?.code === 409 && exceptions.includes(error?.exception)

      if (
        !status &&
        (response.status || error.message) &&
        !hideErrorNotification
      ) {
        showSnackbar(
          ...(response.status
            ? [response.status, 'success']
            : [error.message, 'error'])
        )
      }
    },
    // eslint-disable-next-line
    [response, error]
  )

  const onNegativeAction = () => dispatch(clearContentApproveData())

  return (
    <>
      <PageContainer
        pageTitle={t('Content Approval')}
        subHeader={false}
        pageHeaderClassName={classes.header}
        pageContainerClassName={classNames(classes.pageContainer, {
          [classes.pageContainerSmall]: matches
        })}
        selectedCount={selected.length}
        ActionButtonsComponent={
          <Spacing variant={0} paddingRight={1}>
            <BlueButton
              onClick={handleApproveContent}
              iconClassName="fa-regular fa-circle-check"
              disabled={
                !content.length ||
                isFetching ||
                (!singleContent && !selected.length)
              }
            >
              {t('Approve')}
            </BlueButton>
          </Spacing>
        }
      >
        <Grid
          className={classNames(classes.root, {
            [classes.rootWithFixedFooter]: matchesSm
          })}
        >
          <div
            className={classNames(classes.contentWrapper, {
              [classes.contentWrapperSmall]: matchesSm
            })}
          >
            {(isFetching || loading) && <CircularLoader />}
            {!isFetching && !loading && !content.length && (
              <EmptyPlaceholder text={status} />
            )}
            {content.map(({ id, contentType, thumbUrl, title }) => (
              <PreviewCard
                key={id}
                id={id}
                contentType={contentType}
                thumbUrl={thumbUrl}
                title={title}
                onImageClick={showPreview}
                singleContent={singleContent}
                selected={selected.some(item => item.id === id)}
                onSelect={toggleSelect}
              />
            ))}
          </div>
        </Grid>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classNames(classes.footerWrap, {
            [classes.footerWrapFixed]: matchesSm
          })}
        >
          <TableLibrarySelectAllCheckbox
            isSelectAllDisabled={!content.length || singleContent}
            allSelected={content.length && selected.length === content.length}
            onSelectAllClick={handleSelectAll}
          />
          {!!content.length && !singleContent && (
            <Text color="title.primary" weight="bold">
              {content.length} {t('Total Item', { count: content.length })}
            </Text>
          )}
        </Grid>
        <ScreenPreviewModal targetReducer="media" />
        <MediaDependencyDialog
          err={error}
          handleNegativeAction={onNegativeAction}
          handlePositiveAction={() =>
            handleApproveContent({ confirmationToSave: 1 })
          }
        />
      </PageContainer>
    </>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(ContentApprove)
)

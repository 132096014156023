import React, { useEffect, useRef } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { useLazyPingQuery } from 'api/appApi'
import { routeByName } from 'constants/index'

const useStyles = makeStyles({
  root: {
    background:
      'radial-gradient(51.81% 51.81% at 50% 68.1%, #0027C3 0%, #011875 71%, #00135F 100%)',
    position: 'absolute',
    inset: 0
  }
})

const PageWrapper = ({ children }) => {
  const classes = useStyles()
  const intervalRef = useRef(null)
  const history = useHistory()

  const [ping, result] = useLazyPingQuery()

  useEffect(() => {
    const interval = setInterval(() => ping(), 30000)
    intervalRef.current = interval

    return () => {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (result.isSuccess) {
      history.push(routeByName.signIn.root)
    }
    // eslint-disable-next-line
  }, [result])

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      {children}
    </Grid>
  )
}

export default PageWrapper

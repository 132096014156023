import React, { useMemo } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

import MaterialPopup from 'components/Popup/MaterialPopup'
import Tooltip from 'components/Tooltip'
import { getDeviceTimeWithOffset } from 'utils/getDeviceTimeWithOffset'
import { DateTimeView } from 'components/TableLibrary'
import { TextSizeTooltip } from 'utils'
import { Card } from 'components/Card'
import { TextWithTooltip } from 'components/Typography'
import _get from 'lodash/get'
import { VIRTUAL_DEVICE_ALIAS } from 'constants/deviceConstants'
import NetworkCloseIcon from 'components/Icons/NetworkCloseIcon'

const styles = ({ palette, type, typography, colors }) => ({
  popupRoot: {
    width: 350,
    zIndex: 100
  },
  popupContent: {
    padding: 12,
    background: colors.background.primary[type]
  },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    border: `solid 1px ${palette[type].deviceCard.border}`,
    boxShadow: `0 2px 4px 0 ${palette[type].deviceCard.shadow}`,
    borderRadius: '7px',
    position: 'relative'
  },
  moreInfoMenuDropdownContainer: {
    width: '360px'
  },
  cardHeader: {
    padding: '15px 20px 10px',
    marginBottom: 0,
    backgroundColor: palette[type].deviceCard.header.background,
    borderRadius: '7px 7px 0 0',

    '& a': {
      textDecoration: 'none'
    }
  },
  cardHeaderText: {
    fontSize: '16px'
  },
  content: {
    flexGrow: '1',
    padding: '10px 20px 45px'
  },
  detailRow: {
    padding: '5px',
    borderBottom: `1px solid ${palette[type].deviceCard.row.background}`
  },
  detailLabel: {
    ...typography.lightText[type],
    paddingTop: '3px'
  },
  detailValue: {
    ...typography.darkAccent[type],
    lineHeight: '1em'
  },
  cardTitle: {
    ...typography.darkAccent[type],
    fontSize: '0.9rem',
    textAlign: 'left'
  },
  cardSubTitle: {
    ...typography.lightText[type],
    fontSize: '0.7rem',
    textAlign: 'left'
  },
  textSmall: {
    ...typography.subtitle[type]
  },
  statusIcon: {
    fontSize: '25px',
    color: '#808080'
  },
  rightAlignText: {
    textAlign: 'right'
  },
  networkIconWrapper: {
    display: 'inline-flex',
    height: 24,
    width: 24
  },
  networkGlobeIcon: {
    fontSize: '20px'
  },
  networkCloseIcon: {
    top: '-6px',
    right: '-5px'
  }
})

const DeviceMoreInfoCard = ({ device = {}, children, classes, t }) => {
  const deviceColor = useMemo(() => device.color || device.deviceType.color, [
    device
  ])

  return (
    <MaterialPopup
      placement={'bottom'}
      on={'hover'}
      rootClassName={classes.popupRoot}
      trigger={children}
      preventOverflow={{
        enabled: true,
        boundariesElement: 'viewport'
      }}
    >
      <div className={classes.popupContent}>
        <Card
          rootStyle={
            deviceColor ? { borderLeft: `5px solid ${deviceColor}` } : {}
          }
          titleComponent={
            <>
              <Typography className={classes.cardTitle} component="span">
                <TextSizeTooltip title={device.alias} maxTitleWidth={35} />
              </Typography>
              <Typography className={classes.cardSubTitle} component="span">
                <TextSizeTooltip title={device.name} maxTitleWidth={35} />
              </Typography>
            </>
          }
          rootClassName={classes.cardRoot}
          headerClasses={[classes.cardHeader]}
          headerTextClasses={[classes.cardHeaderText]}
          dropdown={false}
          iconButtonComponent={
            <Tooltip
              arrow
              withWrapper
              title={device.status === 'Active' ? t('Active') : t('Inactive')}
              placement="top"
            >
              {device.status === 'Active' ? (
                <i
                  className={classNames({
                    'icon-network-check': device.status === 'Active',
                    [classes.statusIcon]: true
                  })}
                  style={{
                    color: device?.statusColor
                  }}
                />
              ) : (
                <NetworkCloseIcon
                  wrapperClassName={classes.networkIconWrapper}
                  generalStyles={{ color: device?.statusColor }}
                  mainClassName={classes.networkGlobeIcon}
                  secondClassName={classes.networkCloseIcon}
                />
              )}
            </Tooltip>
          }
        >
          <div className={classes.content}>
            <Grid
              className={classes.detailRow}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classes.detailLabel}>
                  {t('Location')} | {t('Time')}
                </Typography>
              </Grid>
              <Grid item className={classes.rightAlignText}>
                <Grid component="span">
                  <TextWithTooltip
                    rootClassName={classes.detailValue}
                    weight="bold"
                    color="title.primary"
                    maxWidth={130}
                  >
                    {_get(device.deviceType, 'alias') !== VIRTUAL_DEVICE_ALIAS
                      ? `${device.city}, ${device.state}`
                      : t('Virtual Cloud')}
                  </TextWithTooltip>
                  <Typography
                    component="span"
                    className={classNames(
                      classes.detailValue,
                      classes.textSmall
                    )}
                  >
                    {getDeviceTimeWithOffset(device, t) || ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classes.detailRow}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classes.detailLabel}>
                  {t('Device Last Reboot')}
                </Typography>
              </Grid>
              <Grid item className={classes.rightAlignText}>
                <DateTimeView
                  date={device.lastReboot}
                  textClass={classes.detailValue}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.detailRow}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classes.detailLabel}>
                  {t('Device Last Check-in')}
                </Typography>
              </Grid>
              <Grid item className={classes.rightAlignText}>
                <DateTimeView
                  date={device.lastCheckInUTC}
                  textClass={classes.detailValue}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.detailRow}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classes.detailLabel}>
                  {t('Application Version')}
                </Typography>
              </Grid>
              <Grid item className={classes.rightAlignText}>
                <Typography className={classes.detailValue}>
                  {device.firmware}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Card>
      </div>
    </MaterialPopup>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(DeviceMoreInfoCard)
)

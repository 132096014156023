import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import ReactCrop from 'react-image-crop'
import LoaderWrapper from 'components/LoaderWrapper'
import { CircularLoader } from 'components/Loaders'
import { Edit as EditIcon } from '@material-ui/icons'
import { withStyles, IconButton, Dialog, Grid, Paper } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { BlueButton, WhiteButton } from 'components/Buttons'
import { withSnackbar } from 'notistack'
import { useCustomSnackbar } from 'hooks'
import classNames from 'classnames'

import 'react-image-crop/dist/ReactCrop.css'
import {
  getResponsiveEditorSize,
  generateCroppedBlob,
  getCenteredCrop
} from 'utils/imageCropUtils'

const EditImageButtonStyles = () => ({
  button: {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(100% - 68px)',
    right: 20
  },
  disalogContainer: {
    overflowY: 'hidden'
  },
  dialogPaper: {
    margin: 20,
    height: 'calc(100% - 40px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '&.vertical .ReactCrop > div': {
      height: '100%',
      '& .ReactCrop__image': {
        height: '100%'
      }
    },
    '&.horizontal .ReactCrop > div': {
      width: '100%',
      '& .ReactCrop__image': {
        width: '100%'
      }
    }
  },
  paperScrollPaper: {
    maxHeight: 'calc(100% - 40px)'
  },

  actionWrap: {
    paddingRight: 12,
    paddingBottom: 20
  },
  action: {
    paddingTop: '9px',
    paddingBottom: '9px'
  },
  verticalPaper: {
    height: '100%'
  },
  horizontalPaper: {
    width: '100%'
  }
})

const EditImageControl = ({
  value,
  defaultImagePreview,
  onCropedImageChange,
  t,
  classes,
  error = '',
  enableEditInError = false,
  enqueueSnackbar,
  closeSnackbar,
  reactCropProps = {},
  aspectRatio,
  imageControlDialogClass
}) => {
  const [crop, setCrop] = useState({ aspect: aspectRatio })
  const [shouldUpdateDefaultCrop, setShouldUpdateDefaultCrop] = useState(false)
  const [activeCrop, setActiveCrop] = useState({ aspect: aspectRatio })
  const [isOpen, setOpen] = useState(false)
  const [readerValue, setReaderValue] = useState(false)
  const originalImg = useRef()
  const showSnackbar = useCustomSnackbar(t, enqueueSnackbar, closeSnackbar)

  const getUpdatedCrop = useCallback(
    crp => {
      const { minWidth, minHeight, maxWidth, maxHeight } = reactCropProps
      const crop = { ...crp }
      if (crop.height > maxHeight) {
        crop.height = maxHeight
      }
      if (crop.height < minHeight) {
        crop.height = minHeight
      }
      if (crop.width < minWidth) {
        crop.width = minWidth
      }
      if (crop.width > maxWidth) {
        crop.width = maxWidth
      }
      crop.aspect = aspectRatio

      return crop
    },
    [aspectRatio, reactCropProps]
  )

  const handleSetActiveCrop = useCallback(
    crop => {
      setActiveCrop(getUpdatedCrop(crop))
    },
    [getUpdatedCrop]
  )

  const handleSetCrop = useCallback(
    crop => {
      setCrop(getUpdatedCrop(crop))
    },
    [getUpdatedCrop]
  )

  const onLoad = useCallback(img => {
    originalImg.current = img
  }, [])

  const handlerReader = useCallback(
    ({ target }) => {
      setReaderValue(target.result)
    },
    [setReaderValue]
  )

  const { naturalHeight, naturalWidth, width, height } =
    originalImg.current || {}

  const { isHorizontal, containerSize } = useMemo(
    () =>
      getResponsiveEditorSize({
        imageWidth: naturalWidth,
        imageHeight: naturalHeight
      }),
    [naturalWidth, naturalHeight]
  )

  useEffect(() => {
    if (!shouldUpdateDefaultCrop || !width || !height) return
    const crop = getCenteredCrop(originalImg.current)
    handleSetActiveCrop(crop)
    handleSetCrop(crop)
    setShouldUpdateDefaultCrop(false)
    // eslint-disable-next-line
  }, [shouldUpdateDefaultCrop, width, height])

  useEffect(() => {
    if (readerValue) {
      setShouldUpdateDefaultCrop(true)
    }
  }, [readerValue])

  useEffect(() => {
    if (value && value.type) {
      const reader = new FileReader()
      reader.addEventListener('load', handlerReader)
      reader.readAsDataURL(value)
      return () => {
        reader.removeEventListener('load', handlerReader)
      }
    }
    if (defaultImagePreview) {
      setReaderValue(defaultImagePreview)
    } else {
      setReaderValue(null)
    }
  }, [value, defaultImagePreview, handlerReader, setReaderValue])

  const onSaveCrop = useCallback(() => {
    generateCroppedBlob(originalImg.current, crop).then(blob => {
      onCropedImageChange(blob)
      setOpen(false)
    })
  }, [crop, onCropedImageChange])

  const onClose = () => {
    setOpen(false)
  }

  const handleEditIconClick = useCallback(() => {
    if (error && !enableEditInError) {
      showSnackbar(t(error), 'error')
    } else {
      setOpen(!isOpen)
    }
  }, [error, isOpen, showSnackbar, t, enableEditInError])

  return (
    <>
      <IconButton className={classes.button} onClick={handleEditIconClick}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperComponent={'div'}
        maxWidth={false}
        scroll="body"
        classes={{
          container: classes.disalogContainer,
          paper: classNames(classes.dialogPaper, imageControlDialogClass, {
            vertical: !isHorizontal,
            horizontal: isHorizontal
          }),
          paperScrollPaper: classes.paperScrollPaper
        }}
      >
        <LoaderWrapper isLoading={!readerValue} loader={<CircularLoader />}>
          <Paper
            className={classNames({
              [classes.verticalPaper]: !isHorizontal,
              [classes.horizontalPaper]: isHorizontal
            })}
          >
            <ReactCrop
              src={readerValue}
              crop={activeCrop}
              onChange={newCrop => handleSetActiveCrop(newCrop)}
              onComplete={newCrop => {
                handleSetActiveCrop(newCrop)
                handleSetCrop(newCrop)
              }}
              onImageLoaded={onLoad}
              style={{
                ...containerSize,
                margin: 20
              }}
              {...reactCropProps}
            />
            <Grid
              container
              className={classes.buttonContainer}
              justify="flex-end"
            >
              <Grid item className={classes.actionWrap}>
                <BlueButton
                  fullWidth={true}
                  className={classes.action}
                  onClick={onSaveCrop}
                >
                  {t('Save')}
                </BlueButton>
              </Grid>
              <Grid item className={classes.actionWrap}>
                <WhiteButton
                  fullWidth={true}
                  className={classes.action}
                  onClick={onClose}
                  variant="danger"
                >
                  {t('Cancel')}
                </WhiteButton>
              </Grid>
            </Grid>
          </Paper>
        </LoaderWrapper>
      </Dialog>
    </>
  )
}

export default withTranslation('translations')(
  withStyles(EditImageButtonStyles)(withSnackbar(EditImageControl))
)

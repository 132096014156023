import { withStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { _get } from 'utils/lodash'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'

import { useEventListener } from 'hooks/index'
import Text from 'components/Typography/Text'

const styles = ({ spacing }) => ({
  tooltipRoot: {
    padding: spacing(2),
    paddingTop: '50px',
    backgroundColor: '#0a83c8'
  },
  textButton: {
    borderRadius: 4,
    cursor: 'pointer',
    transition: 'all 0.25s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  },
  textButtonActive: {
    color: '#fff !important'
  },
  textButtonRoot: {
    display: 'flex',
    '& p': {
      fontSize: '44px',
      lineHeight: '44px',
      color: 'rgba(255, 255, 255, 0.54)'
    }
  },
  buttonLabelRoot: {
    display: 'flex',
    marginTop: 20,
    '& p': {
      padding: '0px 4px',
      fontSize: '22px',
      lineHeight: '22px',
      color: 'rgba(255, 255, 255, 0.54)'
    }
  },
  ampmWrapper: {
    '& p': {
      padding: '0px 4px',
      fontSize: '22px',
      lineHeight: '22px',
      color: 'rgba(255, 255, 255, 0.54)'
    }
  }
})

const Toolbar = ({
  openView,
  setOpenView,
  date,
  onChange,
  views,
  classes,
  ampm
}) => {
  const [numberPosition, setNumberPosition] = useState(0)
  const [isFirstPress, setFirstPress] = useState(true)
  const [am, setAm] = useState(date.get('hours') < 12)

  const handleChangeView = useCallback(
    isPrevDirection => {
      const positionOffset = isPrevDirection ? -1 : 1
      const nextIndex = views.indexOf(openView) + positionOffset
      if (_get(views, `[${nextIndex}]`)) {
        setOpenView(views[nextIndex])
        setNumberPosition(0)
        setFirstPress(true)
      }
    },
    [openView, views, setOpenView]
  )

  const handleViewClick = useCallback(
    view => {
      setFirstPress(true)
      setNumberPosition(0)
      setOpenView(view)
    },
    [setOpenView]
  )

  const keypressHandler = useCallback(
    ({ key }) => {
      const number = parseInt(key)
      if (!Number.isNaN(number)) {
        setFirstPress(false)
        if (openView === 'hours') {
          if (numberPosition === 0 && number <= 2) {
            const hours = number * 10
            onChange(date.set('hours', hours))
            setNumberPosition(1)
            if (hours >= 12) {
              setAm(false)
            }
          } else if (numberPosition === 1 && number < 24 - date.hours()) {
            const hours = parseInt(date.hours()) + number
            onChange(date.set('hours', hours))
            if (hours >= 12) {
              setAm(false)
            }
            handleChangeView()
          }
        } else {
          if (numberPosition === 0 && number <= 5) {
            onChange(date.set(openView, number * 10))
            setNumberPosition(1)
          } else if (numberPosition === 1) {
            onChange(date.set(openView, parseInt(date.get(openView)) + number))
            if (openView !== 'seconds' && views.includes('seconds')) {
              handleChangeView()
            } else {
              setNumberPosition(2)
            }
          }
        }
      } else if (key === 'Backspace') {
        if (numberPosition === 2 || isFirstPress) {
          onChange(date.set(openView, parseInt(date.get(openView) / 10) * 10))
          setNumberPosition(1)
          setFirstPress(false)
        } else if (numberPosition === 1) {
          onChange(date.set(openView, 0))
          setNumberPosition(0)
        } else {
          if (openView !== 'hours') {
            handleChangeView(true)
          }
        }
      } else if (key === 'ArrowRight') {
        handleChangeView()
      } else if (key === 'ArrowLeft') {
        handleChangeView(true)
      } else if (key === 'ArrowUp') {
        let time = date.get(openView) + 1
        if (ampm && openView === 'hours') {
          if (time === 24) {
            time = 0
          }
        }
        onChange(date.set(openView, time))
        setAm(time - 12 < 0)
      } else if (key === 'ArrowDown') {
        let time = date.get(openView) - 1
        if (ampm && openView === 'hours') {
          if (time === 24) {
            time = 0
          }
        }
        onChange(date.set(openView, time))
        setAm(time - 12 < 0 && time >= 0)
      }
    },
    [
      views,
      openView,
      numberPosition,
      date,
      onChange,
      handleChangeView,
      isFirstPress,
      ampm
    ]
  )
  useEventListener('keydown', keypressHandler)
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.tooltipRoot}
      direction="column"
    >
      <div className={classes.textButtonRoot}>
        <Text
          display="inline"
          onClick={() => handleViewClick('hours')}
          rootClassName={classNames(classes.textButton, {
            [classes.textButtonActive]: openView === 'hours'
          })}
        >
          {date.format(ampm ? 'hh' : 'HH')}
        </Text>
        <Text>:</Text>
        <Text
          display="inline"
          onClick={() => handleViewClick('minutes')}
          rootClassName={classNames(classes.textButton, {
            [classes.textButtonActive]: openView === 'minutes'
          })}
        >
          {date.format('mm')}
        </Text>
        {views.includes('seconds') && (
          <>
            <Text>:</Text>
            <Text
              display="inline"
              onClick={() => handleViewClick('seconds')}
              rootClassName={classNames(classes.textButton, {
                [classes.textButtonActive]: openView === 'seconds'
              })}
            >
              {date.format('ss')}
            </Text>
          </>
        )}
      </div>
      {ampm && (
        <Grid container justify="center" className={classes.ampmWrapper}>
          <Text
            onClick={() => {
              if (!am) {
                setAm(true)
                onChange(date.set('hours', date.get('hours') - 12))
              }
            }}
            rootClassName={classNames(classes.textButton, {
              [classes.textButtonActive]: am
            })}
          >
            am
          </Text>
          <Text
            onClick={() => {
              if (am) {
                setAm(false)
                onChange(date.set('hours', date.get('hours') + 12))
              }
            }}
            rootClassName={classNames(classes.textButton, {
              [classes.textButtonActive]: !am
            })}
          >
            pm
          </Text>
        </Grid>
      )}
      <div className={classes.buttonLabelRoot}>
        <Text>HH</Text>
        <Text>:</Text>
        <Text>MM</Text>
        {views.includes('seconds') && (
          <>
            <Text>:</Text>
            <Text>SS</Text>
          </>
        )}
      </div>
    </Grid>
  )
}

export default withStyles(styles)(Toolbar)

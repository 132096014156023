import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from '../utils/apiUtils'
import apiTags from '../constants/apiTags'
import { getApiParamsByOriginalUser } from 'utils/localStorage'

export const playlistsApi = createAppApi('playlistsQuery', {
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      url: '/playlist',
      name: 'Playlist',
      tags: [apiTags.playlist]
    }),
    copyPlaylists: builder.mutation({
      query: (data = {}) => {
        const { impersonated, ...restData } = data
        return {
          url: '/playlist/bulkCopy',
          method: 'POST',
          data: restData,
          ...(impersonated && getApiParamsByOriginalUser('/playlist/bulkCopy'))
        }
      }
    }),
    sharePlaylists: builder.mutation({
      query: (data = {}) => ({
        url: '/playlist/bulkShare',
        method: 'POST',
        data
      })
    })
  })
})

export const {
  //Playlist
  useLazyGetPlaylistsQuery,
  useLazyGetPlaylistQuery,
  usePostPlaylistMutation,
  usePutPlaylistMutation,
  useDeletePlaylistMutation,
  useCopyPlaylistsMutation,
  useSharePlaylistsMutation
} = playlistsApi

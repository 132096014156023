import React, { memo } from 'react'
import Spacing from 'components/Containers/Spacing'
import classNames from 'classnames'
import { ReactComponent as WarningIcon } from 'common/assets/icons/ic-round-warning.svg'
import Text from 'components/Typography/Text'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import PageContainer from 'components/PageContainer'

const styles = ({ colors }) => ({
  root: {
    height: '70vh'
  },
  icon: {
    color: colors.highlight
  }
})

const NoPermissionsPage = ({ classes, t }) => {
  return (
    <PageContainer header={false} subHeader={false}>
      <Spacing
        variant={0}
        justify="center"
        alignItems="center"
        rootClassName={classNames(classes.root)}
      >
        <WarningIcon className={classes.icon} />
        <Text variant="biggest" weight="bold" component="h1" color="highlight">
          {t('Your user account currently has no permissions enabled.')}
        </Text>
        <Text variant="biggest" weight="bold" component="h1" color="highlight">
          {t('Please contact your administrator.')}
        </Text>
      </Spacing>
    </PageContainer>
  )
}

export default memo(
  withTranslation('translations')(withStyles(styles)(NoPermissionsPage))
)

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Grid, withStyles } from '@material-ui/core'

import Text from 'components/Typography/Text'
import GreyCard from 'components/Card/GreyCard'
import Spacing from 'components/Containers/Spacing'
import Tooltip from 'components/Tooltip'
import { DateTimeView } from 'components/TableLibrary'
import { CircularLoader } from 'components/Loaders'
import ImageWithPlaceholder from 'components/Loaders/ImageWithPlaceholder'
import DevicePreviewRefreshIcon from 'components/Icons/DevicePreviewRefreshIcon'
import { TextWithTooltip } from 'components/Typography'
import { useUserRole } from 'hooks/tableLibrary'
import { isFeatureAvailable } from 'utils/api/featureAvailability'
import { DEVICE_RESOLUTION_FEATURE } from 'constants/featureConstants'
import NoPreviewDiv from 'components/Card/DeviceMoreInfoCard/NoPreviewDiv'
import {
  getDevicePreviewUrl,
  getScreenOrientationValue
} from 'utils/deviceUtils'

const styles = ({ palette, type, typography, fontWeight }) => ({
  cardHeaderRoot: {
    height: 60
  },
  icon: {
    height: '100%'
  },
  cardHerderTitle: {
    ...typography.darkText[type],
    fontSize: '1rem',
    fontWeight: fontWeight.bold,
    lineHeight: '1.25rem'
  },
  imageContainer: {
    width: '100%',
    height: 215,
    display: 'flex',
    justifyContent: 'center',

    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'contain'
    }
  },
  imageClickable: {
    cursor: 'pointer'
  },
  updateContentWrap: {
    display: 'flex',
    alignItems: 'baseline',
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  updateLabel: {
    marginRight: 5,
    fontSize: '12px',
    lineHeight: '1.375rem',
    whiteSpace: 'nowrap'
  },
  cardFooter: {
    minHeight: 37
  },
  cardSubHeader: {
    fontSize: '0.8125rem',
    lineHeight: '1.375rem'
  },
  lastUpdateFooter: {
    ...typography.lightText[type],
    fontSize: '12px'
  },
  headerWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  actionBtnIcon: {
    fontSize: '24px',
    marginBottom: '3px',
    marginRight: '12px',
    zIndex: 2
  },
  activeStatusColor: {
    color: '#36b5cb'
  },
  inActiveStatusColor: {
    color: 'rgba(148, 148, 148, 1)'
  },
  rightHeaderContent: {
    textAlign: 'right'
  },
  screenOrientationRoot: {
    height: 20
  },
  refreshIcon: {
    zIndex: 2
  },
  placeHolderRoot: {
    display: 'flex'
  }
})

function DevicePreviewCard({
  t,
  device,
  onUpdate,
  isUpdateAllowed,
  isPending,
  onImageClick,
  imageContainerClassName = '',
  maxWidth = 345,
  showResolution = false,
  showScreenOrientation = false,
  showConnectedStatus,
  classes,
  noPreviewRootClassName
}) {
  const {
    name,
    lastUpdate,
    alias,
    deviceReportedResolutionWidth,
    deviceReportedResolutionHeight,
    isScreenConnected
  } = device
  const role = useUserRole()

  const [previewUrl, setPreviewUrl] = useState(getDevicePreviewUrl(device))

  useEffect(() => {
    setPreviewUrl(getDevicePreviewUrl(device))
  }, [device])

  const visibleResolution = useMemo(
    () =>
      isFeatureAvailable(DEVICE_RESOLUTION_FEATURE) &&
      showResolution &&
      (role.system || role.org),
    [showResolution, role.system, role.org]
  )

  const visibleScreenOrientation = useMemo(
    () => showScreenOrientation && (role.system || role.org),
    [showScreenOrientation, role.system, role.org]
  )

  const handleUpdate = useCallback(() => {
    onUpdate(device.id)
  }, [device.id, onUpdate])

  const previewImage = useMemo(() => {
    return previewUrl ? (
      <ImageWithPlaceholder
        src={previewUrl}
        alt=""
        onError={() => setPreviewUrl(null)}
        placeHolderClass={classes.placeHolderRoot}
      />
    ) : (
      <NoPreviewDiv rootClassName={noPreviewRootClassName} />
    )
  }, [previewUrl, classes, noPreviewRootClassName])

  return (
    <GreyCard
      headerComponent={
        <>
          <Grid className={classes.headerWrapper}>
            <Grid item>
              <TextWithTooltip
                maxWidth={
                  maxWidth -
                  (visibleResolution || visibleScreenOrientation ? 100 : 5)
                }
                color="title.primary"
                weight="bold"
                rootClassName={classes.cardHerderTitle}
              >
                {alias}
              </TextWithTooltip>
              <TextWithTooltip
                rootClassName={classes.cardSubHeader}
                maxWidth={
                  maxWidth -
                  (visibleResolution || visibleScreenOrientation ? 100 : 5)
                }
                weight="bold"
              >
                {name}
              </TextWithTooltip>
            </Grid>
            <Grid item className={classes.rightHeaderContent}>
              <div className={classes.screenOrientationRoot}>
                {visibleScreenOrientation && (
                  <Text>{getScreenOrientationValue(device, t)}</Text>
                )}
              </div>
              {visibleResolution && (
                <Text>
                  {deviceReportedResolutionWidth > 0 &&
                  deviceReportedResolutionHeight > 0
                    ? `${deviceReportedResolutionWidth}x${deviceReportedResolutionHeight}`
                    : t('N/A')}
                </Text>
              )}
            </Grid>
          </Grid>
        </>
      }
      headerClassName={classes.cardHeaderRoot}
      contentSpacingProps={{
        paddingVert: 0,
        paddingHor: 0
      }}
    >
      <Spacing variant={0}>
        <div
          className={classNames(
            classes.imageContainer,
            imageContainerClassName,
            {
              [classes.imageClickable]:
                !!onImageClick && !isPending && previewUrl
            }
          )}
          onClick={!isPending && previewUrl ? onImageClick : undefined}
        >
          {isPending && <CircularLoader />}
          {previewImage}
        </div>
        <Spacing
          variant={0}
          paddingHor={2}
          paddingVert={1}
          direction="row"
          component="footer"
          justify="space-between"
          alignItems="center"
          rootClassName={classes.cardFooter}
        >
          <Grid
            item
            style={{ maxWidth: maxWidth - 10 }}
            className={classNames(classes.updateContentWrap)}
          >
            <Text rootClassName={classes.updateLabel}>{`${t(
              'Updated At'
            )}:`}</Text>
            <DateTimeView
              date={lastUpdate}
              direction="row"
              withSeparator
              reversedRow
            />
          </Grid>
          <div>
            <Spacing
              variant={0}
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              {showConnectedStatus && isScreenConnected !== null && (
                <Tooltip
                  arrow
                  title={t(
                    isScreenConnected
                      ? 'The digital signage player is actively connected to the display screen'
                      : 'Player is either disconnected or the screen has been turned off.'
                  )}
                >
                  <i
                    className={classNames(
                      'fa-sharp fa-solid fa-tv-retro',
                      classes.actionBtnIcon,
                      {
                        [classes.activeStatusColor]: isScreenConnected,
                        [classes.inActiveStatusColor]: !isScreenConnected
                      }
                    )}
                  />
                </Tooltip>
              )}
              {isUpdateAllowed && (
                <DevicePreviewRefreshIcon
                  deviceId={device.id}
                  onUpdate={handleUpdate}
                  disabled={isPending}
                  iconClass={classes.refreshIcon}
                />
              )}
            </Spacing>
          </div>
        </Spacing>
      </Spacing>
    </GreyCard>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(DevicePreviewCard)
)

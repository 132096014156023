import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Grid, withStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { truncateWithEllipsis } from '../../../utils/truncateStringUtils'
import Tooltip from '../../Tooltip'
import { truncateLength } from '../consts'

const styles = ({ palette, type, typography }) => ({
  detailRow: {
    minHeight: '36px'
  },
  corner: {
    borderBottom: `1px solid ${palette[type].deviceCard.row.background}`
  },
  detailLabel: {
    ...typography.lightText[type]
  },
  detailValue: {
    ...typography.darkAccent[type],
    fontWeight: 700
  }
})

const DeviceInfoRow = ({
  t,
  classes,
  title,
  children,
  hasCorner = true,
  customValueType = false,
  isTruncate = false,
  truncateLength: length = truncateLength,
  rootClassName,
  detailLabelClassName
}) => (
  <Grid
    className={classNames(classes.detailRow, rootClassName, {
      [classes.corner]: hasCorner
    })}
    container
    justify="space-between"
    alignItems="center"
  >
    <Grid item>
      <Typography
        className={classNames(classes.detailLabel, detailLabelClassName)}
      >
        {t(title)}
      </Typography>
    </Grid>
    {customValueType ? (
      children
    ) : (
      <Grid item>
        {isTruncate ? (
          <Tooltip arrow title={children}>
            <Typography className={classes.detailValue}>
              {truncateWithEllipsis(children, length)}
            </Typography>
          </Tooltip>
        ) : (
          <Typography className={classes.detailValue}>{children}</Typography>
        )}
      </Grid>
    )}
  </Grid>
)

DeviceInfoRow.propTypes = {
  title: PropTypes.string.isRequired,
  customValueType: PropTypes.bool,
  isTruncate: PropTypes.bool
}

export default withTranslation('translations')(
  withStyles(styles)(DeviceInfoRow)
)

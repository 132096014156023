import React, { memo, useCallback } from 'react'
import { Grid, withStyles, withTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import Spacing from 'components/Containers/Spacing'
import MaterialPopup from 'components/Popup/MaterialPopup'
import Text from 'components/Typography/Text'
import CheckboxSwitcher from 'components/Checkboxes/Switcher'
import { TextWithTooltip } from 'components/Typography'
import ItemsPopupContent from 'components/Group/ItemsPopupContent'
import {
  clearGetDeviceGroupItemsInfoAction,
  getDeviceGroupItemsAction
} from 'actions/deviceActions'
import { deviceGroupItemsSelector } from 'selectors/deviceSelectors'
import DeviceNameCell from 'components/Pages/Admin/DeviceLibrary/DeviceNameCell'
import { getCountCircleStyle, getListContentWrapStyle } from './styles'

const styles = theme => ({
  listContentWrap: {
    ...getListContentWrapStyle(theme),
    '&:nth-child(even)': {
      //style override
    }
  },
  countCircle: {
    ...getCountCircleStyle(theme)
  }
})

const GroupRow = ({
  item,
  checkboxValue,
  disabled,
  onChange,
  containerProps,
  theme,
  classes
}) => {
  const dispatch = useDispatch()
  const groupItemsResponse = useSelector(deviceGroupItemsSelector)

  const { color, count, title, id } = item

  const handleChange = useCallback(() => {
    onChange(id)
  }, [onChange, id])

  const fetchGroupDevices = useCallback(
    (id, page) =>
      dispatch(
        getDeviceGroupItemsAction(id, {
          order: 'asc',
          sort: 'name',
          fields: 'id,name,alias',
          page: page || 1
        })
      ),
    [dispatch]
  )

  const clearGroupDevices = useCallback(() => {
    dispatch(clearGetDeviceGroupItemsInfoAction())
  }, [dispatch])

  return (
    <Grid item xs={12} {...containerProps} className={classes.listContentWrap}>
      <Spacing
        direction="row"
        alignItems="center"
        justify="space-between"
        variant={0}
        wrap="nowrap"
      >
        <Grid container item alignItems="center" wrap="nowrap">
          <MaterialPopup
            disabled={count < 1}
            placement="bottom-end"
            onClose={clearGroupDevices}
            trigger={
              <Text
                style={{ borderColor: color }}
                rootClassName={classes.countCircle}
                color="title.primary"
                variant="smallest"
              >
                {count}
              </Text>
            }
          >
            <ItemsPopupContent
              groupItemsReducer={groupItemsResponse}
              getGroupItems={fetchGroupDevices}
              clearGroupItemsInfo={clearGroupDevices}
              id={item.id}
              groupCardPopupRow={DeviceNameCell}
              title={item.title}
            />
          </MaterialPopup>

          <TextWithTooltip color="title.primary" maxWidth={250}>
            {title}
          </TextWithTooltip>
        </Grid>
        <CheckboxSwitcher
          switchStrictWidth
          disabled={disabled}
          value={checkboxValue}
          handleChange={handleChange}
        />
      </Spacing>
    </Grid>
  )
}

export default withStyles(styles)(withTheme(memo(GroupRow)))

import { useCallback, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Calendar } from 'react-date-range'
import classNames from 'classnames'
import moment from 'moment'

import MaterialPopup from 'components/Popup/MaterialPopup'
import FormControlInput from '../FormControlInput'
import {
  NORMAL_DATE_TIME_AP_FORMAT,
  NORMAL_DATE_TIME_S_AP_FORMAT,
  TIME_S_FORMAT
} from 'constants/dateTimeFormats'
import { simulateEvent } from 'utils/formik'
import PropTypes from 'prop-types'
import getStyles from './styles'
import TimePicker from './components/TimePickerNew'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const useStyles = makeStyles(({ palette, type, typography }) => ({
  endAdornment: {
    position: 'absolute',
    right: '12px',
    height: 'calc(100% - 3px)',
    padding: '6px 10px',
    marginLeft: 4,
    display: 'grid',
    placeItems: 'center',
    marginRight: '-10px',
    background: palette[type].formControls.multipleDatesPicker.input.background,
    color: palette[type].formControls.multipleDatesPicker.input.color,
    borderLeft: `1px solid ${palette[type].formControls.multipleDatesPicker.input.border}`
  },
  clearIcon: {
    cursor: 'pointer',
    color: typography.lightAccent[type].color,
    position: 'absolute',
    right: '36px',
    top: '2px',
    height: 'calc(100% - 3px)',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    padding: '10px',

    '&:hover': {
      color: typography.darkAccent[type].color
    }
  },
  dateRangeRoot: localValues => ({
    ...getStyles({ palette, type }, localValues)
  }),
  endTimeRoot: {
    position: 'absolute',
    boxShadow: 'rgb(128 128 128 / 45%) 0px 0px 8px',
    borderRadius: '4px',
    top: 70,
    right: 10,
    height: 'calc(100% - 90px) !important'
  },
  formInput: {
    padding: '9px 65px 9px 15px'
  }
}))

const FormControlDateTimePickerNew = ({
  name,
  label,
  value,
  error,
  touched,
  formControlContainerClass,
  formControlRootClass,
  withPortal = false,
  marginBottom = true,
  fullWidth = false,
  onFocus = f => f,
  onBlur = f => f,
  onChange,
  maskValue: dateTimeMaskFormat,
  minDate = moment().format(NORMAL_DATE_TIME_S_AP_FORMAT),
  maxDate,
  labelPosition = 'top',
  disabled = false,
  inputProps = {},
  hideIcon = false,
  isClearable = false,
  fullHeight = false,
  showSeconds,
  onDoubleClick,
  tooltip,
  isBottomError,
  showErrorText = true,
  displayFormat: dateTimeDisplayFormat,
  popupPosition = 'bottom',
  calendarRootClass,
  withTimePicker
}) => {
  const classes = useStyles({
    fullWidth,
    showSeconds,
    noRange: true
  })
  const inputRef = useRef()
  const [isFocusedInput, setFocusedInput] = useState(false)

  const showError = useMemo(() => {
    return !!(showErrorText && error && touched)
  }, [showErrorText, error, touched])

  const isErrorIcon = !isBottomError && showError

  const maskValue = useMemo(
    () =>
      dateTimeMaskFormat ||
      (showSeconds ? NORMAL_DATE_TIME_S_AP_FORMAT : NORMAL_DATE_TIME_AP_FORMAT),
    [showSeconds, dateTimeMaskFormat]
  )

  const displayFormat = useMemo(
    () =>
      dateTimeDisplayFormat ||
      (showSeconds ? NORMAL_DATE_TIME_S_AP_FORMAT : NORMAL_DATE_TIME_AP_FORMAT),
    [showSeconds, dateTimeDisplayFormat]
  )

  const handleChange = useCallback(
    date => {
      onChange(simulateEvent(name, moment(date).format(maskValue)))
    },
    [onChange, maskValue, name]
  )

  const handleFocus = useCallback(
    event => {
      setFocusedInput(true)

      onFocus(event)
    },
    [onFocus]
  )

  const handleBlur = useCallback(
    event => {
      setFocusedInput(false)

      const dateTimeObj = moment(event?.target?.value, displayFormat)
      if (!event?.target?.value && isClearable) {
        onChange(simulateEvent(name, null))
      } else if (
        !dateTimeObj.isValid() ||
        (!!minDate && dateTimeObj.isBefore(moment(minDate, maskValue)))
      ) {
        onChange(simulateEvent(name, moment().format(maskValue)))
      } else {
        onChange(simulateEvent(name, dateTimeObj.format(maskValue)))
      }

      onBlur(event)
    },
    [onBlur, displayFormat, maskValue, name, onChange, minDate, isClearable]
  )

  const handleClearDate = useCallback(
    event => {
      event.stopPropagation()
      onChange(simulateEvent(name, null))
    },
    [onChange, name]
  )

  const handleChangeTime = useCallback(
    t => {
      const time = moment(t, TIME_S_FORMAT)
      onChange(
        simulateEvent(
          name,
          (value ? moment(value, maskValue) : moment())
            .set({
              hour: time.get('hour'),
              minute: time.get('minute'),
              second: time.get('second')
            })
            .format(maskValue)
        )
      )
    },
    [onChange, name, value, maskValue]
  )

  const handleCalendarClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleClose = useCallback(() => {
    if (!isFocusedInput) {
      onBlur(simulateEvent(name, value))
    }
  }, [onBlur, value, name, isFocusedInput])

  const parseDateValue = useMemo(
    () =>
      value && moment(value, maskValue).isValid()
        ? moment(value, maskValue).format(displayFormat)
        : value || '',
    [value, maskValue, displayFormat]
  )

  return (
    <MaterialPopup
      open={isFocusedInput === true ? true : undefined}
      on="click"
      placement={popupPosition}
      onClose={handleClose}
      trigger={
        <FormControlInput
          labelPosition={labelPosition}
          inputRef={inputRef}
          label={label}
          endAdornment={
            (isClearable && value) || (!hideIcon && !isErrorIcon) ? (
              <>
                {hideIcon || isErrorIcon ? null : (
                  <div
                    className={classes.endAdornment}
                    onClick={handleCalendarClick}
                  >
                    <i className={'fa-regular fa-calendar-days'} />
                  </div>
                )}
                {!disabled && isClearable && value ? (
                  <i
                    className={classNames(
                      'fa-regular fa-xmark',
                      classes.clearIcon
                    )}
                    onClick={handleClearDate}
                  />
                ) : null}
              </>
            ) : null
          }
          name={name}
          value={parseDateValue}
          onFocus={handleFocus}
          onChange={onChange}
          onBlur={handleBlur}
          fullWidth={fullWidth}
          error={error}
          touched={touched}
          marginBottom={marginBottom}
          disabled={disabled}
          formControlContainerClass={formControlContainerClass}
          formControlRootClass={formControlRootClass}
          fullHeight={fullHeight}
          onDoubleClick={onDoubleClick}
          tooltip={tooltip}
          showErrorText={showErrorText}
          formControlInputClass={classNames({
            [classes.formInput]: isClearable
          })}
          {...inputProps}
        />
      }
      preventOverflow={
        withPortal
          ? {
              enabled: true,
              boundariesElement: 'viewport'
            }
          : {}
      }
      disabled={disabled}
    >
      <>
        <Calendar
          className={classNames(classes.dateRangeRoot, calendarRootClass)}
          onChange={handleChange}
          date={
            value && moment(value, maskValue).isValid()
              ? moment(value, maskValue).toDate()
              : null
          }
          minDate={
            minDate && moment(minDate, maskValue).isValid()
              ? moment(minDate, maskValue).toDate()
              : undefined
          }
          maxDate={
            maxDate && moment(maxDate, maskValue).isValid()
              ? moment(maxDate, maskValue).toDate()
              : undefined
          }
          weekStartsOn={1}
        />
        {withTimePicker && (
          <TimePicker
            rootClassName={classes.endTimeRoot}
            maskValue={TIME_S_FORMAT}
            value={moment(value, maskValue).format(TIME_S_FORMAT)}
            onChange={handleChangeTime}
            showSeconds={showSeconds}
            noLabel
          />
        )}
      </>
    </MaterialPopup>
  )
}

FormControlDateTimePickerNew.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  formControlContainerClass: PropTypes.string,
  withPortal: PropTypes.bool,
  marginBottom: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  maskValue: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  labelPosition: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  hideIcon: PropTypes.bool,
  isClearable: PropTypes.bool,
  fullHeight: PropTypes.bool,
  displayFormat: PropTypes.string,
  withTimePicker: PropTypes.bool
}

export default FormControlDateTimePickerNew

import React, { useCallback, useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { WhiteButton } from 'components/Buttons'
import { compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import SelectBackgroundDialog from 'components/Modal/SelectBackgroundDialog'
import classNames from 'classnames'
import Spacing from '../Containers/Spacing'
import Error from './Error'
import { simulateEvent } from '../../utils/formik'

const styles = ({ palette, type }) => ({
  sectionHeaderPadding: {
    padding: '0 0 0 13px'
  },
  icon: {
    fontSize: '16px',
    marginRight: '10px'
  }
})

const defaultColor = 'rgba(0, 0, 0, 1)'

const FormControlBackgroundSelect = ({
  classes,
  onChange,
  label,
  style,
  values,
  withoutPattern,
  withoutImage,
  backgroundOptions,
  disabled,
  names = {},
  t,
  maxResolution,
  disableSelectType,
  disableImageSize,
  returnPatternId,
  paginationConfig,
  touched,
  onCloseModal,
  error,
  dialogComponent: DialogComponent = SelectBackgroundDialog,
  optionsConfig,
  featureName,
  themeColors,
  randomColors,
  initialBrandValue,
  renderForPreview,
  mode,
  renderDefaultColor = false,
  imageFeatureId,
  orientation
}) => {
  const [open, toggleOpen] = useState(false)

  const handleOpenDialog = useCallback(() => {
    toggleOpen(true)
  }, [])

  const handleCloseDialog = useCallback(
    e => {
      if (onCloseModal) {
        onCloseModal(e)
      }
      toggleOpen(false)
    },
    [onCloseModal]
  )

  const unionNames = useMemo(
    () => ({
      type: 'type',
      image_id: 'image_id',
      size: 'size',
      color: 'color',
      pattern: 'pattern',
      ...names
    }),
    [names]
  )

  const unionValues = useMemo(
    () => ({
      type: 'none',
      image_id: null,
      size: 'original',
      color: defaultColor,
      pattern: null,
      ...values
    }),
    [values]
  )

  const handleChange = useCallback(
    e => {
      const {
        target: { name, value }
      } = e
      if (names.image_id && name.endsWith('type')) {
        onChange(simulateEvent(names.image_id, undefined))
      }
      if (value === 'none' && name.endsWith('type') && !values.color) {
        onChange(simulateEvent(unionNames.color, defaultColor))
      }
      onChange(e)
    },
    [onChange, names, values, unionNames.color]
  )

  return (
    <>
      <Spacing variant={0} justify="flex-end" relative>
        <WhiteButton
          style={style}
          disabled={disabled}
          onClick={handleOpenDialog}
          fullWidth
          iconClassName={classNames(classes.icon, 'fa-regular fa-folder-image')}
        >
          {label || t('Select Background')}
        </WhiteButton>
        <Error condition={!!(touched && error)} error={error} absolute />
      </Spacing>

      <DialogComponent
        disableSelectType={disableSelectType}
        disableImageSize={disableImageSize}
        returnPatternId={returnPatternId}
        open={open}
        values={unionValues}
        onCloseModal={handleCloseDialog}
        backgroundOptions={backgroundOptions}
        withoutPattern={withoutPattern}
        withoutImage={withoutImage}
        onChange={handleChange}
        names={unionNames}
        maxResolution={maxResolution}
        paginationConfig={paginationConfig}
        optionsConfig={optionsConfig}
        featureName={featureName}
        themeColors={themeColors}
        randomColorsData={randomColors}
        defaultColor={defaultColor}
        initialBrandValue={initialBrandValue}
        renderForPreview={renderForPreview}
        imageFeatureId={imageFeatureId}
        mode={mode}
        renderDefaultColor={renderDefaultColor}
        orientation={orientation}
      />
    </>
  )
}

export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(FormControlBackgroundSelect)

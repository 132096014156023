import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import { Text } from 'components/Typography'
import {
  Logo,
  PageWrapper,
  CodeText,
  ShadowedErrorText,
  ErrorSubDescription
} from './components'
import { ReactComponent as MaintenanceErrorImage } from './assets/maintenanceError.svg'

const useStyles = makeStyles(({ spacing }) => ({
  image: {
    position: 'absolute',
    top: '15%'
  },
  logoImageWrapper: {
    position: 'absolute',
    top: '12%'
  },
  errorTextWrapper: {
    position: 'absolute',
    top: '65%',
    minHeight: 242
  },
  text: {
    fontFamily: 'Montserrat'
  },
  errorText: {
    fontSize: '56px',
    lineHeight: '68px',
    fontWeight: 300,
    marginRight: spacing(1)
  },
  errorDescriptionText: {
    fontSize: '24px',
    lineHeight: '32px',
    textAlign: 'center',
    maxWidth: '90%'
  }
}))

const MaintenanceErrorPage = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <PageWrapper>
      <Grid
        container
        item
        justify="center"
        className={classes.logoImageWrapper}
      >
        <Logo />
      </Grid>
      <CodeText />
      <MaintenanceErrorImage className={classes.image} />
      <Grid
        container
        item
        direction="column"
        justify="space-between"
        alignItems="center"
        className={classes.errorTextWrapper}
      >
        <Grid container item justify="center">
          <Text
            rootClassName={classNames(classes.errorText, classes.text)}
            color="white"
          >
            {t('Unplanned')}
          </Text>
          <ShadowedErrorText>{t('Maintenance')}</ShadowedErrorText>
        </Grid>
        <Grid container item alignItems="center" direction="column">
          <Text
            rootClassName={classNames(
              classes.errorDescriptionText,
              classes.text
            )}
            color="white"
          >
            {t(
              'We apologize for the inconvenience. Our website is currently undergoing unplanned maintenance.'
            )}
          </Text>
          <Text
            rootClassName={classNames(
              classes.errorDescriptionText,
              classes.text
            )}
            color="white"
          >
            {t('Please check back shortly. Thank you for your understanding.')}
          </Text>
        </Grid>
        <ErrorSubDescription />
      </Grid>
    </PageWrapper>
  )
}

export default MaintenanceErrorPage

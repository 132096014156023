import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Spacing from 'components/Containers/Spacing'
import { Text, TextWithTooltip } from 'components/Typography'
import Tooltip from 'components/Tooltip'
import { getVideoDuration } from 'utils/mediaUtils'

const useStyles = makeStyles(({ colors }) => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    color: colors.light
  },
  opaque: {
    opacity: 0.1,
    cursor: 'auto'
  },
  textPlaceholder: {
    minHeight: 20
  }
}))

const VideoTutorialCardBottomInfo = ({ video }) => {
  const { t } = useTranslation()

  const { attributes } = video

  const classes = useStyles()

  const duration = getVideoDuration(attributes?.duration)

  return (
    <Spacing variant={0}>
      <Spacing paddingHor={1} paddingVert={1} variant={0} borderTop={1}>
        <TextWithTooltip
          maxWidth={324}
          variant="small"
          rootClassName={classes.textPlaceholder}
        >
          {attributes?.description}
        </TextWithTooltip>
      </Spacing>
      <Spacing
        item
        direction="row"
        alignItems="center"
        paddingHor={1}
        paddingVert={1}
        variant={0}
        justify="space-between"
      >
        <Grid item container xs={6} alignItems="center">
          <Tooltip arrow title={t('Knowledge base Article')}>
            <i
              className={classNames(
                'fa-sharp fa-regular fa-file-circle-question',
                classes.icon,
                { [classes.opaque]: !attributes?.knowledgeBaseArticleLink }
              )}
              onClick={() => {
                if (attributes?.knowledgeBaseArticleLink) {
                  window.open(attributes?.knowledgeBaseArticleLink, '_blank')
                }
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item container xs={6} alignItems="center" justify="flex-end">
          <Text variant="small">{duration || 'N/A'}</Text>
        </Grid>
      </Spacing>
    </Spacing>
  )
}

export default VideoTutorialCardBottomInfo

import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import {
  getBackgroundPattern,
  clearBackgroundData
} from 'actions/configActions'
import DefaultModal from 'components/Modal/DefaultModal'
import { FormControlColorPicker, FormControlReactSelect } from 'components/Form'
import BackgroundImageCard from 'components/Card/BackgroundImageCard'
import { BlueButton } from 'components/Buttons'
import BasePaginate from 'components/BasePaginate'
import Scrollbars from 'components/Scrollbars'
import { CircularLoader } from 'components/Loaders'
import Container from 'components/Containers/Container'
import Spacing from 'components/Containers/Spacing'
import Text from 'components/Typography/Text'
import MessageCard from 'components/Card/MessageCard'
import {
  useLazyGetMediaGridEmbeddedItemsQuery,
  useLazyGetMediaLibraryItemsQuery
} from 'api/mediaApi'
import useConfigFeatureMedia from 'hooks/api/useConfigFeatureMedia'
import { getThumbnail } from 'utils/mediaUtils'
import { featureNames } from 'constants/featureConstants'
import { backgroundTypes } from 'constants/media'
import { backgroundPatternsSelector } from 'selectors/configSelectors'
import { shapeOfMeta } from 'constants/initialLibraryState'
import { approveStatuses } from 'constants/library'
import { initialMeta } from 'constants/api'

const IMAGE_CARD_HEIGHT = 255
const GRID_GAP = 16

const useStyles = makeStyles(() => ({
  content: {
    overflow: 'visible'
  },
  imageCard: {
    height: IMAGE_CARD_HEIGHT,
    maxWidth: 'auto'
  },
  modalHeader: {
    borderRadius: '8px 8px 0 0',
    padding: '0 32px',
    minHeight: 55
  },
  modalFooter: {
    borderRadius: '0 0 8px 8px'
  },
  modalContainer: {
    overflowY: 'visible'
  },
  scrollbarRoot: {
    maxHeight: 'calc(100vh - 375px)'
  },
  messageRoot: {
    marginTop: '16px'
  },
  messageIconRoot: {
    maxWidth: '3% !important'
  },
  blurred: {
    filter: 'blur(7px)'
  },
  video: {
    borderRadius: '0 0 8px 8px'
  }
}))

const scrollbarsStyle = {
  height: IMAGE_CARD_HEIGHT * 2 + GRID_GAP
}

const initialTypeOptions = [
  { value: backgroundTypes.none, label: 'None' },
  { value: backgroundTypes.image, label: 'Image' },
  { value: backgroundTypes.pattern, label: 'Pattern' },
  { value: backgroundTypes.video, label: 'Video' },
  { value: backgroundTypes.default, label: 'Default' },
  { value: backgroundTypes.defaultImage, label: 'Default' }
]

const defaultOptionsConfig = {
  [backgroundTypes.none]: true,
  [backgroundTypes.image]: true,
  [backgroundTypes.pattern]: true,
  [backgroundTypes.video]: false,
  [backgroundTypes.defaultImage]: false,
  [backgroundTypes.default]: false
}

const SelectBackgroundDialogNew = ({
  onClickSave,
  pageRangeDisplayed = 5,
  values = {},
  names,
  onChange,
  returnPatternId = false,
  disableSelectType = false,
  disableImageSize = false,
  backgroundOptions,
  maxResolution,
  open,
  onCloseModal,
  optionsConfig = defaultOptionsConfig,
  ...props
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const [
    getBackgroundImagesFromMedia,
    mediaImageQuery
  ] = useLazyGetMediaGridEmbeddedItemsQuery()
  const [
    getBackgroundVideo,
    mediaVideoQuery
  ] = useLazyGetMediaLibraryItemsQuery()

  const imageReducer = useMemo(
    () => ({
      response: mediaImageQuery.data?.data || [],
      meta: mediaImageQuery.data?.meta || initialMeta,
      isLoading: mediaImageQuery.isFetching || mediaImageQuery.isUninitialized
    }),
    [mediaImageQuery]
  )

  const videoReducer = useMemo(
    () => ({
      response: mediaVideoQuery.data?.data || [],
      meta: mediaVideoQuery.data?.meta || initialMeta,
      isLoading: mediaVideoQuery.isFetching || mediaVideoQuery.isUninitialized
    }),
    [mediaVideoQuery]
  )

  const patternReducer = useSelector(backgroundPatternsSelector)
  const configFeatureMedia = useConfigFeatureMedia(false, [], { includeAll: 1 })

  const reducersByType = {
    [backgroundTypes.image]: imageReducer,
    [backgroundTypes.pattern]: patternReducer,
    [backgroundTypes.video]: videoReducer
  }

  const { type, image_id, color, pattern, size } = values

  const { response, meta, isLoading } = reducersByType[type] || shapeOfMeta

  const imageFeatureId = useMemo(
    () =>
      configFeatureMedia?.response?.find(
        ({ name }) => name === featureNames.Image
      )?.id,
    [configFeatureMedia]
  )

  const videoFeatureId = useMemo(
    () =>
      configFeatureMedia?.response?.find(
        ({ name }) => name === featureNames.Video
      )?.id,
    [configFeatureMedia]
  )

  const imageFetcher = (params = {}) => {
    getBackgroundImagesFromMedia({
      maxResolution,
      featureId: imageFeatureId,
      approvedStatus: approveStatuses.approve,
      ...params
    })
  }

  const videoFetcher = (params = {}) => {
    getBackgroundVideo({
      featureId: videoFeatureId,
      approvedStatus: approveStatuses.approve,
      ...params
    })
  }

  const patternFetcher = (params = {}) => {
    dispatch(
      getBackgroundPattern({
        type: 'html',
        ...params
      })
    )
  }

  const fetcherByType = {
    [backgroundTypes.image]: imageFetcher,
    [backgroundTypes.pattern]: patternFetcher,
    [backgroundTypes.video]: videoFetcher
  }

  const fetcher = (params = {}) => {
    if (fetcherByType[type]) {
      fetcherByType[type]({
        limit: 6,
        ...params
      })
    }
  }

  const handlePageChange = ({ selected }) => {
    fetcher({ page: selected + 1 })
  }

  const options = useMemo(() => {
    const config = { ...defaultOptionsConfig, ...optionsConfig }

    return backgroundOptions
      ? backgroundOptions
      : initialTypeOptions.filter(({ value }) => config[value])
  }, [optionsConfig, backgroundOptions])

  const renderItems = useMemo(
    () =>
      response?.map(item => {
        const { mediaUrl, title, id, tooltip, thumb, name } = item

        const propsByType = {
          [backgroundTypes.image]: {
            title,
            src: getThumbnail(item),
            isSelect: image_id === id,
            name: names.image_id
          },
          [backgroundTypes.pattern]: {
            title: tooltip,
            src: thumb,
            isSelect: pattern === (returnPatternId ? id : name),
            name: names.pattern
          },
          [backgroundTypes.video]: {
            title,
            isSelect: image_id === id,
            name: names.image_id,
            render: (
              <video
                src={mediaUrl}
                className={classNames(classes.video, {
                  [classes.blurred]: isLoading
                })}
                loop="loop"
                preload="metadata"
                height="100%"
                width="100%"
                muted
                onMouseOver={e => {
                  try {
                    e.target.play()
                    e.stopPropagation()
                    e.preventDefault()
                  } catch (e) {}
                }}
                onMouseLeave={e => {
                  try {
                    e.target.pause()
                    e.stopPropagation()
                    e.preventDefault()
                  } catch (e) {}
                }}
              />
            )
          }
        }

        return (
          <BackgroundImageCard
            key={`background-image-${id}`}
            className={classes.imageCard}
            id={id}
            onSelectImage={onChange}
            isLoading={isLoading}
            {...(propsByType[type] || {})}
          />
        )
      }),
    [
      isLoading,
      classes,
      onChange,
      names,
      response,
      image_id,
      pattern,
      returnPatternId,
      type
    ]
  )

  useEffect(() => {
    if (open) {
      const isAllowedToFetch =
        type === backgroundTypes.pattern ||
        (type === backgroundTypes.image && imageFeatureId) ||
        (type === backgroundTypes.video && videoFeatureId)

      if (isAllowedToFetch) {
        fetcher({ page: meta.currentPage || 1 })
      }
    }
    // eslint-disable-next-line
  }, [type, open, imageFeatureId, videoFeatureId])

  useEffect(() => {
    return () => {
      dispatch(clearBackgroundData())
    }
    // eslint-disable-next-line
  }, [])

  const modalWidth = useMemo(() => {
    return [
      backgroundTypes.image,
      backgroundTypes.pattern,
      backgroundTypes.video
    ].includes(type)
      ? 'lg'
      : 'sm'
  }, [type])

  const handleConfirm = useCallback(() => {
    onCloseModal(true)
  }, [onCloseModal])

  return (
    <DefaultModal
      modalTitle={t('Background Settings')}
      maxWidth={modalWidth}
      contentClass={classes.content}
      headerClassName={classes.modalHeader}
      footerClassName={classes.modalFooter}
      containerClassName={classes.modalContainer}
      actions={
        <BlueButton
          iconClassName="fa-regular fa-circle-check"
          onClick={handleConfirm}
        >
          {t('OK')}
        </BlueButton>
      }
      open={open}
      onCloseModal={onCloseModal}
      {...props}
    >
      <Container>
        <FormControlReactSelect
          options={options}
          label={t('Select Background')}
          value={type}
          name={names.type}
          disabled={disableSelectType}
          onChange={onChange}
          marginBottom={false}
        />
        {[
          backgroundTypes.none,
          backgroundTypes.pattern,
          backgroundTypes.default
        ].includes(type) && (
          <FormControlColorPicker
            label={t('Background Color')}
            name={names.color}
            value={color}
            onChange={onChange}
            marginBottom={false}
            disabled={type === backgroundTypes.default}
          />
        )}
        {type === backgroundTypes.image && (
          <FormControlReactSelect
            options={[
              { value: 'original', label: 'Original' },
              { value: 'stretch', label: 'Stretch' }
            ]}
            label={t('Image Size')}
            value={size}
            name={names.size}
            onChange={onChange}
            disabled={disableImageSize}
            marginBottom={false}
          />
        )}
      </Container>

      {maxResolution && type === backgroundTypes.image && (
        <MessageCard
          icon="fa-regular fa-circle-info"
          message={t('Resolution is less than', {
            maxResolution
          })}
          rootClassName={classes.messageRoot}
          iconRootClassName={classes.messageIconRoot}
        />
      )}

      {[
        backgroundTypes.image,
        backgroundTypes.pattern,
        backgroundTypes.video
      ].includes(type) && (
        <>
          <Spacing variant={0} paddingVert={2}>
            {isLoading && !response?.length && <CircularLoader />}
            <Scrollbars
              className={classes.scrollbarRoot}
              style={scrollbarsStyle}
            >
              {!isLoading && !response?.length ? (
                <Spacing
                  variant={0}
                  height="full"
                  justify="center"
                  alignItems="center"
                >
                  <Text>{t('Nothing to show')}</Text>
                </Spacing>
              ) : (
                <Container cols="3">{renderItems}</Container>
              )}
            </Scrollbars>
          </Spacing>
          {meta.lastPage > 1 && (
            <Spacing variant={0} alignItems="center" paddingBottom={2}>
              <BasePaginate
                page={meta.currentPage}
                pageCount={meta.lastPage}
                onPageChange={handlePageChange}
                pageRangeDisplayed={pageRangeDisplayed}
              />
            </Spacing>
          )}
        </>
      )}
    </DefaultModal>
  )
}

export default SelectBackgroundDialogNew

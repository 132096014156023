import React, { useState, useCallback, useMemo, useEffect } from 'react'
import MicrosoftLogin from './MicrosoftLogin'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google'

import { LinkedIn } from 'react-linkedin-login-oauth2'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from '@reduxjs/toolkit'
import { Grid, withStyles } from '@material-ui/core'
import { WhiteButton } from '../../Buttons'
import classNames from 'classnames'

import { FACEBOOK_APP_ID } from 'config'

import {
  loginOktaAction,
  getSSOData,
  clearLoginInfo
} from 'actions/authenticationActions'
import useSnackbar from 'hooks/useSnackbar'
import useRouter from 'hooks/router/useRouter'
import { handleFacebookLogout } from 'utils/windowUtils'
import { LAST_LOGIN_PROVIDER, redirectUrls, SYSTEM_ROLE } from 'constants/api'
import { googleLoginErrors } from 'constants/errorConstants'
import { storageGetItem, storageSetItem } from 'utils/localStorage'
import { DARK } from 'theme'

const styles = ({ palette, type, spacing }) => ({
  socialLoginWrapper: {
    width: '100%'
  },
  socialLogin: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'nowrap'
  },
  anotherSocialLogin: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  anotherOrgSocialLogin: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  socialLoginButtonWrapper: {
    paddingLeft: 8
  },
  socialLoginOrgButtonWrapper: {
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: 0
    }
  },
  socialLoginButton: {
    minWidth: 'unset',
    width: 40,
    height: 40,
    '& span': {
      fontSize: 11
    }
  },
  socialLoginOrgButton: {
    minWidth: 'unset',
    width: '100%',
    height: 48,

    '& span': {
      fontSize: 15,
      gap: '15px'
    }
  },
  systemSocialButton: {
    width: 56
  },
  socialLoginIcon: {
    fontSize: '2em'
  },
  facebook: {
    color: palette[type].pages.singIn.social.facebook,
    display: 'flex'
  },
  socialIcon: {
    display: 'flex'
  },
  linkedIn: {
    color: palette[type].pages.singIn.social.linkedIn,
    display: 'flex'
  },
  separatorBlock: {
    textAlign: 'center',
    color: '#74809A',
    textTransform: 'uppercase',
    padding: spacing(0, 1)
  },
  useAnotherMethodBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 11,
    marginBottom: 5
  },
  useAnotherMethodText: {
    color: '#0076b9',
    cursor: 'pointer',
    margin: 0,
    fontSize: 16,

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  lastLoginBlockWrapper: {
    overflow: 'hidden',
    width: '100%'
  },
  expandedAuthButton: {
    width: 225,
    height: 40,
    border: '1px solid #dadce0',
    borderRadius: 4,
    color: '#3c4043',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '2px 15px',

    '&:hover': {
      border: '1px solid #d2e3fc',
      backgroundColor: 'rgba(66,133,244,.04)'
    }
  },
  expandedGoogleButtonWrapper: {
    cursor: 'pointer'
  },
  expandedGoogleButton: {
    pointerEvents: 'none',
    height: 40,
    width: '100%',
    overflow: 'hidden'
  },
  signInText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#5f6368'
  },
  socialLoginIcons: {
    display: 'flex'
  },
  signinlogin: {
    width: '400px'
  }
})

const loginProviders = {
  google: 'google',
  microsoft: 'microsoft',
  linkedin: 'linkedin',
  facebook: 'facebook'
}

const lastLoginProvider = storageGetItem(LAST_LOGIN_PROVIDER)

const SocialPanel = ({
  classes,
  loginOktaAction,
  clearLoginInfo,
  getSSOData,
  renderOptions,
  role,
  isSignUp,
  errorMessage,
  loginWithAnotherMethod,
  setLoginWithAnotherMethod,
  signInButton,
  t
}) => {
  const { showSnackbar } = useSnackbar()
  const { routerPush } = useRouter()
  const [isCookiesUnavailable, setCookiesUnavailable] = useState(false)
  const [lastLoginAttempt, setLastLoginAttempt] = useState(null)

  const isSystem = useMemo(() => {
    return role === SYSTEM_ROLE
  }, [role])

  useEffect(
    () => {
      if (errorMessage) {
        showSnackbar(errorMessage, 'error')
        clearLoginInfo()
        if (lastLoginAttempt === loginProviders.facebook) {
          handleFacebookLogout()
        }
        setLastLoginAttempt(null)
      }
    },
    // eslint-disable-next-line
    [errorMessage]
  )

  const onLoginSuccess = useCallback(() => {
    routerPush({
      pathname: '/org/dashboard',
      state: { fromLogin: true }
    })
  }, [routerPush])

  const oktaAction = useCallback(
    data => {
      if (isSignUp) {
        getSSOData({ data, onLogin: onLoginSuccess })
      } else {
        setLastLoginAttempt(data?.provider)
        loginOktaAction(data, role)
      }
    },
    [isSignUp, getSSOData, onLoginSuccess, loginOktaAction, role]
  )

  const handleGoogleButtonClick = useGoogleLogin({
    onSuccess: tokenResponse => {
      oktaAction({
        provider: loginProviders.google,
        token: tokenResponse.access_token
      })
      storageSetItem('lastLoginProvider', loginProviders.google)
    },
    onError: error => {
      switch (error) {
        case googleLoginErrors.POPUP_CLOSED_BY_USER:
          break
        case googleLoginErrors.IDPIFRAME_INITIALIZATION_FAILED:
          setCookiesUnavailable(true)
          break
        case googleLoginErrors.ACCESS_DENIED:
          showSnackbar(
            `${t('Unable to login via Google.')} ${t(
              'Not enough permissions.'
            )}`,
            'error'
          )
          break
        default:
          showSnackbar(t('Unable to login via Google.'), 'error')
          break
      }
    }
  })

  const handleGoogleInitializationFailure = useCallback(({ error }) => {
    if (error === googleLoginErrors.IDPIFRAME_INITIALIZATION_FAILED) {
      setCookiesUnavailable(true)
    }
  }, [])

  const handleMicrosoftResponse = useCallback(
    token => {
      if (token) {
        oktaAction({ provider: loginProviders.microsoft, token })
        storageSetItem('lastLoginProvider', loginProviders.microsoft)
      } else {
        showSnackbar(
          t('Unable to login via provider.', { provider: 'Microsoft' }),
          'error'
        )
      }
    },
    [oktaAction, showSnackbar, t]
  )
  const handleLinkedInResponse = useCallback(
    (response = {}) => {
      if (response) {
        oktaAction({ provider: loginProviders.linkedin, code: response })
        storageSetItem('lastLoginProvider', loginProviders.linkedin)
      }
    },
    [oktaAction]
  )
  const handleFacebookResponse = useCallback(
    (response = {}) => {
      if (response.accessToken) {
        if (!(response.grantedScopes || '').includes('email')) {
          showSnackbar(
            t('Please provide permission to read the email address'),
            'error'
          )
          handleFacebookLogout()
          return
        }
        oktaAction({
          provider: loginProviders.facebook,
          token: response.accessToken
        })
        storageSetItem('lastLoginProvider', loginProviders.facebook)
      }
    },
    [oktaAction, showSnackbar, t]
  )
  const handleLinkedInError = useCallback(error => {
    console.error(error)
  }, [])
  const renderFacebook = useMemo(() => {
    if (!renderOptions.facebook) return null
    if (!FACEBOOK_APP_ID) return null

    const facebookIcon = (
      <i
        className={classNames(
          classes.facebook,
          classes.socialLoginIcon,
          'mdi mdi-facebook'
        )}
      />
    )

    return (
      <Grid
        item
        key="login-social-facebook"
        className={
          isSystem || isSignUp
            ? classes.socialLoginButtonWrapper
            : classes.socialLoginOrgButtonWrapper
        }
      >
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          callback={handleFacebookResponse}
          redirectUri={redirectUrls.facebook}
          returnScopes
          authType="rerequest"
          render={renderProps =>
            lastLoginProvider === loginProviders.facebook &&
            !loginWithAnotherMethod ? (
              <div
                className={classes.expandedAuthButton}
                onClick={renderProps.onClick}
              >
                {facebookIcon}
                <p className={classes.signInText}>
                  {t('Sign in with social media', { socialMedia: 'Facebook' })}
                </p>
              </div>
            ) : (
              <WhiteButton
                classes={{
                  root:
                    isSystem || isSignUp
                      ? classes.socialLoginButton
                      : classes.socialLoginOrgButton
                }}
                onClick={renderProps.onClick}
              >
                {facebookIcon}
                {!isSystem && !isSignUp && (
                  <span> {t('Sign in with Facebook')}</span>
                )}
              </WhiteButton>
            )
          }
        />
      </Grid>
    )
  }, [
    renderOptions,
    classes,
    handleFacebookResponse,
    loginWithAnotherMethod,
    isSystem,
    isSignUp,
    t
  ])
  const renderMicrosoft = useMemo(() => {
    if (!renderOptions.microsoft) return null
    if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID) return null

    const microsoftIcon = (
      <i
        className={classNames(
          classes.socialIcon,
          classes.socialLoginIcon,
          'mdi mdi-microsoft'
        )}
        style={{ color: '#8d30a5' }}
      />
    )

    return (
      <Grid
        item
        key="login-social-microsoft"
        className={
          isSystem || isSignUp
            ? classes.socialLoginButtonWrapper
            : classes.socialLoginOrgButtonWrapper
        }
      >
        <MicrosoftLogin
          clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
          redirectUri={redirectUrls.microsoft}
          responseType="token"
          handleLogin={handleMicrosoftResponse}
          prompt="select_account"
          withPollInterval={false}
          btnContent={() => (
            <>
              {lastLoginProvider === loginProviders.microsoft &&
              !loginWithAnotherMethod ? (
                <div className={classes.expandedAuthButton}>
                  {microsoftIcon}
                  <p className={classes.signInText}>
                    {t('Sign in with social media', {
                      socialMedia: 'Microsoft'
                    })}
                  </p>
                </div>
              ) : (
                <WhiteButton
                  classes={{
                    root:
                      isSystem || isSignUp
                        ? classes.socialLoginButton
                        : classes.socialLoginOrgButton
                  }}
                >
                  {microsoftIcon}
                  {!isSystem && !isSignUp && (
                    <span>{t('Sign in with Microsoft')}</span>
                  )}
                </WhiteButton>
              )}
            </>
          )}
        />
      </Grid>
    )
  }, [
    renderOptions,
    classes,
    handleMicrosoftResponse,
    loginWithAnotherMethod,
    isSystem,
    isSignUp,
    t
  ])

  const renderGoogle = useMemo(() => {
    if (!renderOptions.google) return null
    if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) return null

    if (isCookiesUnavailable) {
      return () => {
        showSnackbar(
          `${t('Unable to login via Google.')} ${t(
            'Please check if third-party cookies are enabled in your browser.'
          )}`,
          'error'
        )
      }
    }

    if (
      lastLoginProvider === loginProviders.google &&
      !loginWithAnotherMethod &&
      !isSignUp &&
      !isSystem
    ) {
      return (
        <div
          className={classes.expandedGoogleButtonWrapper}
          onClick={() => handleGoogleButtonClick({ prompt: '' })}
        >
          <div className={classes.expandedGoogleButton}>
            <GoogleLogin
              onScriptLoadError={handleGoogleInitializationFailure}
              theme={
                storageGetItem('theme') === DARK ? 'filled_blue' : 'outline'
              }
            />
          </div>
        </div>
      )
    }

    return (
      <Grid
        item
        key="login-social-google"
        className={
          isSystem || isSignUp
            ? classes.socialLoginButtonWrapper
            : classes.socialLoginOrgButtonWrapper
        }
      >
        <WhiteButton
          classes={{
            root: classNames({
              [classes.socialLoginButton]: isSystem || isSignUp,
              [classes.socialLoginOrgButton]: !isSystem && !isSignUp,
              [classes.systemSocialButton]: isSystem
            })
          }}
          onClick={() => handleGoogleButtonClick()}
        >
          <i
            className={classNames(
              classes.socialIcon,
              classes.socialLoginIcon,
              'mdi mdi-google'
            )}
            style={{ color: '#ed4b2d' }}
          />
          {!isSystem && !isSignUp && <span>{t('Sign in with Google')}</span>}
        </WhiteButton>
      </Grid>
    )
  }, [
    classes,
    handleGoogleButtonClick,
    isSystem,
    renderOptions,
    handleGoogleInitializationFailure,
    isCookiesUnavailable,
    isSignUp,
    loginWithAnotherMethod,
    showSnackbar,
    t
  ])

  const renderLinkedIn = useMemo(() => {
    if (!renderOptions.linkedin) return null
    if (!process.env.REACT_APP_LINKEDIN_CLIENT_ID) return null

    const linkedInIcon = (
      <i
        className={classNames(
          classes.socialLoginIcon,
          classes.linkedIn,
          'mdi mdi-linkedin'
        )}
      />
    )

    return (
      <LinkedIn
        clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
        onFailure={handleLinkedInError}
        onSuccess={handleLinkedInResponse}
        scope="r_liteprofile,r_emailaddress"
        redirectUri={redirectUrls.linkedin}
      >
        {({ linkedInLogin, disabled }) => (
          <Grid
            item
            key="login-social-google"
            className={
              isSystem
                ? classes.socialLoginButtonWrapper
                : classes.socialLoginOrgButtonWrapper
            }
          >
            <>
              {lastLoginProvider === loginProviders.linkedin &&
              !loginWithAnotherMethod ? (
                <div
                  className={classes.expandedAuthButton}
                  onClick={linkedInLogin}
                >
                  {linkedInIcon}
                  <p className={classes.signInText}>
                    {t('Sign in with social media', {
                      socialMedia: 'LinkedIn'
                    })}
                  </p>
                </div>
              ) : (
                <WhiteButton
                  classes={{
                    root:
                      isSystem || isSignUp
                        ? classes.socialLoginButton
                        : classes.socialLoginOrgButton
                  }}
                  onClick={linkedInLogin}
                  disabled={disabled}
                >
                  {linkedInIcon}
                  {!isSystem && !isSignUp && (
                    <span>{t('Sign in with Linkedin')}</span>
                  )}
                </WhiteButton>
              )}
            </>
          </Grid>
        )}
      </LinkedIn>
    )
  }, [
    renderOptions,
    classes,
    handleLinkedInError,
    handleLinkedInResponse,
    loginWithAnotherMethod,
    isSystem,
    isSignUp,
    t
  ])

  const renderLastSocialLogin = useMemo(() => {
    switch (lastLoginProvider) {
      case loginProviders.microsoft:
        return renderMicrosoft
      case loginProviders.facebook:
        return renderFacebook
      case loginProviders.linkedin:
        return renderLinkedIn
      case loginProviders.google:
        return renderGoogle
      default:
        return renderGoogle
    }
  }, [renderMicrosoft, renderFacebook, renderLinkedIn, renderGoogle])

  const separatorBlock = useMemo(
    () => (
      <Grid item className={classes.separatorBlock}>
        {t('or')}
      </Grid>
    ),
    [classes.separatorBlock, t]
  )

  return (
    <>
      {lastLoginProvider &&
      !loginWithAnotherMethod &&
      !isSystem &&
      !isSignUp ? (
        <div className={classes.signinlogin}>
          <Grid
            container
            justify="space-between"
            className={classes.socialLogin}
          >
            <Grid item className={classes.lastLoginBlockWrapper}>
              {renderLastSocialLogin}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.useAnotherMethodBlock}>
            <p
              className={classes.useAnotherMethodText}
              onClick={() => setLoginWithAnotherMethod(true)}
            >
              {t('Login using another method')}
            </p>
          </Grid>
        </div>
      ) : (
        <div
          className={
            isSystem || isSignUp
              ? classes.anotherSocialLogin
              : classes.anotherOrgSocialLogin
          }
        >
          <Grid item>{signInButton}</Grid>
          {isSystem && !loginWithAnotherMethod && !isSignUp && separatorBlock}
          <div
            className={
              isSystem || isSignUp
                ? classes.socialLoginIcons
                : classes.signinlogin
            }
          >
            {!isSystem && renderFacebook}
            {!isSystem && renderMicrosoft}
            {renderGoogle}
            {!isSystem && renderLinkedIn}
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ login }) => ({
  errorMessage: login.error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginOktaAction,
      getSSOData,
      clearLoginInfo
    },
    dispatch
  )

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SocialPanel)

import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import FormColorPicker from './FormColorPicker'
import { simulateEvent } from 'utils'
import BaseFormControl from '../BaseFormControl'
import Tooltip from 'components/Tooltip'
import classNames from 'classnames'

const useStyles = makeStyles(
  ({ palette, type, fontSize, lineHeight, fontWeight, colors, spacing }) => ({
    root: {
      position: 'relative',
      width: '100%'
    },
    rightLabel: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center'
    },
    topLabel: {
      display: 'flex',
      flexDirection: 'column'
    },
    leftLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    bottomLabel: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    bootstrapFormLabel: {
      fontSize: fontSize.primary,
      lineHeight: lineHeight.primary,
      fontWeight: fontWeight.normal,
      color: palette[type].formControls.label.color,
      whiteSpace: 'pre',
      transform: 'none'
    },
    alignLabel: {
      alignSelf: 'flex-start'
    },
    topLabelMargin: {
      marginBottom: 0
    },
    bottomLabelMargin: {
      marginTop: '7px'
    },
    label: {
      position: 'unset !important'
    },
    labelSmall: {
      fontSize: `${fontSize.small}px !important`
    },
    labelSmallest: {
      fontSize: `${fontSize.smallest}px !important`
    },
    bootstrapFormLabelFocus: {
      color: `${palette[type].formControls.label.color} !important`
    },
    labelLink: {
      display: 'unset',
      position: 'unset !important',
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
      textDecorationColor: colors.highlight,
      textUnderlineOffset: '2px',
      '&:hover': {
        cursor: 'pointer',
        textDecorationStyle: 'solid'
      }
    },
    colorRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 3,
      paddingTop: 5
    },
    marginBottom: {
      marginBottom: spacing(2)
    },
    labelDisabled: {
      opacity: 0.38
    }
  })
)

const FormControlMultipleColorPicker = ({
  t,
  numberOfColors = 0,
  values = [],
  error,
  touched,
  label,
  name,
  names = [],
  onChange,
  marginBottom = false,
  labelPosition = 'top',
  labelFontSizeVariant,
  tooltip,
  formControlContainerClass,
  formControlLabelClass,
  isOptional = false,
  isClearable = false,
  disabled,
  errorTextClass,
  absoluteErrorText = true,
  inSequencePicker = false,
  disablePortal,
  clearColorCb
}) => {
  const classes = useStyles()

  const handleChangeColor = useCallback(
    e => {
      const {
        target: { name: _name, value }
      } = e
      if (name) {
        const _values = [...values]
        _values[_name] = value
        onChange(simulateEvent(name, _values))
      } else {
        onChange(e)
      }
    },
    [onChange, name, values]
  )

  const isColorPickerDisabled = useCallback(
    index => {
      if (inSequencePicker) {
        if (index === 0) return false
        return !(values[names[index - 1]] || values[index - 1])
      }
      return false
    },
    [inSequencePicker, values, names]
  )

  return (
    <BaseFormControl
      error={error}
      touched={touched}
      label={label}
      marginBottom={marginBottom}
      labelPosition={labelPosition}
      labelFontSizeVariant={labelFontSizeVariant}
      tooltip={tooltip}
      formControlContainerClass={formControlContainerClass}
      formControlLabelClass={formControlLabelClass}
      isOptional={isOptional}
      errorTextClass={errorTextClass}
      absoluteErrorText={absoluteErrorText}
    >
      <div
        className={classNames(classes.colorRoot, {
          [classes.labelDisabled]: disabled
        })}
      >
        {Array(numberOfColors)
          .fill()
          .map((_, index) => {
            const colorPicker = (
              <div className={index}>
                <FormColorPicker
                  name={names[index] || index}
                  value={values[names[index]] || values[index]}
                  handleChange={handleChangeColor}
                  isClearable={isClearable}
                  disabled={disabled || isColorPickerDisabled(index)}
                  disablePortal={disablePortal}
                  clearColorCb={clearColorCb}
                  clearColorOnClick
                  changeOnClose
                  onChangeDelay={0}
                />
              </div>
            )

            if (!values[names[index]] && !values[index]) {
              return colorPicker
            }

            return (
              <Tooltip
                key={`multiple-color-${index}`}
                title={values[names[index]] || values[index]}
                arrow
              >
                {colorPicker}
              </Tooltip>
            )
          })}
      </div>
    </BaseFormControl>
  )
}

export default withTranslation('translations')(FormControlMultipleColorPicker)

import React, { useCallback, useMemo, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import MaterialPopup from 'components/Popup/MaterialPopup'
import Scrollbars from 'components/Scrollbars'
import { FormControlInput } from 'components/Form'
import { simulateEvent } from 'utils'
import fontAwesome5Icons from 'constants/fontAwesome5Icons'
import handleBottomScroll from 'utils/handleBottomScroll'

const useStyles = makeStyles(({ colors, palette, type }) => ({
  popupRoot: {
    maxWidth: 270
  },
  searchWrapper: {
    padding: '15px 20px 10px 20px'
  },
  icon: {
    color: colors.light,
    fontSize: 22,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 20
  },
  iconContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    padding: '10px 20px',
    gridGap: 20
  },
  input: {
    height: 32,
    width: 32,
    padding: 2,
    borderRadius: 4,
    backgroundColor: palette[type].formControls.input.background,
    border: `1px solid ${palette[type].formControls.input.border}`,
    cursor: 'pointer'
  }
}))

const iconStyles = {
  solid: 'fa-solid',
  regular: 'fa-regular',
  light: 'fa-light',
  thin: 'fa-thin',
  duotone: 'fa-duotone'
}

const ICONS_LIMIT_STEP = 200

const FormControlIconPicker = ({
  name,
  value,
  onChange,
  disablePortal = false,
  placement = 'bottom',
  iconsData,
  rootClassName
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [search, setSearch] = useState()
  const [iconsLimit, setIconsLimit] = useState(ICONS_LIMIT_STEP)

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value)
  }

  const icons = useMemo(() => {
    const data = []

    if (!iconsData) {
      return fontAwesome5Icons
    }

    iconsData.forEach(icon => {
      const { classicStyles, sharpStyles, name } = icon

      const classicData =
        classicStyles
          ?.filter(style => style !== 'brands')
          .map(item => `${iconStyles[item]} fa-${name}`) || []

      const sharpData =
        sharpStyles?.map(item => `fa-sharp ${iconStyles[item]} fa-${name}`) ||
        []

      const brandData =
        !icon.hasOwnProperty('classicStyles') &&
        !icon.hasOwnProperty('sharpStyles')
          ? [`fa-brands fa-${name}`]
          : []

      data.push(...[...classicData, ...sharpData, ...brandData])
    })

    return data
  }, [iconsData])

  const filteredIcons = useMemo(() => {
    if (!search) {
      return icons
    }

    const term = search.toLowerCase().trim()

    return icons.filter(icon => icon.includes(term))
  }, [icons, search])

  const displayIcons = useMemo(() => {
    return filteredIcons.slice(0, iconsLimit)
  }, [filteredIcons, iconsLimit])

  const handleLoadMore = useCallback(
    () => setIconsLimit(prev => prev + ICONS_LIMIT_STEP),
    []
  )

  return (
    <div className={classNames(rootClassName)}>
      <MaterialPopup
        on="click"
        placement={placement}
        disablePortal={disablePortal}
        trigger={
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.input}
          >
            {value && <i className={classNames(value, classes.icon)} />}
          </Grid>
        }
        rootClassName={classes.popupRoot}
      >
        <div>
          <div className={classes.searchWrapper}>
            <FormControlInput
              value={search}
              onChange={handleSearchChange}
              marginBottom={false}
              placeholder={t('Search')}
            />
          </div>
          <Scrollbars
            autoHeight
            autoHeightMax={300}
            onUpdate={handleBottomScroll(handleLoadMore)}
          >
            <div className={classes.iconContainer}>
              {displayIcons.map(icon => (
                <i
                  className={classNames(icon, classes.icon)}
                  key={icon}
                  onClick={() => onChange(simulateEvent(name, icon))}
                />
              ))}
            </div>
          </Scrollbars>
        </div>
      </MaterialPopup>
    </div>
  )
}

export default FormControlIconPicker

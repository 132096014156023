import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, Redirect } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Link, makeStyles } from '@material-ui/core'
import * as Yup from 'yup'

import { Text } from 'components/Typography'
import { FormControlInput, FormControlReactSelect } from 'components/Form'
import Spacing from 'components/Containers/Spacing'
import RectLoader from 'components/Loaders/RectLoader'
import SignInWrapper from 'components/Account/SignInWrapper'
import SubmitButton from 'components/Account/SignIn/SubmitButton'
import { usePostDeviceMutation } from 'api/deviceApi'
import { useGetDeviceTypesQuery } from 'api/configApi'
import { userDetailsSelector } from 'selectors/userSelectors'
import { getUserDetailsAction } from 'actions/userActions'
import useMutationResultHandler from 'hooks/useMutationResultHandler'
import useSnackbar from 'hooks/useSnackbar'
import { useUserRole } from 'hooks/tableLibrary'
import usePermissions from 'hooks/api/usePermissions'
import useSearchParams from 'hooks/tableLibrary/useSearchParams'
import { getUrlPrefix } from 'utils'
import { isStorageHasToken } from 'utils/apiUtils'
import { isTrialClient, isTruthy } from 'utils/generalUtils'
import { activationCodeRegExp } from 'constants/regExp'
import {
  requiredField,
  validActivationCode
} from 'constants/validationMessages'
import { permissionNames } from 'constants/index'

const useStyles = makeStyles(() => ({
  inputWrap: {
    position: 'relative',
    marginBottom: '16px'
  },
  formControlInput: {
    height: '50px',
    fontSize: '18px'
  },
  formControlContainer: {
    marginBottom: '10px'
  },
  submitButtonRoot: {
    width: 'fit-content'
  },
  dashboardText: {
    color: '#0076b9',
    fontSize: 13
  },
  selectError: {
    position: 'unset'
  }
}))

const selectStyles = {
  control: {
    height: '50px',
    '& > div': {
      overflow: 'visible'
    }
  },
  input: {
    fontSize: '18px'
  },
  placeholder: {
    fontSize: '18px'
  },
  singleValue: {
    fontSize: '18px'
  }
}

const ActivateDevice = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const classes = useStyles()

  const { showSnackbar } = useSnackbar()
  const { deviceId, type } = useSearchParams({ search: location.search })
  const { getPermissionByName } = usePermissions()
  const role = useUserRole()

  const details = useSelector(userDetailsSelector)
  const [typesVisible, setTypesVisible] = useState(false)

  const [postDevice, result] = usePostDeviceMutation()
  const {
    data: deviceTypes,
    isFetching: isDeviceTypesFetching,
    isUninitialized: isDeviceTypesUninitialized
  } = useGetDeviceTypesQuery({ roleLevel: role.role }, { skip: !role.role })
  const [successResult, setSuccessResult] = useState(null)

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    isValid,
    setFieldError,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: { activationCode: '', deviceTypeId: '' },
    onSubmit: values => {
      postDevice(values)
    },
    validationSchema: Yup.object().shape({
      activationCode: Yup.string()
        .min(12, t('Activation code must consist of 12 characters'))
        .max(12, t('Activation code must consist of 12 characters'))
        .matches(activationCodeRegExp, t(validActivationCode))
        .required(t(requiredField)),
      deviceTypeId: Yup.string().required(t(requiredField))
    })
  })

  useMutationResultHandler({
    result,
    keyWord: 'Device',
    actionText: 'activated',
    notificationOptions: { error: false },
    onSuccess: result => setSuccessResult(result),
    onError: ({ error }) => {
      if (error.errorFields?.length) {
        error.errorFields.forEach(({ name, value }) => {
          if (name === 'activationCode') {
            setFieldError('activationCode', value.join(' '))
          } else {
            showSnackbar(value.join(' '), 'error')
          }
        })
      } else {
        showSnackbar(error.message, 'error')
      }
    }
  })

  const deviceTypeOptions = useMemo(() => {
    return (deviceTypes || []).map(({ id, alias }) => ({
      value: id,
      label: alias
    }))
  }, [deviceTypes])

  useEffect(() => {
    if (isStorageHasToken() && !details.response) {
      dispatch(getUserDetailsAction())
    }
    if (deviceId) {
      setFieldValue('activationCode', deviceId)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (deviceTypes?.length === 1) {
      setFieldValue('deviceTypeId', deviceTypes[0].id)
      return
    } else if (type && deviceTypes?.length) {
      const targetedType = deviceTypes.find(
        ({ alias }) => alias?.toLowerCase() === type.toLowerCase()
      )

      if (targetedType) {
        setFieldValue('deviceTypeId', targetedType.id)
        return
      }
    }

    if (deviceTypes) {
      setTypesVisible(true)
    }
    // eslint-disable-next-line
  }, [deviceTypes])

  const renderFormContent = useMemo(() => {
    const { client } = details?.response || {}

    if (details.loading) {
      return (
        <>
          <Spacing variant={3}>
            <RectLoader height={50} padding={0} />
          </Spacing>
          <Spacing variant={4} alignItems="center">
            <RectLoader height={40} width={138} padding={0} />
          </Spacing>
        </>
      )
    }

    const isTrial = isTrialClient(role, client)
    const hasLicenses =
      !!client?.totalDeviceLicense && !!parseInt(client.totalDeviceLicense)
    const hasCreatePermission = getPermissionByName(
      permissionNames.CLIENT_DEVICE_STORE
    )

    if (!isTruthy(isTrial, hasLicenses, hasCreatePermission)) {
      return (
        <Spacing variant={4}>
          <Spacing>
            <Text color="title.primary" variant="big">
              {`${t(
                'You cannot activate the device due to the following reason(s)'
              )}:`}
            </Text>
          </Spacing>
          {!isTrial && (
            <Text color="title.primary">
              {t('Your account is not allowed to activate the device')}
            </Text>
          )}
          {!hasCreatePermission && (
            <Text color="title.primary">{t('Not enough permissions')}</Text>
          )}
          {!hasLicenses && (
            <Text color="title.primary">{t('Not enough licences')}</Text>
          )}
        </Spacing>
      )
    }

    if (successResult?.isSuccess) {
      return (
        <Spacing direction="row" variant={4}>
          <Text color="title.primary" variant="bigger">
            {t('Successfully activated')}
          </Text>
        </Spacing>
      )
    }

    return (
      <>
        <FormControlInput
          type="text"
          name="activationCode"
          value={values.activationCode}
          handleChange={handleChange}
          error={errors.activationCode}
          handleBlur={handleBlur}
          touched={touched.activationCode}
          placeholder={t('Enter Device ID')}
          formControlInputClass={classes.formControlInput}
          formControlContainerClass={classes.formControlContainer}
          fullWidth
        />
        {typesVisible &&
          !isDeviceTypesFetching &&
          !isDeviceTypesUninitialized && (
            <FormControlReactSelect
              options={deviceTypeOptions}
              styles={selectStyles}
              isLoading={isDeviceTypesFetching}
              name="deviceTypeId"
              value={values.deviceTypeId}
              placeholder={t('Select Device Type')}
              handleChange={handleChange}
              error={errors.deviceTypeId}
              touched={touched.deviceTypeId}
              errorClassNames={classes.selectError}
              formControlContainerClass={classes.formControlContainer}
              fullWidth
              withPortal
              marginBottom={24}
            />
          )}
        <Spacing direction="row" justify="center" variant={4}>
          <SubmitButton
            opaque={!isValid}
            isLoading={result.isLoading}
            rootClass={classes.submitButtonRoot}
            onClick={handleSubmit}
          >
            {t('Submit')}
          </SubmitButton>
        </Spacing>
      </>
    )
  }, [
    classes,
    details,
    getPermissionByName,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    result,
    successResult,
    role,
    t,
    touched,
    values,
    deviceTypeOptions,
    isDeviceTypesFetching,
    isDeviceTypesUninitialized,
    typesVisible
  ])

  if (!isStorageHasToken()) {
    return <Redirect to={{ pathname: '/sign-in', state: { from: location } }} />
  }

  return (
    <SignInWrapper title={t('Activate Device')} isSystemUser={false}>
      <div className={classes.inputWrap}>
        {renderFormContent}
        <Spacing direction="row" justify="center" alignItems="center">
          <Link
            to={getUrlPrefix('dashboard')}
            className={classes.dashboardText}
            component={RouterLink}
          >
            {t('Go to Dashboard')}
          </Link>
        </Spacing>
      </div>
    </SignInWrapper>
  )
}

export default ActivateDevice

import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Text } from 'components/Typography'
import Spacing from 'components/Containers/Spacing'
import { menuItemTypes } from 'constants/contextMenuConstants'

const useStyles = makeStyles(({ colors, type, spacing }) => ({
  root: {
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: 'rgba(134,134,134, 0.15)'
    }
  },
  icon: {
    color: colors.title.primary[type]
  },
  disabled: {
    cursor: 'default',
    opacity: 0.5,
    pointerEvents: 'none'
  }
}))

const MenuItem = ({ item, closeContextMenu }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { icon, label, description, callback, disabled, type } = item

  return (
    <Spacing
      item
      direction="row"
      alignItems="center"
      onClick={() => {
        callback()
        closeContextMenu()
      }}
      rootClassName={classNames(classes.root, {
        [classes.disabled]: disabled || type === menuItemTypes.delimiter
      })}
      variant={0}
      paddingHor={2}
      paddingVert={1}
      {...(type === menuItemTypes.delimiter && {
        variant: 1,
        paddingVert: 0,
        paddingTop: 1,
        borderBottom: 1
      })}
    >
      {type === menuItemTypes.item && (
        <>
          <Grid item xs={2}>
            <i className={classNames(icon, classes.icon)} />
          </Grid>
          <Grid item xs={5}>
            <Text color="title.primary">{t(label)}</Text>
          </Grid>
          <Grid container item xs={5} justify="flex-end">
            <Text variant="small">{description}</Text>
          </Grid>
        </>
      )}
    </Spacing>
  )
}

export default MenuItem

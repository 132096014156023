export const SYSTEM_MEDIA_COPY = 'system media copy'
export const SYSTEM_PLAYLIST_COPY = 'system playlist copy'
export const SYSTEM_TEMPLATE_COPY = 'system template copy'
export const SYSTEM_SCHEDULE_COPY = 'system schedule copy'
export const ENTERPRISE_MEDIA_COPY = 'enterprise media copy'
export const ENTERPRISE_PLAYLIST_COPY = 'enterprise playlist copy'
export const ENTERPRISE_TEMPLATE_COPY = 'enterprise template copy'
export const CLIENT_MEDIA_APPROVAL = 'client media approval'
export const CLIENT_PLAYLIST_APPROVAL = 'client playlist approval'
export const CLIENT_TEMPLATE_APPROVAL = 'client template approval'
export const CLIENT_SCHEDULE_APPROVAL = 'client schedule approval'
export const SYSTEM_CLIENT_IMPERSONATE = 'system impersonate client users'
export const ENTERPRISE_CLIENT_IMPERSONATE =
  'enterprise impersonate client users'
export const RESELLER_CLIENT_IMPERSONATE = 'reseller client impersonate'
export const RESELLER_CLIENT_USER_READ = 'reseller associated client user show'
export const CLIENT_MEDIA_STORE = 'client media store'
export const ORG_USER_PROFILE_IMAGE_READ = 'org image profile image show'
export const SYSTEM_USER_PROFILE_IMAGE_READ = 'system image profile image show'
export const ENTERPRISE_USER_PROFILE_IMAGE_READ =
  'enterprise image profile image show'
export const SYSTEM_DEVICE_ERROR_LOG = 'system get device error log'
export const ORG_DEVICE_ERROR_LOG = 'org get device error log'
export const SYSTEM_CLIENT_BLOCKING = 'system client blocking'
export const ORG_MEDIA_SHORELINE_PRICE_SHOW = 'org media shoreline price show'
export const ORG_MEDIA_SHORELINE_PRICE_STORE = 'org media shoreline price store'
export const ORG_MEDIA_SHORELINE_EVENT_SHOW =
  'org media shoreline event type show'
export const ORG_MEDIA_SHORELINE_EVENT_STORE =
  'org media shoreline event type store'
export const CLIENT_DESIGN_GALLERY_SHOW = 'client design gallery show'
export const CLIENT_DESIGN_GALLERY_STORE = 'client design gallery store'
export const CLIENT_DESIGN_GALLERY_UPDATE = 'client design gallery update'
export const ORG_USER_DEVICE_HEALTH_READ = 'org show device health'
export const SYSTEM_USER_DEVICE_HEALTH_READ = 'system show device health'
export const SYSTEM_RUN_REPORT = 'system run report'
export const ORG_RUN_REPORT = 'org run report'
export const SYSTEM_DESIGN_GALLERY_SHARE = 'system design gallery share'
export const SYSTEM_BANNER_CLONE = 'system banner clone'
export const SYSTEM_TRIGGER_DEVICE_HEALTH = 'system trigger device health'
export const SYSTEM_DEVICE_THUMBNAIL_TRIGGER = 'system device thumbnail trigger'
export const SYSTEM_MEDIA_SHARE = 'system media share'
export const SYSTEM_PLAYLIST_SHARE = 'system playlist share'
export const SYSTEM_TEMPLATE_SHARE = 'system template share'
export const ENTERPRISE_DESIGN_GALLERY_SHARE = 'enterprise design gallery share'
export const ENTERPRISE_BANNER_CLONE = 'enterprise banner clone'
export const ENTERPRISE_TRIGGER_DEVICE_HEALTH =
  'enterprise trigger device health'
export const ENTERPRISE_DEVICE_THUMBNAIL_TRIGGER =
  'enterprise device thumbnail trigger'
export const ENTERPRISE_MEDIA_SHARE = 'enterprise media share'
export const ENTERPRISE_PLAYLIST_SHARE = 'enterprise playlist share'
export const ENTERPRISE_TEMPLATE_SHARE = 'enterprise template share'
export const ENTERPRISE_SCHEDULE_COPY = 'enterprise schedule copy'
export const ORG_DEVICE_NOC = 'org device noc'
export const SYSTEM_SHOW_REPORTS_CONFIG = 'system show reports config'
export const SYSTEM_SHOW_REPORT_TABLE_CONFIG = 'system show report table config'
export const ORG_SHOW_REPORTS_CONFIG = 'org show reports config'
export const ORG_SHOW_REPORT_TABLE_CONFIG = 'org show report table config'
export const SYSTEM_USER_LIBRARY_RECORD_EXPORT =
  'system user library record export'
export const SYSTEM_CLIENT_LIBRARY_RECORD_EXPORT =
  'system client library record export'
export const SYSTEM_CLIENT_USER_LIBRARY_RECORD_EXPORT =
  'system clientUser library record export'
export const SYSTEM_DEVICE_LIBRARY_RECORD_EXPORT =
  'system device library record export'
export const SYSTEM_MEDIA_LIBRARY_RECORD_EXPORT =
  'system media library record export'
export const SYSTEM_PLAYLIST_LIBRARY_RECORD_EXPORT =
  'system playlist library record export'
export const SYSTEM_TEMPLATE_LIBRARY_RECORD_EXPORT =
  'system template library record export'
export const SYSTEM_SCHEDULE_LIBRARY_RECORD_EXPORT =
  'system schedule library record export'
export const CLIENT_DEVICE_STORE = 'client device store'
export const SYSTEM_DEVICE_STORE = 'system device store'

export const IP_PROVIDER_SHOW = 'ip provider show'
export const IP_PROVIDER_UPDATE = 'ip provider update'

export const CLIENT_DEVICE_SHOW = 'client device show'
export const SYSTEM_DEVICE_SHOW = 'system device show'
export const ENTERPRISE_DEVICE_SHOW = 'enterprise device show'

export const CLIENT_DEVICE_UPDATE = 'client device update'
export const SYSTEM_DEVICE_UPDATE = 'system device update'
export const ENTERPRISE_DEVICE_UPDATE = 'enterprise device update'

export const SYSTEM_DEVICE_DESTROY = 'system device destroy'

export const CLIENT_DEVICE_ALERT_SHOW = 'client device alert show'
export const CLIENT_DEVICE_ALERT_MANAGE = 'client device alert manage'

export const SHOW_DEVICE_GROUP_ASSOCIATION = 'show device group association'
export const CREATE_DEVICE_GROUP_ASSOCIATION = 'create device group association'
export const DELETE_DEVICE_GROUP_ASSOCIATION = 'delete device group association'

export const DEVICE_OFFLINE_NOTIFICATION_SHOW =
  'device offline notification show'
export const DEVICE_OFFLINE_NOTIFICATION_STORE =
  'device offline notification store'
export const DEVICE_OFFLINE_NOTIFICATION_UPDATE =
  'device offline notification update'
export const DEVICE_OFFLINE_NOTIFICATION_DELETE =
  'device offline notification delete'

export const ORG_DEVICE_LOCATION_VIEW_SHOW = 'org device location view show'
export const ORG_DEVICE_LOCATION_VIEW_CREATE = 'org device location view create'
export const ORG_DEVICE_LOCATION_VIEW_UPDATE = 'org device location view update'
export const ORG_DEVICE_LOCATION_VIEW_DELETE = 'org device location view delete'

export const ORG_SHOW_DEVICE_HEALTH = 'org show device health'
export const SYSTEM_SHOW_DEVICE_HEALTH = 'system show device health'
export const ENTERPRISE_SHOW_DEVICE_HEALTH = 'enterprise show device health'

export const CLIENT_DEVICE_REBOOT_SHOW = 'client device reboot show'
export const SYSTEM_DEVICE_REBOOT_SHOW = 'system device reboot show'
export const ENTERPRISE_DEVICE_REBOOT_SHOW = 'enterprise device reboot show'

export const CLIENT_DEVICE_REBOOT_UPDATE = 'client device reboot update'
export const SYSTEM_DEVICE_REBOOT_UPDATE = 'system device reboot update'
export const ENTERPRISE_DEVICE_REBOOT_UPDATE = 'enterprise device reboot update'

export const SYSTEM_DEVICE_TYPE_ASSIGN_APP_VERSION =
  'system device type assign app version'

export const CLIENT_DEVICE_APPLY_APP_VERSION = 'client device apply app version'
export const SYSTEM_DEVICE_ASSIGN_APP_VERSION =
  'system device assign app version'
export const ENTERPRISE_DEVICE_ASSIGN_APP_VERSION =
  'enterprise device apply app version'

export const ORG_DEVICE_THUMBNAIL_TRIGGER = 'org device thumbnail trigger'

export const ORG_DEVICE_LOCATION_IMAGE_SHOW = 'org device location image show'
export const SYSTEM_DEVICE_LOCATION_IMAGE_SHOW =
  'system device location image show'
export const ENTERPRISE_DEVICE_LOCATION_IMAGE_SHOW =
  'enterprise device location image show'

export const SYSTEM_DEVICE_REFRESH_LICENSE_STORE =
  'system device refresh license store'
export const SYSTEM_DEVICE_REFRESH_LICENSE_UPDATE =
  'system device refresh license update'
export const SYSTEM_DEVICE_REFRESH_LICENSE_DESTROY =
  'system device refresh license destroy'

export const SYSTEM_DEVICE_LICENSE_SHOW = 'system device license show'
export const SYSTEM_DEVICE_LICENSE_UPDATE = 'system device license update'
export const SYSTEM_DEVICE_LICENSE_DELETE = 'system device license delete'

export const SYSTEM_DEVICE_TYPE_STORE = 'system device type store'
export const SYSTEM_DEVICE_TYPE_UPDATE = 'system device type update'

export const DEVICE_ALARM_NOTIFICATION_SHOW = 'device alarm notification show'
export const DEVICE_ALARM_NOTIFICATION_STORE = 'device alarm notification store'
export const DEVICE_ALARM_NOTIFICATION_UPDATE =
  'device alarm notification update'
export const DEVICE_ALARM_NOTIFICATION_DELETE =
  'device alarm notification delete'

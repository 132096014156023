import React, { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import { _get } from 'utils/lodash'

import Card from 'components/Card/Card'
import MediaInfoRow from './MediaInfoRow'
import { truncateLength } from 'components/Card/consts'
import {
  ActiveStatus,
  DisabledStatus,
  InactiveStatus,
  PendingStatus
} from 'components/StatusText'
import { DateTimeView } from 'components/TableLibrary'
import { mediaStatus } from 'constants/media'
import { isApproved } from 'utils/libraryUtils'
import { getDurationString, getSize, getResolution } from 'utils/mediaUtils'
import { useGetMediaLibraryItemQuery } from 'api/mediaApi'

const styles = ({ palette, type, typography }) => ({
  cardRoot: {
    padding: 0,
    borderRadius: '7px',
    width: '100%'
  },
  moreInfoCardHeader: {
    padding: '0 20px',
    marginBottom: 0,
    borderBottom: `solid 1px ${palette[type].deviceCard.border}`,
    backgroundColor: palette[type].deviceCard.header.background,
    borderRadius: '8px 8px 0 0'
  },
  moreInfoCardHeaderWrap: {
    width: '100%'
  },
  moreInfoCardHeaderText: {
    ...typography.darkAccent[type],
    lineHeight: '45px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px'
  }
})

const MediaMoreInfoCard = ({ t, media, classes }) => {
  const {
    data: mediaItem = {},
    isFetching,
    isUninitialized
  } = useGetMediaLibraryItemQuery(media.id)

  const data = useMemo(() => {
    const resolution = getResolution(mediaItem)

    return [
      {
        label: t('Type'),
        value: _get(mediaItem, 'feature.alias', '')
      },
      {
        label: t('Duration'),
        value: getDurationString(mediaItem)
      },
      {
        label: t('Size (MB)'),
        value: getSize(mediaItem) || 'N/A'
      },
      {
        label: t('Status'),
        value: isApproved(mediaItem) ? (
          mediaItem.status === mediaStatus.disabled ? (
            <DisabledStatus title={t('Disabled')} />
          ) : mediaItem.status === mediaStatus.active ? (
            <ActiveStatus title={t('Active')} />
          ) : (
            <InactiveStatus title={t('Inactive')} />
          )
        ) : (
          <PendingStatus title={t('Pending')} />
        )
      },
      {
        label: t('Last Edited'),
        value: <DateTimeView date={mediaItem.updatedAt} />
      },
      {
        label: t('Resolution'),
        value: resolution ? resolution : t('Responsive')
      },
      {
        label: t('Added By'),
        value: `${mediaItem?.createdBy?.firstName || ''} ${
          mediaItem?.createdBy?.lastName || ''
        }`
      },
      {
        label: t('Date Added'),
        value: <DateTimeView date={mediaItem.createdAt} />
      }
    ]
  }, [mediaItem, t])

  return (
    <Card
      onClick={e => e.stopPropagation()}
      icon={false}
      titleComponent={
        <div className={classes.moreInfoCardHeaderText}>{media.title}</div>
      }
      rootClassName={classes.cardRoot}
      headerClasses={[classes.moreInfoCardHeader]}
      titleComponentWrapClassName={classes.moreInfoCardHeaderWrap}
    >
      <div className={classes.container}>
        {data.map(({ label, value }, index) => (
          <MediaInfoRow
            title={label}
            isTruncate={value.length > truncateLength}
            key={`media-info-card-${index}`}
            isLoading={isFetching || isUninitialized}
          >
            {value}
          </MediaInfoRow>
        ))}
      </div>
    </Card>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(MediaMoreInfoCard)
)

import { call, put } from 'redux-saga/effects'

import * as types from '../actions'

import { configService, approveService } from '../services'
import { _isEmpty } from 'utils/lodash'
import { transformMeta } from 'utils/tableUtils'
import { featureNames, oauth2Features } from 'constants/featureConstants'
import { showMediaAssociation } from '../slices/oauth2Slice'

function* getFacebookPages({ params }) {
  try {
    const response = yield call(configService.getFacebookPages, params)

    yield put({ type: types.GET_FACEBOOK_PAGES_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_FACEBOOK_PAGES_ERROR, payload: error })
  }
}

function* getConfigOrgRole() {
  try {
    const response = yield call(configService.getConfigOrgRole)

    //Extend response
    const transformResponse = response.map(item => ({
      ...item,
      label: item.displayName,
      value: item.id
    }))

    yield put({
      type: types.GET_CONFIG_ORG_ROLE_SUCCESS,
      payload: transformResponse
    })
  } catch (error) {
    yield put({ type: types.GET_CONFIG_ORG_ROLE_ERROR, payload: error })
  }
}

function* getConfigEnterpriseRole() {
  try {
    const response = yield call(configService.getConfigEnterpriseRole)
    yield put({
      type: types.GET_CONFIG_ENTERPRISE_ROLE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_ENTERPRISE_ROLE_ERROR,
      payload: error
    })
  }
}

function* getConfigResellerRole() {
  try {
    const response = yield call(configService.getConfigResellerRole)
    yield put({
      type: types.GET_CONFIG_RESELLER_ROLE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_RESELLER_ROLE_ERROR,
      payload: error
    })
  }
}

function* getConfigSystemRole() {
  try {
    const response = yield call(configService.getConfigSystemRole)
    yield put({
      type: types.GET_CONFIG_SYSTEM_ROLE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_SYSTEM_ROLE_ERROR,
      payload: error
    })
  }
}

function* getConfigClientType({ impersonated }) {
  try {
    const response = yield call(configService.getConfigClientType, impersonated)
    yield put({
      type: types.GET_CONFIG_CLIENT_TYPE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_CLIENT_TYPE_ERROR,
      payload: error
    })
  }
}

function* getConfigDeviceType() {
  try {
    const response = yield call(configService.getConfigDeviceType)
    yield put({
      type: types.GET_CONFIG_DEVICE_TYPE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_DEVICE_TYPE_ERROR,
      payload: error
    })
  }
}

function* getConfigFeatureClient() {
  try {
    const response = yield call(configService.getConfigFeatureClient)
    yield put({
      type: types.GET_CONFIG_FEATURE_CLIENT_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_FEATURE_CLIENT_ERROR,
      payload: error
    })
  }
}

function* getConfigFeatureDevice() {
  try {
    const response = yield call(configService.getConfigFeatureDevice)
    yield put({
      type: types.GET_CONFIG_FEATURE_DEVICE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_FEATURE_DEVICE_ERROR,
      payload: error
    })
  }
}

function* getConfigFeatureMedia({ params }) {
  try {
    const response = yield call(configService.getConfigFeatureMedia, params)
    yield put({
      type: types.GET_CONFIG_FEATURE_MEDIA_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_FEATURE_MEDIA_ERROR,
      payload: error
    })
  }
}

function* getThemeOfMediaFeatureById({ id }) {
  try {
    const response = yield call(configService.getThemeOfMediaFeatureById, id)
    yield put({
      type: types.GET_THEME_OF_MEDIA_FEATURE_BY_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_THEME_OF_MEDIA_FEATURE_BY_ID_ERROR,
      payload: error
    })
  }
}

function* getContentSourceOfMediaFeatureById({ id }) {
  try {
    const response = yield call(
      configService.getContentSourceOfMediaFeatureById,
      id
    )
    yield put({
      type: types.GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID_ERROR,
      payload: error
    })
  }
}

function* getConfigMediaCategory() {
  try {
    const response = yield call(configService.getConfigMediaCategory)
    yield put({
      type: types.GET_CONFIG_MEDIA_CATEGORY_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_MEDIA_CATEGORY_ERROR,
      payload: error
    })
  }
}

function* getLocation(action) {
  try {
    const response = yield call(
      configService.getLocation,
      action.data,
      action.silent
    )
    yield put({
      type: types.GET_LOCATION_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_LOCATION_ERROR,
      payload: error
    })
  }
}

function* getCity(action) {
  try {
    const response = yield call(
      configService.getCity,
      action.data,
      action.silent
    )
    yield put({
      type: types.GET_CITY_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CITY_ERROR,
      payload: error
    })
  }
}

function* getTransitions() {
  try {
    const response = yield call(configService.getTransitions)
    yield put({
      type: types.GET_CONFIG_TRANSITIONS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_TRANSITIONS_ERROR,
      payload: error
    })
  }
}

function* getAlertTypes() {
  try {
    const response = yield call(configService.getAlertTypes)
    yield put({ type: types.GET_CONFIG_ALERT_TYPES_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_CONFIG_ALERT_TYPES_ERROR, payload: error })
  }
}

function* getAirlines({ params }) {
  try {
    let response = yield call(configService.getAirlines, params)
    if (_isEmpty(response)) {
      response = []
    }
    yield put({ type: types.GET_AIRLINES_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_AIRLINES_ERROR, payload: error })
  }
}

function* getAirports({ params }) {
  try {
    const response = yield call(configService.getAirports, params)
    yield put({ type: types.GET_AIRPORTS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_AIRPORTS_ERROR, payload: error })
  }
}

function* getLocationInfo({ location }) {
  try {
    const { data } = yield call(configService.getLocationInfo, location)
    yield put({ type: types.GET_LOCATION_INFO_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: types.GET_LOCATION_INFO_ERROR, payload: error })
  }
}

function* getBackgroundPattern({ params }) {
  try {
    const { data, meta } = yield call(configService.getBackgroundPattern, {
      limit: 6,
      ...params
    })

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_BACKGROUND_PATTERNS_SUCCESS,
      payload: { data, meta: modifiedMeta }
    })
  } catch (error) {
    yield put({ type: types.GET_BACKGROUND_PATTERNS_ERROR, payload: error })
  }
}

function* getBackgroundPatternById(id) {
  try {
    const { data } = yield call(configService.getBackgroundPatternById, id)
    yield put({
      type: types.GET_BACKGROUND_PATTERN_BY_ID_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_BACKGROUND_PATTERN_BY_ID_ERROR,
      payload: error
    })
  }
}

function* getCurrency() {
  try {
    const response = yield call(configService.getCurrency)
    yield put({
      type: types.GET_CONFIG_CURRENCY_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: types.GET_CONFIG_CURRENCY_ERROR,
      payload: error
    })
  }
}

function* getYoutubeCaptions({ payload: id }) {
  try {
    const response = yield call(configService.getYoutubeCaptions, id)
    yield put({
      type: types.GET_YOUTUBE_CAPTIONS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_YOUTUBE_CAPTIONS_ERROR
    })
  }
}

function* getOauth2Tokens({ params }) {
  try {
    const { data, meta } = yield call(configService.getOauth2Tokens, params)

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_OAUTH2_TOKENS_SUCCESS,
      payload: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({
      type: types.GET_OAUTH2_TOKENS_ERROR,
      payload: error
    })
  }
}

function* postFacebookAccount({ data }) {
  try {
    yield call(configService.postFacebookAccount, data)

    yield put({
      type: types.POST_FACEBOOK_ACCOUNT_SUCCESS,
      payload: { status: 'success', featureName: featureNames.Facebook }
    })
  } catch (error) {
    yield put({
      type: types.POST_FACEBOOK_ACCOUNT_ERROR,
      payload: { error, featureName: featureNames.Facebook }
    })
  }
}

function* postInstagramAccount({ data }) {
  try {
    yield call(configService.postInstagramAccount, data)

    yield put({
      type: types.POST_INSTAGRAM_ACCOUNT_SUCCESS,
      payload: { status: 'success', featureName: featureNames.Instagram }
    })
  } catch (error) {
    yield put({
      type: types.POST_INSTAGRAM_ACCOUNT_ERROR,
      payload: { error, featureName: featureNames.Instagram }
    })
  }
}

function* postGoogleDocsAccount({ data }) {
  try {
    yield call(configService.postGoogleDocsAccount, data)

    yield put({
      type: types.POST_GOOGLE_DOCS_ACCOUNT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.POST_GOOGLE_DOCS_ACCOUNT_ERROR,
      payload: error
    })
  }
}

function* putOauth2Token({ id, data, featureName }) {
  try {
    yield call(configService.putOauth2Token, id, data)

    yield put({
      type: types.PUT_OAUTH2_TOKEN_SUCCESS,
      payload: { status: 'success', featureName }
    })
  } catch (error) {
    yield put({
      type: types.PUT_OAUTH2_TOKEN_ERROR,
      payload: { error, featureName }
    })
  }
}

function* deleteOauth2Token({ id, data, featureName }) {
  try {
    yield call(configService.deleteOauth2Token, id, data)

    yield put({
      type: types.DELETE_OAUTH2_TOKEN_SUCCESS,
      payload: { status: 'success', featureName }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_OAUTH2_TOKEN_ERROR,
      payload: { error, featureName }
    })
  }
}

function* getPowerBiToken({ code }) {
  try {
    yield call(configService.getPowerBiToken, code)
    yield put({
      type: types.GET_POWERBI_TOKEN_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.GET_POWERBI_TOKEN_ERROR,
      payload: error
    })
  }
}

function* powerBiLogout({ tokenId, onLogoutSuccess, onLogoutError, data }) {
  try {
    yield call(configService.oauthLogout, tokenId, data)
    yield put({
      type: types.CLEAR_POWERBI_DASHBOARDS
    })
    yield put({
      type: types.CLEAR_POWERBI_TOKEN_INFO
    })
    onLogoutSuccess()
  } catch (error) {
    onLogoutError(error)
  }
}

function* getMsTeamsChatToken({ code }) {
  try {
    const response = yield call(configService.getMsTeamsChatToken, code)
    yield put({
      type: types.GET_MS_TEAMS_CHAT_TOKEN_SUCCESS,
      payload: { status: 'success', data: response }
    })
    if (response.tokenId && response.mediaToAssociate?.length) {
      yield put(
        showMediaAssociation({
          ...response,
          feature: oauth2Features.MsTeams
        })
      )
    }
  } catch (error) {
    yield put({
      type: types.GET_MS_TEAMS_CHAT_TOKEN_ERROR,
      payload: error
    })
  }
}

function* getMsTeamsChatChannels() {
  try {
    const response = yield call(configService.getMsTeamsChatChannels)
    yield put({
      type: types.GET_MS_TEAMS_CHAT_CHANNELS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_MS_TEAMS_CHAT_CHANNELS_ERROR,
      payload: error
    })
  }
}

function* msTeamsChatLogout({ tokenId, onLogoutSuccess, onLogoutError, data }) {
  try {
    yield call(configService.oauthLogout, tokenId, data)
    yield put({
      type: types.CLEAR_MS_TEAMS_CHAT_CHANNELS
    })
    yield put({
      type: types.CLEAR_MS_TEAMS_CHAT_TOKEN_INFO
    })
    onLogoutSuccess()
  } catch (error) {
    onLogoutError(error)
  }
}

function* getPowerBiDashboards() {
  try {
    const response = yield call(configService.getPowerBiDashboards)
    yield put({
      type: types.GET_POWERBI_DASHBOARDS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_POWERBI_DASHBOARDS_ERROR,
      payload: error
    })
  }
}

function* getAppConfig() {
  try {
    const response = yield call(configService.getAppConfig)
    yield put({
      type: types.GET_APP_CONFIG_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_APP_CONFIG_ERROR,
      payload: error
    })
  }
}

function* getFipsLocation({ params }) {
  try {
    const { data } = yield call(configService.getFipsLocation, params)

    yield put({
      type: types.GET_FIPS_LOCATION_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({ type: types.GET_FIPS_LOCATION_ERROR, payload: error })
  }
}

function* getIpawsFeeds() {
  try {
    const { data } = yield call(configService.getIpawsFeeds)

    yield put({
      type: types.GET_IPAWS_FEEDS_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({ type: types.GET_IPAWS_FEEDS_ERROR, payload: error })
  }
}

function* getKlipfolioToken({ data }) {
  try {
    yield call(configService.getKlipfolioToken, data)
    yield put({
      type: types.GET_KLIPFOLIO_TOKEN_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.GET_KLIPFOLIO_TOKEN_ERROR,
      payload: error
    })
  }
}

function* klipfolioLogout({ tokenId, onLogoutSuccess, onLogoutError, data }) {
  try {
    yield call(configService.klipfolioLogout, tokenId, data)
    yield put({
      type: types.CLEAR_KLIPFOLIO_RESPONSE_INFO
    })
    yield put({
      type: types.CLEAR_KLIPFOLIO_TOKEN_INFO
    })
    onLogoutSuccess()
  } catch (error) {
    onLogoutError(error)
  }
}

function* getKlipfolioDashboards() {
  try {
    const response = yield call(configService.getKlipfolioDashboards)
    yield put({
      type: types.GET_KLIPFOLIO_DASHBOARDS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_KLIPFOLIO_DASHBOARDS_ERROR,
      payload: error
    })
  }
}

function* approveContent({ payload }) {
  try {
    const response = yield call(configService.approveContent, payload)
    yield put({
      type: types.CONTENT_APPROVE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.CONTENT_APPROVE_ERROR,
      payload: error
    })
  }
}

function* rejectContent({ payload: { content, id } }) {
  try {
    yield call(approveService.rejectContent, content, id)
    yield put({
      type: types.CONTENT_REJECT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.CONTENT_REJECT_ERROR,
      payload: error
    })
  }
}

function* rejectAndDeleteContent({ payload: { content, id } }) {
  try {
    yield call(approveService.rejectAndDeleteContent, content, id)

    yield put({
      type: types.CONTENT_REJECT_AND_DELETE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.CONTENT_REJECT_AND_DELETE_ERROR,
      payload: error
    })
  }
}

function* getAllConfigFeatureMedia({ params }) {
  try {
    const data = yield call(configService.getConfigFeatureMedia, {
      includeAll: 1,
      ...params
    })
    yield put({
      type: types.GET_ALL_CONFIG_FEATURE_MEDIA_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_ALL_CONFIG_FEATURE_MEDIA_ERROR,
      payload: error
    })
  }
}

function* getAirportGate({ params }) {
  try {
    let response = yield call(configService.getAirportGate, params)
    if (!response?.gates) {
      response = { gates: [] }
    }

    yield put({ type: types.GET_AIRPORT_GATE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_AIRPORT_GATE_ERROR, payload: error })
  }
}

function* getAirportBaggage({ params }) {
  try {
    let response = yield call(configService.getAirportBaggage, params)
    if (!response?.baggage) {
      response = { baggage: [] }
    }

    yield put({ type: types.GET_AIRPORT_BAGGAGE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_AIRPORT_BAGGAGE_ERROR, payload: error })
  }
}

function* getShorelineEvents({ payload: { params } }) {
  try {
    const data = yield call(configService.getShorelineEvents, params)
    yield put({
      type: types.GET_SHORELINE_EVENTS_SUCCESS,
      payload: data.data
    })
  } catch (error) {
    yield put({
      type: types.GET_SHORELINE_EVENTS_ERROR,
      payload: error
    })
  }
}

function* getShorelinePrice({ payload: { params } }) {
  try {
    const response = yield call(configService.getShorelinePrice, params)
    yield put({
      type: types.GET_SHORELINE_PRICE_SUCCESS,
      payload: {
        response: response.data,
        meta: transformMeta(response.meta)
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_SHORELINE_PRICE_ERROR,
      payload: error
    })
  }
}

function* uploadShorelinePrice({ payload }) {
  try {
    const data = yield call(configService.uploadShorelinePrice, payload)
    yield put({
      type: types.POST_SHORELINE_PRICE_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.POST_SHORELINE_PRICE_ERROR,
      payload: error
    })
  }
}

function* getGeoLocationFromIp({ payload }) {
  try {
    const data = yield call(configService.getGeoLocationFromIp, payload)
    yield put({
      type: types.GET_GEO_LOCATION_FROM_IP_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_GEO_LOCATION_FROM_IP_ERROR,
      payload: error
    })
  }
}

function* getGlobalSearchItems({ params }) {
  try {
    const data = yield call(configService.getGlobalSearchItems, params)
    yield put({
      type: types.GET_GLOBAL_SEARCH_ITEMS_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_GLOBAL_SEARCH_ITEMS_ERROR,
      payload: error
    })
  }
}

function* postGoogleCalendarAccount({ data }) {
  try {
    yield call(configService.postGoogleCalendarAccount, data)

    yield put({
      type: types.POST_GOOGLE_CALENDAR_ACCOUNT_SUCCESS,
      payload: {
        status: 'success',
        featureName: featureNames.CalendarAggregator
      }
    })
  } catch (error) {
    yield put({
      type: types.POST_GOOGLE_CALENDAR_ACCOUNT_ERROR,
      payload: error
    })
  }
}

function* postMicrosoftOutlookAccount({ data }) {
  try {
    yield call(configService.postMicrosoftOutlookAccount, data)

    yield put({
      type: types.POST_MICROSOFT_OUTLOOK_ACCOUNT_SUCCESS,
      payload: {
        status: 'success',
        featureName: featureNames.CalendarAggregator
      }
    })
  } catch (error) {
    yield put({
      type: types.POST_MICROSOFT_OUTLOOK_ACCOUNT_ERROR,
      payload: error
    })
  }
}

function* getContentModerationLabels() {
  try {
    const data = yield call(configService.getContentModerationLabels)

    yield put({
      type: types.GET_CONTENT_MODERATION_LABELS_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_CONTENT_MODERATION_LABELS_ERROR,
      payload: error
    })
  }
}

function* getAIVideoAvatarsList() {
  try {
    const data = yield call(configService.getAIVideoAvatarsList)

    yield put({
      type: types.GET_AI_VIDEO_AVATARS_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_AI_VIDEO_AVATARS_LIST_ERROR,
      payload: error
    })
  }
}

function* getAIVideoVoicesList() {
  try {
    const data = yield call(configService.getAIVideoVoicesList)

    yield put({
      type: types.GET_AI_VIDEO_VOICES_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_AI_VIDEO_VOICES_LIST_ERROR,
      payload: error
    })
  }
}

function* getAIVideoPresetBackgroundsList() {
  try {
    const data = yield call(configService.getAIVideoPresetBackgroundsList)

    yield put({
      type: types.GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST_ERROR,
      payload: error
    })
  }
}

const configSaga = {
  getConfigOrgRole,
  getConfigEnterpriseRole,
  getConfigResellerRole,
  getConfigSystemRole,
  getConfigClientType,
  getConfigDeviceType,
  getConfigFeatureClient,
  getConfigFeatureDevice,
  getConfigFeatureMedia,
  getConfigMediaCategory,
  getThemeOfMediaFeatureById,
  getContentSourceOfMediaFeatureById,
  getCity,
  getLocation,
  getTransitions,
  getAirlines,
  getAirports,
  getAlertTypes,
  getLocationInfo,
  getBackgroundPattern,
  getBackgroundPatternById,
  getCurrency,
  getYoutubeCaptions,
  getPowerBiToken,
  powerBiLogout,
  getOauth2Tokens,
  getPowerBiDashboards,
  getFacebookPages,
  getAppConfig,
  approveContent,
  rejectContent,
  rejectAndDeleteContent,
  getFipsLocation,
  getIpawsFeeds,
  getKlipfolioToken,
  getKlipfolioDashboards,
  klipfolioLogout,
  getAllConfigFeatureMedia,
  postFacebookAccount,
  postInstagramAccount,
  postGoogleDocsAccount,
  putOauth2Token,
  deleteOauth2Token,
  getAirportGate,
  getAirportBaggage,
  getShorelineEvents,
  getShorelinePrice,
  uploadShorelinePrice,
  getGeoLocationFromIp,
  getGlobalSearchItems,
  postGoogleCalendarAccount,
  postMicrosoftOutlookAccount,
  getContentModerationLabels,
  getMsTeamsChatToken,
  getMsTeamsChatChannels,
  msTeamsChatLogout,
  getAIVideoAvatarsList,
  getAIVideoVoicesList,
  getAIVideoPresetBackgroundsList
}

export default configSaga

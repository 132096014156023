import React from 'react'

import { ReactComponent as WarningIcon } from 'common/assets/icons/ic-round-warning.svg'
import Text from 'components/Typography/Text'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ colors }) => ({
  icon: {
    color: colors.highlight
  }
}))

const NormalPlaceholderContent = ({ text, requestText }) => {
  const classes = useStyles()

  return (
    <>
      <WarningIcon className={classes.icon} />
      <Text variant="big" weight="bold" component="h2" color="highlight">
        {text}
      </Text>
      <Text color="highlight">{requestText}</Text>
    </>
  )
}

export default NormalPlaceholderContent

import { useEffect, useState } from 'react'
import { useSnackbar as _useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { UAParser } from 'ua-parser-js'

import { useCustomSnackbar } from './index'

export default function useBrowserWarning(hideWarning) {
  const { enqueueSnackbar, closeSnackbar } = _useSnackbar()
  const { t } = useTranslation('translations')

  const [isAllowedBrowser, setAllowedBrowser] = useState(false)

  const showSnackbar = useCustomSnackbar(
    t,
    enqueueSnackbar,
    closeSnackbar,
    30000
  )

  useEffect(
    () => {
      const parser = new UAParser()

      const isMobile = ['mobile'].includes(parser.getDevice().type)
      const isRecommendedBrowser = [
        'Chrome',
        'Chromium',
        'Mozilla',
        'Firefox'
      ].includes(parser.getBrowser().name)

      if ((isMobile || !isRecommendedBrowser) && !hideWarning) {
        showSnackbar(
          'We have detected that you are accessing the CMS using a browser which is not recommended. For the best experience, we recommend using a desktop-based version of Google Chrome or Mozilla Firefox.',
          'warning',
          'OK'
        )
      } else {
        setAllowedBrowser(true)
      }
    },
    // eslint-disable-next-line
    []
  )

  return isAllowedBrowser
}

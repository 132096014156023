import React, { forwardRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Spacing from 'components/Containers/Spacing'

function styles({ fontSize, colors, palette }) {
  return {
    root: {
      fontSize: fontSize.big
    },
    hover: {
      cursor: 'pointer'
    },
    disabled: {
      opacity: '.6'
    },
    'color-blue': {
      color: colors.highlight
    },
    'color-light': {
      color: colors.light
    },
    'color-success': {
      color: colors.green
    },
    'color-danger': {
      color: palette.buttons.secondary.theme2.color
    },
    'color-needsAttentionStatus': {
      color: colors.needsAttentionStatus
    }
  }
}

const containerProps = {
  container: true,
  justify: 'center',
  alignItems: 'center'
}

const itemProps = {
  item: true
}

const Icon = forwardRef(
  (
    {
      classes,
      icon,
      iconComponent,
      rootClassName,
      tooltip = '',
      color,
      disabled,
      rootProps,
      container = true,
      paddingHor,
      paddingVert,
      onClick,
      ...props
    },
    ref
  ) => {
    return (
      <Tooltip arrow title={tooltip} disableHoverListener={!tooltip}>
        <Grid
          ref={ref}
          onClick={onClick}
          className={classNames(classes.root, classes[`color-${color}`], {
            [classes.hover]: !!onClick,
            [classes.disabled]: disabled
          })}
          {...(container && containerProps)}
          {...(!container && itemProps)}
          {...rootProps}
          {...props}
        >
          <Spacing
            variant={0}
            paddingVert={paddingVert}
            paddingHor={paddingHor}
            {...(container && containerProps)}
            {...(!container && itemProps)}
          >
            {iconComponent ? (
              iconComponent
            ) : (
              <i
                className={classNames(icon, rootClassName)}
                aria-hidden="true"
              />
            )}
          </Spacing>
        </Grid>
      </Tooltip>
    )
  }
)

// TODO Refactor (with "utils/styles")
Icon.propTypes = {
  icon: PropTypes.string,
  rootClassName: PropTypes.string,
  tooltip: PropTypes.string,
  color: PropTypes.oneOf([
    'blue',
    'light',
    'success',
    'danger',
    'needsAttentionStatus'
  ]),
  disabled: PropTypes.bool,
  rootProps: PropTypes.object,
  container: PropTypes.bool,
  paddingVert: PropTypes.oneOf([1, 1.5, 2, 3]),
  paddingHor: PropTypes.oneOf([1, 1.5, 2, 3]),
  onClick: PropTypes.func
}

export default withStyles(styles)(Icon)

import Icon4K from 'components/Icons/Icon4K'
import TouchScreenIcon from 'components/Icons/TouchScreenIcon'

export const templateTypes = {
  Standard: {
    color: 'rgb(74, 130, 238)',
    iconHelperClass: 'fa-regular fa-desktop',
    title: 'Standard Template',
    name: 'Standard'
  },
  Videowall: {
    color: 'rgb(80, 227, 194)',
    iconHelperClass: 'icon-grid',
    title: 'Videowall Template',
    name: 'Videowall'
  },
  Interactive: {
    color: 'rgb(255, 61, 132)',
    iconComponent: TouchScreenIcon,
    title: 'Interactive Template',
    name: 'Interactive'
  }
}

export const templateGalleryTabs = [
  {
    name: 'Full HD Landscape',
    icon: 'fa-regular fa-high-definition',
    type: 'landscape',
    resolution: 'HD',
    width: 1920,
    height: 1080
  },
  {
    name: 'Full HD Portrait',
    icon: 'fa-regular fa-high-definition',
    type: 'portrait',
    resolution: 'HD',
    width: 1080,
    height: 1920
  },
  {
    name: '4K Landscape',
    iconComponent: <Icon4K />,
    type: 'landscape',
    resolution: '4K',
    width: 3840,
    height: 2160
  },
  {
    name: '4K Portrait',
    iconComponent: <Icon4K />,
    type: 'portrait',
    resolution: '4K',
    width: 2160,
    height: 3840
  }
]

export const templateStatus = {
  expired: 'expired',
  expires: 'expires',
  activates: 'activates',
  notApproved: 'notApproved'
}

export const unprocessableTemplateStatuses = [
  templateStatus.expired,
  templateStatus.notApproved
]

export const expiresTemplateStatuses = [
  templateStatus.expires,
  templateStatus.expired
]

export const searchStatusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' }
]

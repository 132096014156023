import React, { memo, useCallback } from 'react'
import { withStyles } from '@material-ui/core'
import { _get } from 'utils/lodash'
import classNames from 'classnames'

import Spacing from 'components/Containers/Spacing'
import Popup from 'components/Popup'
import DeviceCard from 'components/Card/DeviceCard'
import CheckboxSwitcher from 'components/Checkboxes/Switcher'
import { TextWithTooltip } from 'components/Typography'
import { getListContentWrapStyle } from './styles'
import DeviceCardScheduleContent from './DeviceCardScheduleContent'

const styles = theme => {
  const { spacing } = theme

  return {
    popupContentWrap: {
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing(1)
    },
    listContentWrap: {
      ...getListContentWrapStyle(theme)
    },
    deviceWrap: {
      lineHeight: '40px',
      fontSize: 16,
      color: '#74809A'
    },
    deviceIcon: {
      color: '#0a83c8'
    },
    contentInfo: {
      display: 'flex',
      alignItems: 'center'
    }
  }
}

const ModalDeviceRow = ({
  item,
  name,
  checkboxValue,
  disabled,
  disabledReason,
  maxTitleWidth = 100,
  onChange,
  isScheduled,
  hideSchedulePopup = false,
  containerProps,
  classes,
  modalView
}) => {
  const handleChange = useCallback(() => {
    onChange(item)
  }, [onChange, item])

  const handleDisplayPopup = useCallback(() => {
    setTimeout(() => {
      const popupContent = document.querySelector('.popup-content')
      const left = _get(popupContent, 'style.left', '0px')
      const leftValue = Number(left.replace('px', ''))
      const top = _get(popupContent, 'style.top', '0px')
      const topValue = Number(top.replace('px', ''))

      if (leftValue < 0 || (leftValue > 40 && !modalView)) {
        popupContent.style.left = '25px'
      }
      if (topValue < 0) {
        popupContent.style.top = '10px'
      }
    }, 5)
  }, [modalView])

  return (
    <div {...containerProps} className={classes.listContentWrap}>
      <Spacing
        direction="row"
        alignItems="center"
        justify="space-between"
        variant={0}
        wrap="nowrap"
      >
        <div className={classes.contentInfo}>
          {hideSchedulePopup ? (
            <div className={classes.popupContentWrap}>
              <i
                className={classNames(
                  'fa-sharp fa-regular fa-desktop',
                  classes.deviceWrap,
                  {
                    [classes.deviceIcon]: isScheduled
                  }
                )}
              />
            </div>
          ) : (
            <Popup
              trigger={
                <div className={classes.popupContentWrap}>
                  <i
                    className={classNames(
                      'fa-sharp fa-regular fa-desktop',
                      classes.deviceWrap,
                      {
                        [classes.deviceIcon]: isScheduled
                      }
                    )}
                  />
                </div>
              }
              position={[
                'bottom left',
                'bottom right',
                'top left',
                'top right'
              ]}
              keepTooltipInside=".popupBoundary"
              contentStyle={{
                padding: '0',
                borderRadius: '6px',
                width: '320px'
              }}
              onOpen={handleDisplayPopup}
            >
              <DeviceCard
                device={item}
                withFooter={false}
                infoIconsPosition="top"
                variant="auto"
                disableHoverEffect
                bottomContentComponent={
                  <Spacing variant={0} paddingHor={2} paddingTop={2}>
                    <DeviceCardScheduleContent device={item} />
                  </Spacing>
                }
              />
            </Popup>
          )}
          <TextWithTooltip color="title.primary" maxWidth={maxTitleWidth}>
            {name}
          </TextWithTooltip>
        </div>
        <CheckboxSwitcher
          {...(disabledReason && { tooltip: disabledReason })}
          switchStrictWidth
          disabled={disabled}
          value={checkboxValue}
          handleChange={handleChange}
        />
      </Spacing>
    </div>
  )
}

export default withStyles(styles)(memo(ModalDeviceRow))

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { userDetailsSelectorMemoized } from 'selectors/userSelectors'

export default function usePermissions() {
  const { response } = useSelector(userDetailsSelectorMemoized)

  const permissionNames = useMemo(() => {
    return response?.role?.permission.map(({ name }) => name) || []
  }, [response])

  const getPermissionByName = useCallback(
    (...names) => names.some(name => permissionNames.includes(name)),
    [permissionNames]
  )

  return useMemo(
    () => ({
      permissionNames,
      getPermissionByName
    }),
    [permissionNames, getPermissionByName]
  )
}

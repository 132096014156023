import React, { useCallback, useMemo } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import { compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { TabToggleButtonGroup, TabToggleStaticButton } from 'components/Buttons'
import FormControlPalette from 'components/Form/FormControlPalette'
import Spacing from 'components/Containers/Spacing'
import { paletteTypes } from 'utils/media/text'
import Container from '../Containers/Container'

const styles = () => ({
  paletteToggleRoot: {
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  paletteToggleRootSmall: {
    width: '100% !important'
  },
  palettePickerContainer: {
    gap: 8
  },
  palettePickerContainerSmall: {
    gridTemplateColumns: '1fr !important',
    placeItems: 'center'
  },
  customSection: {
    width: 48
  }
})

const FormControlPaletteSelector = ({
  onSelectPalette,
  value,
  option,
  preset,
  custom,
  selectedPaletteType,
  onColorChange,
  noTabs,
  halfWidth,
  onChangeTab,
  forceColors,
  customRootClass,
  customSection,
  variant = 'big',
  paletteContentRootClass = '',
  classes,
  t
}) => {
  const translate = useMemo(
    () => ({
      presets: t(paletteTypes.PRESET),
      custom: t(paletteTypes.CUSTOM)
    }),
    [t]
  )

  const getPalettesFromOptions = useCallback(
    paletteOptions => {
      return paletteOptions.map(item => (
        <Spacing
          item
          variant={0}
          container={false}
          direction={undefined}
          key={item.id}
        >
          <FormControlPalette
            id={item.id}
            palette={item.palette}
            onSelect={onSelectPalette}
            selected={item.id === value}
          />
        </Spacing>
      ))
    },
    [onSelectPalette, value]
  )

  const presetType = useMemo(() => preset || paletteTypes.PRESET, [preset])

  const customType = useMemo(() => custom || paletteTypes.CUSTOM, [custom])

  const renderPalettePicker = useMemo(() => {
    if (selectedPaletteType === presetType && !!option[0]?.type) {
      const presetOptions = option.filter(item => item.type === presetType)
      return getPalettesFromOptions(presetOptions)
    }
    if (selectedPaletteType === customType) {
      return (
        <>
          <div />
          <Grid container justify={variant === 'small' ? 'center' : 'flex-end'}>
            <FormControlPalette
              editable
              id={forceColors?.id}
              palette={forceColors?.palette}
              onChange={onColorChange}
              customSection={classNames(classes.customSection, customSection)}
            />
          </Grid>
        </>
      )
    }
    return getPalettesFromOptions(option)
  }, [
    option,
    forceColors,
    onColorChange,
    selectedPaletteType,
    getPalettesFromOptions,
    classes.customSection,
    customSection,
    presetType,
    customType,
    variant
  ])

  const palettePickerContainer = useMemo(() => {
    return (
      <Spacing variant={0}>
        <Container
          customClass={classNames(classes.palettePickerContainer, {
            [classes.palettePickerContainerSmall]: variant === 'small'
          })}
        >
          {renderPalettePicker}
        </Container>
      </Spacing>
    )
  }, [
    classes.palettePickerContainer,
    renderPalettePicker,
    classes.palettePickerContainerSmall,
    variant
  ])

  return (
    <Spacing variant={0} alignItems="center">
      <div
        className={classNames(classes.paletteToggleRoot, {
          paletteToggleRootSmall: variant === 'small',
          [customRootClass]: selectedPaletteType === customType
        })}
      >
        {!noTabs && (
          <Spacing alignItems="center">
            <TabToggleButtonGroup
              exclusive
              value={selectedPaletteType}
              onChange={onChangeTab}
            >
              <TabToggleStaticButton
                value={presetType}
                width={variant === 'small' ? 135 : 194}
              >
                {translate.presets}
              </TabToggleStaticButton>
              <TabToggleStaticButton
                value={customType}
                width={variant === 'small' ? 135 : 194}
              >
                {translate.custom}
              </TabToggleStaticButton>
            </TabToggleButtonGroup>
          </Spacing>
        )}
      </div>
      {!halfWidth ? (
        <Container cols="1-2-1" customClass={paletteContentRootClass}>
          <div />
          {palettePickerContainer}
          <div />
        </Container>
      ) : (
        palettePickerContainer
      )}
    </Spacing>
  )
}

export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(FormControlPaletteSelector)

import { useEffect, useState } from 'react'

export const useElementInView = ({ targetRef, targetRendered }) => {
  const [isInView, setIsInView] = useState(false)

  useEffect(() => {
    if (targetRendered) {
      const observer = new IntersectionObserver(
        entries => {
          const [entry] = entries
          setIsInView(entry.isIntersecting)
        },
        { threshold: 0.5 }
      )

      const current = targetRef?.current

      if (current) {
        observer.observe(current)
      }

      return () => {
        if (current) {
          observer.unobserve(current)
        }
      }
    }

    // eslint-disable-next-line
  }, [targetRef?.current, targetRendered])

  return [isInView]
}

import React, { useState, useEffect, useCallback } from 'react'
import { Avatar } from '@material-ui/core'
import { withStyles, Tooltip } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ReactComponent as PersonAvatars } from 'common/assets/icons/personAvatars.svg'
import useUserProfile from 'hooks/useUserProfile'
import AvatarWithPlaceholder from 'components/Loaders/AvatarWithPlaceholder'
import { WhiteButton } from 'components/Buttons'
import Spacing from 'components/Containers/Spacing'
import { Text } from 'components/Typography'

export const defaultAvatar = 'DEFAULT_AVATAR'

const AvatarTooltip = withStyles(() => ({
  tooltip: {
    width: 'fit-content',
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  popper: {
    opacity: 1
  }
}))(Tooltip)

const styles = ({ palette, type }) => ({
  marginBottom40: {
    marginBottom: '40px'
  },
  addProfileImageHeader: {
    marginBottom: '20px'
  },
  avatar: {
    width: 45,
    height: 45,

    cursor: 'pointer'
  },
  defaultAvatar: {
    borderRadius: '50%',
    width: 45,
    height: 45,

    '& div': {
      fontSize: '1rem'
    }
  },
  scaleAvatar: {
    borderRadius: '50%',
    boxShadow: palette[type].dropdown.shadow,
    '& > img': {
      margin: '-1px -2px',
      width: 160
    },
    '& > div': {
      margin: '-1px -2px',
      width: 160,
      height: 160,
      fontSize: '80px'
    }
  },
  selected: {
    boxShadow: '0 0 0 3px green',
    padding: '3px'
  },
  selectedDefaultAvatar: {
    width: 39,
    height: 39,
    fontSize: '0.9rem !important'
  },
  icon: {
    // color: '#0379bb',
    height: 'inherit',
    width: 'inherit'
  },
  avatarsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(17, 1fr)',
    gridColumnGap: '6px',
    gridRowGap: '20px',
    justifyItems: 'center'
  },
  fileInput: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1
  }
})

const TWO_ROWS_AVATARS_COUNT = 34 - 1 // - default avatar

const ProfileImage = ({
  className,
  avatarsContainerClassName,
  onChange,
  onImageUpload,
  name,
  userName,
  classes,
  selected,
  setSelected,
  setFieldValue,
  profileDetails,
  setImageUploadIsLoading,
  setProfileAvatarUrl,
  withHeader = true,
  disabled = false,
  t
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [showAllAvatars, setShowAllAvatars] = useState(false)

  const { avatars: allAvatars, hasProfileImagePermissions } = useUserProfile()

  const convertAndUploadAvatar = useCallback(
    async avatar => {
      setImageUploadIsLoading(true)
      setProfileAvatarUrl(avatar?.profile)
      setFieldValue('defaultAvatarId', avatar.id)
      onChange(null)
      setImageUploadIsLoading(false)
    },
    [setFieldValue, onChange, setImageUploadIsLoading, setProfileAvatarUrl]
  )

  useEffect(() => {
    if (profileDetails?.id) {
      setSelected(profileDetails?.id)
    } else if (!profileDetails?.isCustom) {
      setSelected(defaultAvatar)
    }
    //eslint-disable-next-line
  }, [profileDetails])

  useEffect(() => {
    const selectedAvatar = avatars?.find(avatar => avatar.id === selected)
    if (selectedAvatar) {
      convertAndUploadAvatar(selectedAvatar)
    }

    if (selected === defaultAvatar) {
      setFieldValue('defaultAvatarId', null)
      onChange(defaultAvatar)
    }
    //eslint-disable-next-line
  }, [selected])

  const avatars = showAllAvatars
    ? allAvatars
    : allAvatars?.slice(0, TWO_ROWS_AVATARS_COUNT)

  return (
    <section className={classNames(classes.marginBottom40, className)}>
      {withHeader && (
        <header className={classes.addProfileImageHeader}>
          <Text weight="bold">{t('Profile Image')}</Text>
        </header>
      )}
      <PersonAvatars style={{ display: 'none' }} />
      <div
        className={classNames(
          classes.avatarsContainer,
          avatarsContainerClassName
        )}
      >
        <button
          id={`profile-image-${name}`}
          type="button"
          onClick={onImageUpload}
          className={classes.fileInput}
        />
        <AvatarTooltip
          interactive
          placement="top"
          title={
            <div className={classes.scaleAvatar}>
              <Avatar className={classes.avatar}>
                {userName && userName[0].toUpperCase()}
              </Avatar>
            </div>
          }
        >
          <div
            className={classNames(classes.defaultAvatar, {
              [classes.selected]: selected === defaultAvatar
            })}
            onClick={() => setSelected(defaultAvatar)}
          >
            <Avatar
              className={classNames(classes.avatar, {
                [classes.selectedDefaultAvatar]: selected === defaultAvatar
              })}
              alt={`First name initial avatar preview`}
            >
              ABC
            </Avatar>
          </div>
        </AvatarTooltip>
        {hasProfileImagePermissions && (
          <>
            {avatars?.map((avatar, index) => (
              <AvatarTooltip
                interactive
                key={avatar.id}
                placement={index > 7 ? 'bottom' : 'top'}
                open={index === showTooltip}
                title={
                  <div className={classes.scaleAvatar}>
                    <img
                      src={avatar.profile}
                      alt={`Default avatar preview ${index}`}
                    />
                  </div>
                }
              >
                <AvatarWithPlaceholder
                  index={index}
                  avatar={avatar}
                  setShowTooltip={setShowTooltip}
                  selected={selected}
                  setSelected={setSelected}
                />
              </AvatarTooltip>
            ))}
          </>
        )}
      </div>
      <Spacing direction="row" justify="center" paddingTop={2}>
        {allAvatars?.length && avatars.length !== allAvatars.length && (
          <WhiteButton
            onClick={() => setShowAllAvatars(true)}
            iconClassName="fa-regular fa-chevrons-down"
            disabled={disabled}
          >
            {t('See More')}
          </WhiteButton>
        )}
      </Spacing>
    </section>
  )
}

export default withTranslation('translations')(withStyles(styles)(ProfileImage))

import update from 'immutability-helper'

import * as types from '../actions'
import { createTemplateConstants } from '../constants'
import {
  bulkUpdateTemplateItemError,
  bulkUpdateTemplateItemSuccess,
  calculateMultiplier
} from '../utils/createTemplate'
import uuidv4 from 'uuid/v4'

const initialState = {
  container: {
    size: { width: 1920, height: 1080 },
    orientation: 'landscape',
    showGrid: false,
    snapToGrid: false,
    videoWall: {
      active: false,
      props: { x: 0, y: 0 },
      zoomed: false
    },
    background: 'rgba(0, 0, 0, 1)',
    pattern: {},
    patternOpacity: 1,
    image_id: null,
    image_size: 'stretch',
    bg_type: 'image'
  },
  displaySize: {},
  items: {},
  touchZoneMediaItems: [],
  currentItemId: -1,
  maxId: -1,
  maxOrder: 0,
  renderMultiplier: 1.5,
  selectedItems: [],
  templateHistory: [],
  templateHistoryLength: 0,
  indexHistory: 0,
  templateChanged: true,
  mediaItem: {
    status: null,
    response: null,
    error: null,
    isFetching: false
  },
  selectedTemplateGallery: null
}

const createTemplateReducer = (state = initialState, action) => {
  let item,
    size,
    position,
    value,
    multiplier,
    orientation,
    newItem,
    newId,
    newOrder,
    newVideoWall,
    count,
    newIndex,
    newSnapshot,
    landscape,
    containerWidth,
    containerHeight,
    width,
    height,
    videoWallWidthSectors,
    videoWallHeightSectors

  if (
    [
      types.UPDATE_CURRENT_TEMPLATE_ITEM,
      types.SET_CURRENT_TEMPLATE_ITEM_SIZE,
      types.SET_CURRENT_TEMPLATE_ITEM_POSITION,
      types.SET_CURRENT_TEMPLATE_ITEM_ALIGNMENT,
      types.SET_CURRENT_TEMPLATE_ITEM_ROTATION,
      types.FLIP_CURRENT_TEMPLATE_ITEM
    ].includes(action.type) &&
    state.currentItemId === -1
  )
    return state

  let newTemplateHistory = state.templateHistory
  let newTemplateHistoryLength = state.templateHistory.length
  if (state.indexHistory < state.templateHistory.length) {
    newTemplateHistory = state.templateHistory.slice(0, state.indexHistory)
    newTemplateHistoryLength = newTemplateHistory.length
  }

  switch (action.type) {
    case types.RESET_TEMPLATE_CONTAINER:
      return update(state, {
        $set: initialState
      })
    case types.CLEAR_ALL_TEMPLATE_ITEMS:
      return {
        ...initialState,
        renderMultiplier: state.renderMultiplier,
        container: state.container,
        templateHistory: state.templateHistory,
        templateHistoryLength: state.templateHistoryLength,
        indexHistory: state.indexHistory,
        templateChanged: true
      }
    case types.UPDATE_TEMPLATE_CONTAINER:
      return update(state, {
        container: { $merge: action.payload },
        templateChanged: { $set: true }
      })
    case types.UPDATE_TEMPLATE_DISPLAY_SIZE:
      return update(state, {
        displaySize: { $set: action.payload }
      })
    case types.SET_TEMPLATE_CONTAINER_ORIENTATION:
      size = state.container.size
      orientation = action.payload.toUpperCase()

      multiplier = calculateMultiplier(
        size,
        state.container.videoWall,
        orientation
      )

      return update(state, {
        container: {
          orientation: { $set: action.payload }
        },
        renderMultiplier: { $set: multiplier },
        templateChanged: { $set: true }
      })
    case types.SET_TEMPLATE_CONTAINER_SIZE:
      size = {}
      orientation = state.container.orientation.toUpperCase()

      size.width = action.payload.width
      size.height = action.payload.height

      multiplier = calculateMultiplier(
        size,
        state.container.videoWall,
        orientation
      )

      return update(state, {
        container: {
          size: { $set: size }
        },
        renderMultiplier: { $set: multiplier },
        templateChanged: { $set: true }
      })
    case types.SET_TEMPLATE_CONTAINER_VIDEO_WALL:
      multiplier = state.renderMultiplier
      orientation = state.container.orientation.toUpperCase()

      newVideoWall = update(state.container.videoWall, {
        $merge: action.payload
      })

      multiplier = calculateMultiplier(
        state.container.size,
        newVideoWall,
        orientation
      )

      return update(state, {
        container: {
          videoWall: { $set: newVideoWall }
        },
        renderMultiplier: { $set: multiplier },
        templateChanged: { $set: true }
      })

    case types.SET_CURRENT_TEMPLATE_ITEM:
      return update(state, {
        currentItemId: { $set: action.payload },
        selectedItems: { $set: [] }
      })

    case types.UPDATE_CURRENT_TEMPLATE_ITEM:
      const {
        NESTED,
        MEDIA,
        TITLE,
        TOUCH,
        RANDOMIZE_ORDER,
        TRANSITION,
        COMMON_DURATION,
        FEATURE_ID,
        ICON,
        TYPE
      } = createTemplateConstants
      const setFields = [
        NESTED,
        TITLE,
        TOUCH,
        RANDOMIZE_ORDER,
        TRANSITION,
        COMMON_DURATION,
        FEATURE_ID,
        ICON,
        TYPE,
        MEDIA
      ]
      const field =
        action.payload.field === NESTED ? MEDIA : action.payload.field

      return update(state, {
        items: {
          $merge: {
            [state.currentItemId]: update(state.items[state.currentItemId], {
              [field]: setFields.includes(action.payload.field)
                ? { $set: action.payload.data }
                : { $merge: action.payload.data }
            })
          }
        },
        templateChanged: { $set: true }
      })

    case types.SET_CURRENT_TEMPLATE_ITEM_SIZE:
      item = state.items[state.currentItemId]
      size = { ...item.size }
      position = { ...item.position }
      landscape =
        state.container.orientation ===
        createTemplateConstants.orientationTypes[
          createTemplateConstants.LANDSCAPE
        ]
      value = action.payload.value
      videoWallWidthSectors =
        action.payload?.videoWallSectors?.videoWallWidthSectors
      videoWallHeightSectors =
        action.payload?.videoWallSectors?.videoWallHeightSectors
      containerWidth = landscape
        ? state.container.size.width
        : state.container.size.height
      containerHeight = landscape
        ? state.container.size.height
        : state.container.size.width
      width = videoWallWidthSectors
        ? videoWallWidthSectors * containerWidth
        : containerWidth
      height = videoWallHeightSectors
        ? videoWallHeightSectors * containerHeight
        : containerHeight

      switch (action.payload.field) {
        case createTemplateConstants.WIDTH:
          if (value > width - position.x) {
            value = width - position.x
          }
          size.width = value
          break
        case createTemplateConstants.HEIGHT:
          if (value > height - position.y) {
            value = height - position.y
          }
          size.height = value
          break
        default:
          return state
      }

      return update(state, {
        items: {
          $merge: {
            [state.currentItemId]: update(state.items[state.currentItemId], {
              size: { $set: size }
            })
          }
        },
        templateChanged: { $set: true }
      })

    case types.SET_CURRENT_TEMPLATE_ITEM_POSITION:
      item = state.items[state.currentItemId]
      position = { ...item.position }
      landscape =
        state.container.orientation ===
        createTemplateConstants.orientationTypes[
          createTemplateConstants.LANDSCAPE
        ]

      value = action.payload.value
      width = state.container.size.width
      height = state.container.size.height
      const { active, props } = state.container.videoWall

      switch (action.payload.field) {
        case createTemplateConstants.X_AXIS:
          const maxAllowedX = active
            ? (landscape ? width * props.x : height * props.x) - item.size.width
            : (landscape ? width : height) - item.size.width
          if (value > maxAllowedX) {
            value = maxAllowedX
          }
          position.x = value
          break
        case createTemplateConstants.Y_AXIS:
          const maxAllowedY = active
            ? (landscape ? height * props.y : width * props.y) -
              item.size.height
            : (landscape ? height : width) - item.size.height
          if (value > maxAllowedY) {
            value = maxAllowedY
          }
          position.y = value
          break
        default:
          return state
      }

      return update(state, {
        items: {
          $merge: {
            [state.currentItemId]: update(state.items[state.currentItemId], {
              position: { $set: position }
            })
          }
        },
        templateChanged: { $set: true }
      })

    case types.SET_CURRENT_TEMPLATE_ITEM_ALIGNMENT:
      item = state.items[state.currentItemId]
      size = { ...item.size }
      position = { ...item.position }
      orientation = state.container.orientation.toUpperCase()

      landscape = orientation === createTemplateConstants.LANDSCAPE

      const videoWallActive = state.container.videoWall.active
      const { x, y } = state.container.videoWall.props

      switch (action.payload) {
        case createTemplateConstants.LEFT:
          position.x = 0
          break
        case createTemplateConstants.RIGHT:
          position.x =
            (landscape
              ? state.container.size.width
              : state.container.size.height) *
              (videoWallActive ? x : 1) -
            item.size.width
          break
        case createTemplateConstants.TOP:
          position.y = 0
          break
        case createTemplateConstants.BOTTOM:
          position.y =
            (landscape
              ? state.container.size.height
              : state.container.size.width) *
              (videoWallActive ? y : 1) -
            item.size.height
          break
        case createTemplateConstants.H_STRETCH:
          size.width =
            (landscape
              ? state.container.size.width
              : state.container.size.height) * (videoWallActive ? x : 1)
          position.x = 0
          break
        case createTemplateConstants.V_STRETCH:
          size.height =
            (landscape
              ? state.container.size.height
              : state.container.size.width) * (videoWallActive ? y : 1)
          position.y = 0
          break
        default:
          return state
      }

      return update(state, {
        items: {
          $merge: {
            [state.currentItemId]: update(state.items[state.currentItemId], {
              position: { $set: position },
              size: { $set: size }
            })
          }
        },
        templateChanged: { $set: true }
      })

    case types.SET_CURRENT_TEMPLATE_ITEM_ROTATION:
      item = state.items[state.currentItemId]
      let rotate = item.styles.rotate

      switch (action.payload) {
        case createTemplateConstants.L_ROTATE:
          rotate = (rotate + 270) % 360
          break
        case createTemplateConstants.R_ROTATE:
          rotate = (rotate + 90) % 360
          break
        default:
          return state
      }

      return update(state, {
        items: {
          $merge: {
            [state.currentItemId]: update(state.items[state.currentItemId], {
              styles: {
                rotate: { $set: rotate }
              }
            })
          }
        },
        templateChanged: { $set: true }
      })

    case types.FLIP_CURRENT_TEMPLATE_ITEM:
      item = state.items[state.currentItemId]
      let vFlip = item.styles.vFlip
      let hFlip = item.styles.hFlip

      switch (action.payload) {
        case createTemplateConstants.H_FLIP:
          hFlip = !hFlip
          break
        case createTemplateConstants.V_FLIP:
          vFlip = !vFlip
          break
        default:
          return state
      }

      return update(state, {
        items: {
          $merge: {
            [state.currentItemId]: update(state.items[state.currentItemId], {
              styles: { $merge: { hFlip, vFlip } }
            })
          }
        },
        templateChanged: { $set: true }
      })

    case types.DELETE_TEMPLATE_ITEMS:
      return update(state, {
        items: { $set: {} },
        templateChanged: { $set: true }
      })

    case types.DELETE_TEMPLATE_ITEM:
      const itemsToDelete = [action.payload, ...state.selectedItems]

      return update(state, {
        items: { $unset: itemsToDelete },
        currentItemId: { $set: -1 },
        selectedItems: { $set: [] },
        templateChanged: { $set: true }
      })

    case types.ADD_TEMPLATE_ITEM:
      newId = state.maxId + 1
      newOrder = state.maxOrder + 1
      count = Object.keys(state.items).length
      newItem = {
        uuid: `${action.payload.featureId}_${uuidv4()}`,
        id: newId,
        zoneId: action.payload.zoneId,
        featureId: action.payload.featureId,
        title: action.payload.featureTitle
          ? action.payload.featureTitle
          : action.payload.featureTab,
        type: action.payload.type,
        duration: action.payload.duration,
        icon: action.payload.icon,
        size: action.payload.size
          ? action.payload.size
          : {
              width: Math.min(640, state.container.size.width),
              height: Math.min(360, state.container.size.height)
            },
        position: action.payload.position
          ? action.payload.position
          : {
              x: Math.min(
                count * 40,
                Math.max(0, state.container.size.width - 640)
              ),
              y: Math.min(
                count * 40,
                Math.max(0, state.container.size.height - 360)
              )
            },
        media: action.payload.playbackContent
          ? action.payload.playbackContent
          : {},
        touchSettingJson: action.payload.touchSettingJson
          ? action.payload.touchSettingJson
          : undefined,
        zoneSettings: action.payload.zoneSettings
          ? action.payload.zoneSettings
          : {},
        order:
          action.payload.positionOrder ||
          Number(action.payload.positionOrder) === 0
            ? action.payload.positionOrder
            : newOrder,
        styles: {
          isTouchZone: action.payload.isTouchZone
            ? action.payload.isTouchZone
            : false,
          rotate: 0,
          opacity: 1,
          background: action.payload.featureColor
            ? action.payload.featureColor
                .split(',')
                .map((item, i) => (i === 3 ? '0.9)' : item))
                .join()
            : 'rgba(65, 72, 116, 0.9)',
          borderWidth: 0,
          borderHeight: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderColor: 'rgba(0, 0, 0, 1)',
          vFlip: false,
          hFlip: false,
          isZoomToFit: true,
          ...(action.payload.styles && action.payload.styles),
          showButtons: true
        },
        restricted: action.payload.restricted
      }

      return update(state, {
        items: {
          $merge: { [newId]: newItem }
        },
        maxId: { $set: newId },
        maxOrder: { $set: action.payload.positionOrder || newOrder },
        templateChanged: { $set: true }
      })

    case types.COPY_TEMPLATE_ITEM:
      item = state.items[action.payload]
      newId = state.maxId + 1
      newOrder = state.maxOrder + 1
      newItem = update(item, {
        title: { $set: `${item.title}` },
        id: { $set: newId },
        order: { $set: newOrder },
        touchSettingJson: {
          $set: {
            ...item.touchSettingJson
          }
        }
      })

      return update(state, {
        items: {
          $merge: { [newId]: newItem }
        },
        maxId: { $set: newId },
        maxOrder: { $set: newOrder },
        templateChanged: { $set: true }
      })

    case types.GET_TOUCH_ZONE_TEMPLATE_ITEM:
      return update(state, {
        touchZoneMediaItems: []
      })

    case types.GET_TOUCH_ZONE_TEMPLATE_ITEM_SUCCESS:
      return update(state, {
        touchZoneMediaItems: { $merge: [action.payload] }
      })

    case types.GET_TOUCH_ZONE_TEMPLATE_ITEM_ERROR:
      return update(state, {
        mediaItem: {
          error: { $set: action.payload }
        }
      })

    case types.MOVE_TEMPLATE_ITEM_FORWARD:
      newOrder = state.maxOrder + 1

      return update(state, {
        items: {
          [action.payload]: {
            order: { $set: newOrder }
          }
        },
        maxOrder: { $set: newOrder },
        templateChanged: { $set: true }
      })

    case types.MOVE_TEMPLATE_ITEM_BACK:
      const itemsCopy = { ...state.items }

      newOrder = state.maxOrder + 1

      Object.keys(itemsCopy).forEach(id => {
        if (+id === action.payload) {
          itemsCopy[id].order = 0
        } else {
          itemsCopy[id].order += 1
        }
      })

      return update(state, {
        items: { $set: itemsCopy },
        maxOrder: { $set: newOrder },
        templateChanged: { $set: true }
      })

    case types.ZOOM_IN_TEMPLATE_CONTAINER:
      newVideoWall = update(state.container.videoWall, {
        zoomed: { $set: true }
      })

      orientation = state.container.orientation.toUpperCase()
      multiplier = calculateMultiplier(
        state.container.size,
        newVideoWall,
        orientation
      )

      return update(state, {
        container: {
          videoWall: { $set: newVideoWall }
        },
        renderMultiplier: { $set: multiplier },
        templateChanged: { $set: true }
      })

    case types.ZOOM_OUT_TEMPLATE_CONTAINER:
      newVideoWall = update(state.container.videoWall, {
        zoomed: { $set: false }
      })

      orientation = state.container.orientation.toUpperCase()
      multiplier = calculateMultiplier(
        state.container.size,
        newVideoWall,
        orientation
      )

      return update(state, {
        container: {
          videoWall: { $set: newVideoWall }
        },
        renderMultiplier: { $set: multiplier },
        templateChanged: { $set: true }
      })

    case types.SET_MULTIPLIER:
      return update(state, {
        renderMultiplier: { $set: action.payload },
        templateChanged: { $set: true }
      })

    case types.LOCK_TEMPLATE_ITEM:
      return update(state, {
        items: {
          [action.payload]: {
            zoneSettings: {
              locked: { $set: true }
            }
          }
        },
        templateChanged: { $set: true }
      })

    case types.UNLOCK_TEMPLATE_ITEM:
      return update(state, {
        items: {
          [action.payload]: {
            zoneSettings: {
              locked: { $set: false }
            }
          }
        },
        templateChanged: { $set: true }
      })

    case types.TOGGLE_TEMPLATE_ITEM_TO_SELECTED:
      const index = state.selectedItems.indexOf(action.payload)
      if (index !== -1) {
        return update(state, {
          selectedItems: { $splice: [[index, 1]] }
        })
      } else {
        return update(state, {
          selectedItems: { $push: [action.payload] }
        })
      }

    case types.CLEAR_SELECTED_ITEMS:
      return update(state, {
        selectedItems: { $set: [] }
      })

    case types.UPDATE_TEMPLATE_HISTORY:
      return update(state, {
        indexHistory: { $set: state.indexHistory + 1 },
        templateChanged: { $set: false },
        templateHistoryLength: { $set: newTemplateHistoryLength + 1 },
        templateHistory: {
          $set: [
            ...newTemplateHistory,
            {
              container: state.container,
              items: state.items,
              maxId: state.maxId,
              maxOrder: state.maxOrder,
              renderMultiplier: state.renderMultiplier
            }
          ]
        }
      })

    case types.UNDO_TEMPLATE:
      if (state.indexHistory > 1) {
        newIndex = state.indexHistory - 1
        newSnapshot = state.templateHistory[newIndex - 1]
        return update(state, {
          indexHistory: { $set: newIndex },
          templateChanged: { $set: false },
          container: { $set: newSnapshot.container },
          items: { $set: newSnapshot.items },
          maxId: { $set: newSnapshot.maxId },
          maxOrder: { $set: newSnapshot.maxOrder },
          renderMultiplier: { $set: newSnapshot.renderMultiplier }
        })
      }
      return state

    case types.REDO_TEMPLATE:
      if (state.indexHistory < state.templateHistory.length) {
        newIndex = state.indexHistory + 1
        newSnapshot = state.templateHistory[newIndex - 1]
        return update(state, {
          indexHistory: { $set: newIndex },
          templateChanged: { $set: false },
          container: { $set: newSnapshot.container },
          items: { $set: newSnapshot.items },
          maxId: { $set: newSnapshot.maxId },
          maxOrder: { $set: newSnapshot.maxOrder },
          renderMultiplier: { $set: newSnapshot.renderMultiplier }
        })
      }
      return state

    case types.CLEAR_TEMPLATE_HISTORY:
      return update(state, {
        indexHistory: { $set: 0 },
        templateChanged: { $set: true },
        templateHistoryLength: { $set: 0 },
        templateHistory: {
          $set: []
        }
      })
    case types.GET_TEMPLATE_MEDIA:
    case types.GET_TEMPLATE_PLAYLIST:
      return update(state, {
        mediaItem: {
          response: { $set: null },
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.GET_TEMPLATE_MEDIA_SUCCESS:
    case types.GET_TEMPLATE_PLAYLIST_SUCCESS:
      return update(state, {
        mediaItem: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_TEMPLATE_MEDIA_ERROR:
    case types.GET_TEMPLATE_PLAYLIST_ERROR:
      return update(state, {
        mediaItem: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.BULK_UPDATE_TEMPLATE_ITEM:
      return update(state, {
        items: {
          $merge: bulkUpdateTemplateItemError(state.items, action.payload, true)
        },
        templateChanged: { $set: true }
      })
    case types.BULK_UPDATE_TEMPLATE_ITEM_SUCCESS:
      return update(state, {
        items: {
          $merge: bulkUpdateTemplateItemSuccess(state.items, action.payload)
        },
        templateChanged: { $set: true }
      })
    case types.BULK_UPDATE_TEMPLATE_ITEM_ERROR:
      return update(state, {
        items: {
          $merge: bulkUpdateTemplateItemError(
            state.items,
            action.payload,
            false
          )
        },
        templateChanged: { $set: true }
      })
    case types.SET_SELECT_TEMPLATE_GALLERY:
      return update(state, {
        selectedTemplateGallery: {
          $set: action.payload
        }
      })
    case types.CLEAR_SELECTED_TEMPLATE_GALLERY:
      return update(state, {
        selectedTemplateGallery: {
          $set: null
        }
      })
    default:
      return state
  }
}

export default createTemplateReducer

import { useMemo } from 'react'

import { featureNames } from 'constants/featureConstants'
import { _isEmpty } from 'utils/lodash'

const useProofOfPlaySettings = ({ client }) => {
  return useMemo(() => {
    const { feature, featureSetting } = client

    const id = feature?.find(
      feature => feature.name === featureNames.ProofOfPlay
    )?.id
    const proofOfPlaySetting = featureSetting?.find(
      setting => setting.id === id
    )

    return {
      proofOfPlayEnabledByClient: !_isEmpty(proofOfPlaySetting?.settings),
      proofOfPlayOptions: proofOfPlaySetting?.settings?.org_settings?.options
    }
  }, [client])
}

export default useProofOfPlaySettings

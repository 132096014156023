import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'

import {
  withStyles,
  Grid,
  InputLabel,
  Typography,
  withTheme
} from '@material-ui/core'

import 'react-dates/lib/css/_datepicker.css'
import '../../styles/forms/_datepicker.scss'
import classNames from 'classnames'

const styles = theme => {
  const { palette, colors, type } = theme
  return {
    root: {
      marginBottom: theme.spacing(2)
    },
    datePicker: {
      display: 'flex',
      flexDirection: 'row',
      verticalAlign: 'middle'
    },
    labelRoot: {
      display: 'flex',
      width: '100%'
    },
    label: {
      fontSize: 14,
      fontWeight: 'bold',
      color: palette[type].formControls.label.color,
      flexGrow: 1
    },
    endDateLabel: {
      marginLeft: 17
    },
    dividerText: {
      fontSize: 12,
      lineHeight: '28px',
      textTransform: 'uppercase'
    },
    inputContainer: {
      margin: '1px 1px 1px 2px'
    },
    errorText: {
      color: colors.error,
      fontSize: 9
    }
  }
}

class FormControlDateRangePickers extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onDatesChange: PropTypes.func
  }

  static defaultProps = {
    bottomMargin: true,
    startDatePlaceholderText: 'Start',
    endDatePlaceholderText: 'End'
  }

  constructor(props) {
    super(props)

    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null
    }

    document.body.classList.add(
      props.theme.type === 'dark' ? 'DatePicker-dark' : 'DatePicker-light'
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.theme.type !== this.props.theme.type) {
      document.body.classList.remove('DatePicker-dark')
      document.body.classList.remove('DatePicker-light')

      document.body.classList.add(
        this.props.theme.type === 'dark'
          ? 'DatePicker-dark'
          : 'DatePicker-light'
      )
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('DatePicker-dark')
    document.body.classList.remove('DatePicker-light')
  }

  render() {
    const {
      id,
      classes,
      label,
      dividerText,
      bottomMargin,
      startDatePlaceholderText,
      endDatePlaceholderText,
      formControlLabelClass = '',
      formControlDividerTextClass = '',
      onDatesChange,
      startDate,
      endDate,
      error,
      touched,
      anchorDirection = 'right',
      withPortal = false,
      theme,
      isOutsideRange = () => false,
      endDateLabel,
      separateFields = false,
      minimumNights = 0,
      ...props
    } = this.props
    return (
      <div className={bottomMargin ? classes.root : null}>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          className={classNames(classes.inputContainer, {
            DarkTheme: theme.type === 'dark',
            SeparateFields: separateFields
          })}
        >
          <div className={classes.labelRoot}>
            {label && (
              <InputLabel
                shrink
                className={[classes.label, formControlLabelClass].join(' ')}
              >
                {label}
              </InputLabel>
            )}
            {endDateLabel && (
              <InputLabel
                shrink
                className={[
                  classes.label,
                  classes.endDateLabel,
                  formControlLabelClass
                ].join(' ')}
              >
                {endDateLabel}
              </InputLabel>
            )}
          </div>
          <DateRangePicker
            customArrowIcon={
              <Typography
                className={[
                  classes.dividerText,
                  formControlDividerTextClass
                ].join(' ')}
              >
                {dividerText}
              </Typography>
            }
            anchorDirection={anchorDirection}
            isOutsideRange={e =>
              isOutsideRange(
                e,
                this.state.focusedInput,
                startDate,
                endDate,
                minimumNights
              )
            }
            minimumNights={minimumNights}
            startDateId={`${id}-from`}
            endDateId={`${id}-to`}
            startDate={onDatesChange ? startDate : this.state.startDate}
            endDate={onDatesChange ? endDate : this.state.endDate}
            onDatesChange={
              onDatesChange ||
              (({ startDate, endDate }) =>
                this.setState({ startDate, endDate }))
            }
            onFocusChange={focusedInput => this.setState({ focusedInput })}
            focusedInput={this.state.focusedInput}
            startDatePlaceholderText={startDatePlaceholderText}
            endDatePlaceholderText={endDatePlaceholderText}
            appendToBody={withPortal}
            {...props}
          />
        </Grid>
        {error && touched && (
          <Typography className={classes.errorText}>{error}</Typography>
        )}
      </div>
    )
  }
}

export default withTheme(withStyles(styles)(FormControlDateRangePickers))

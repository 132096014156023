export const DEFAULT_RESULT_COUNT = 9
// user types
export const ORG_USER = 'ORG_USER'
export const SYSTEM_USER = 'SYSTEM_USER'
export const ENTERPRISE_USER = 'ENTERPRISE_USER'
// token names
export const ORG_TOKEN = 'accessToken'
export const SYSTEM_TOKEN = 'accessSystemToken'
export const ENTERPRISE_TOKEN = 'enterpriseSystemToken'
export const FACEBOOK_TOKEN = 'facebookToken'
export const INSTAGRAM_TOKEN = 'instagramToken'
export const FACEBOOK_BUSINESS_TOKEN = 'facebookBusinessToken'
export const LEGACY_THEMES_ENABLED = 'legacyThemesEnabled'
// roles
export const ORG_ROLE = 'org'
export const ENTERPRISE_ROLE = 'enterprise'
export const SYSTEM_ROLE = 'system'
export const RESELLER_ROLE = 'reseller'

export const EXPIRES_IN = 'expiresIn'
export const ORIGINAL_USERS = 'originalUsers'
export const LAST_ACTIVITY = 'lastActivity'
export const DESIGN_GALLERY_HISTORY = 'DESIGN_GALLERY_HISTORY'
export const UPPY_RESTORE_STATE = 'uppyRestoreState'

export const mediaFileSubTypes = [
  'Video',
  'Image',
  'Flash',
  'Audio',
  'PDF',
  'SftpFolder'
]

export const PEXEL_API_URL = 'https://api.pexels.com'
export const PIXABAY_API_URL = 'https://pixabay.com/api/'
export const SLACK_API_URL = 'https://slack.com/api/'
export const MVIX_URL = 'https://www.mvix.com'

export const ACTIVITY_CONFIRMATION_DURATION = 60

export const ORG_USER_PASSWORD_EXPIRE = 180
export const SYSTEM_USER_PASSWORD_EXPIRE = 90

export const AWS_URL_PART = 'amazonaws.com'
export const awsValidationHeaders = {
  Range: 'bytes=0-1023'
}

export const noCacheHeaders = {
  Pragma: 'no-cache',
  'Cache-Control': 'no-cache'
}

export const DEFAULT_NOTIFICATION_DURATION = 3000
export const DEFAULT_TRANSITION_DURATION = 1000

export const MIN_FAVORITE_FEATURES = 3

export const resourceTypes = {
  PlaylistResource: 'PlaylistResource',
  ScheduleResource: 'ScheduleResource',
  MediaResource: 'MediaResource',
  TemplateResource: 'TemplateResource',
  TagWithReportResource: 'TagWithReportResource',
  GroupResource: 'GroupResource',
  DeviceLocationResource: 'DeviceLocationResource',
  SystemClientResource: 'SystemClientResource',
  DeviceListResource: 'DeviceListResource',
  UserResource: 'UserResource',
  ClientUserResource: 'ClientUserResource',
  MenuItemResource: 'MenuItemResource',
  CategoryResource: 'CategoryResource',
  SystemClientListResource: 'SystemClientListResource'
}

export const conflictTypes = {
  deviceMacAddress: 'deviceMacAddress',
  deviceMacAddressForReuse: 'deviceMacAddressForReuse',
  deviceHasSchedule: 'deviceHasSchedule',
  associationHasSchedule: 'associationHasSchedule',
  featureDisabled: 'featureDisabled',
  multipleScheduleAssociation: 'multipleScheduleAssociation',
  schedulesConflict: 'schedulesConflict',
  modelAlreadyUsed: 'modelAlreadyUsed',
  modelCollectionAlreadyUsed: 'modelCollectionAlreadyUsed',
  confirmAutoAddDeviceToScheduleException:
    'confirmAutoAddDeviceToScheduleException',
  multipleCollectionScheduleAssociation:
    'multipleCollectionScheduleAssociation',
  deviceHasAutoAssignedScheduleException:
    'deviceHasAutoAssignedScheduleException',
  modelHasScheduleAssociationException: 'modelHasScheduleAssociationException',
  removeEmailTemplateRelatedToEntitiesException:
    'RemoveEmailTemplateRelatedToEntitiesException',
  deviceConflictedScheduleAssociationException:
    'DeviceConflictedScheduleAssociationException'
}

export const exceptionsToConflictTypes = {
  DeviceMacAddressNotUniqueException: conflictTypes.deviceMacAddress,
  ModelConflictedDeviceScheduleException: conflictTypes.deviceHasSchedule,
  ModelFeatureNotAllowedException: conflictTypes.featureDisabled,
  DeviceHasMultipleScheduleAssociationException:
    conflictTypes.multipleScheduleAssociation,
  ModelConflictedScheduleException: conflictTypes.schedulesConflict,
  ModelConflictedDeviceScheduleAssociationException:
    conflictTypes.associationHasSchedule,
  ScheduleConflictedException: conflictTypes.schedulesConflict,
  ModelAlreadyUsedErrorException: conflictTypes.modelAlreadyUsed,
  ModelCollectionAlreadyUsedErrorException:
    conflictTypes.modelCollectionAlreadyUsed,
  ConfirmAutoAddDeviceToScheduleException:
    conflictTypes.confirmAutoAddDeviceToScheduleException,
  DeviceCollectionHasMultipleScheduleAssociationException:
    conflictTypes.multipleCollectionScheduleAssociation,
  DeviceHasAutoAssignedScheduleException:
    conflictTypes.deviceHasAutoAssignedScheduleException,
  ModelHasScheduleAssociationException:
    conflictTypes.modelHasScheduleAssociationException,
  RemoveEmailTemplateRelatedToEntitiesException:
    conflictTypes.removeEmailTemplateRelatedToEntitiesException,
  DeviceConflictedScheduleAssociationException:
    conflictTypes.deviceConflictedScheduleAssociationException
}

export const conflictSources = {
  general: 'general',
  addEditDevice: 'addEditDevice',
  deviceToGroup: 'deviceToGroup',
  bulkTagsAssign: 'bulkTagsAssign',
  deviceLocation: 'deviceLocation',
  playlist: 'playlist',
  smartPlaylist: 'smartPlaylist',
  interactivePlaylist: 'interactivePlaylist',
  createTemplate: 'createTemplate',
  media: 'media',
  tag: 'tag',
  scheduleTimeline: 'scheduleTimeline',
  group: 'group',
  designGalleryCategories: 'designGalleryCategories'
}

export const conflictForceTypes = {
  force: 'force',
  forceAssociateDevices: 'forceAssociateDevices',
  forceAssociateConflict: 'forceAssociateConflict',
  reuseDisabledMacAddress: 'reuseDisabledMacAddress',
  confirmationToSave: 'confirmationToSave',
  confirmAutoAddDeviceToSchedule: 'confirmAutoAddDeviceToSchedule',
  confirmationToDelete: 'confirmationToDelete'
}

export const conflictSourceToEntity = {
  [conflictSources.group]: 'Group',
  [conflictSources.tag]: 'Tag',
  [conflictSources.deviceLocation]: 'Location'
}

export const LAST_LOGIN_PROVIDER = 'lastLoginProvider'

export const billingNotificationTypes = {
  normal: 1,
  final: 2,
  custom: 3
}

export const billingNotificationMessages = {
  [billingNotificationTypes.normal]:
    'Your account is 30+days overdue and subject to late fee and possible deactivation. Contact ar@mvix.com to avoid service disruption',
  [billingNotificationTypes.final]:
    'FINAL NOTICE: This account is PAST DUE and subject to termination immediately. Please remit payment to avoid service disruption'
}

export const LOGOUT_EVENT_NAME = 'logout'

export const instanceTypes = {
  local: 'local',
  dev: 'dev',
  qa: 'qa',
  prod: 'prod'
}

export const contentTypes = {
  media: 'media',
  playlist: 'playlist',
  template: 'template',
  schedule: 'schedule'
}

export const EXPIRATION_TIME_FALLBACK = '23:59:59'

export const redirectRoutes = {
  instagram: '/login/instagram',
  microsoft: '/login/microsoft',
  facebook: '/login/facebook',
  linkedin: '/login/linkedin',
  msTeams: '/login/msTeams',
  outlook: '/login/OutlookCalendar',
  itsaCheckmate: '/oauth2/itsacheckmate'
}

export const redirectUrls = {
  instagram: `${window.location.origin}${redirectRoutes.instagram}`,
  microsoft: `${window.location.origin}${redirectRoutes.microsoft}`,
  facebook: `${window.location.origin}${redirectRoutes.facebook}`,
  linkedin: `${window.location.origin}${redirectRoutes.linkedin}`,
  msTeams: `${window.location.origin}${redirectRoutes.msTeams}/`,
  outlook: `${window.location.origin}${redirectRoutes.outlook}`,
  dumb: `${window.location.origin}/myapp`,
  itsaCheckmate: `${window.location.origin}${redirectRoutes.itsaCheckmate}`
}

export const CURRENT_SAVED_FILTERS_VERSION = 1

export const vuePreviews = {
  v2: 'v2',
  v3: 'v3'
}

export const initialMeta = {
  currentPage: 1,
  from: 0,
  lastPage: 0,
  perPage: 0,
  to: 0,
  total: 0,
  count: 0,
  isLoading: true
}

import React, { useCallback, useMemo } from 'react'
import 'react-dates/initialize'
import { DayPickerSingleDateController } from 'react-dates'
import moment from 'moment'
import { Grid, withStyles, withTheme } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Popup from 'components/Popup'
import FormControlInput from 'components/Form/FormControlInput'
import { popupPosition as positionConstants } from 'constants/stylesConstants'
import { BACKEND_DATE_FORMAT } from 'constants/dateTimeFormats'
import { simulateEvent } from 'utils'

import 'react-dates/lib/css/_datepicker.css'
import 'styles/forms/_datepicker.scss'
import MaterialPopup from 'components/Popup/MaterialPopup'

const styles = () => ({
  inputContainer: {
    width: '100%',
    cursor: 'pointer'
  },
  inputInput: {
    cursor: 'pointer'
  },
  inputIcon: {
    color: '#74809a',
    position: 'absolute',
    right: 10,
    top: 9
  },
  button: {
    padding: 0,
    margin: 0,
    position: 'relative',
    background: 'transparent',
    border: 0,
    outline: 'none'
  },
  marginL: {
    marginLeft: 10
  },
  marginBottom: {
    marginBottom: 16
  },
  clearIcon: {
    position: 'absolute',
    top: 10,
    right: 34,
    fontSize: 13,
    color: '#74809a',
    cursor: 'pointer'
  },
  disablePointerEvents: {
    pointerEvents: 'none'
  },
  labelRoot: {
    fontSize: 13,
    lineHeight: '22px',
    fontWeight: 400,
    color: '#74809A',
    transform: 'none'
  },
  root: {
    display: 'flex'
  },
  topLabel: {
    flexDirection: 'column'
  },
  bottomLabel: {
    flexDirection: 'column-reverse'
  },
  leftLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > div:first-child': {
      paddingRight: 5
    }
  },
  rightLabel: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center',

    '& > div:first-child': {
      paddingLeft: 5
    }
  }
})

const isCurrentOrFutureRange = day => moment().diff(day) > 0

const FormControlSingleDatePicker = ({
  classes,
  label,
  value,
  handleChange = f => f,
  handleBlur = f => f,
  isOptional = false,
  error,
  touched,
  classContainer,
  buttonWrapper,
  name,
  format = BACKEND_DATE_FORMAT,
  placeholder,
  noMargin,
  disabled,
  popupPosition = positionConstants.BOTTOM_RIGHT,
  isClearable,
  offsetY,
  theme,
  isOutsideRange = isCurrentOrFutureRange,
  withPortal = false,
  labelPosition = 'top',
  ...props
}) => {
  const { t } = useTranslation()

  const onBlur = useCallback(() => {
    handleBlur(simulateEvent(name, null))
  }, [handleBlur, name])

  const handle = useCallback(
    value => {
      handleChange({ target: { name, value } })
    },
    [handleChange, name]
  )

  const formatValue = useMemo(() => {
    if (!value) return ''
    return moment(value).format(format)
  }, [format, value])

  const clearValue = useCallback(
    event => {
      handleChange({ target: { name, value: '' } })
      event.stopPropagation()
    },
    [handleChange, name]
  )

  const CustomPopup = useCallback(
    ({ children, trigger }) =>
      withPortal ? (
        <MaterialPopup
          on="click"
          placement={popupPosition}
          offset={offsetY}
          disabled={disabled}
          onClose={onBlur}
          style={{ minHeight: 300 }}
          trigger={trigger}
          hasArrow
          preventOverflow={{
            enabled: true,
            boundariesElement: 'viewport'
          }}
        >
          {children}
        </MaterialPopup>
      ) : (
        <Popup
          on="click"
          position={popupPosition}
          offsetY={offsetY}
          disabled={disabled}
          onClose={onBlur}
          contentStyle={{ minHeight: 300 }}
          trigger={trigger}
        >
          {children}
        </Popup>
      ),
    [withPortal, popupPosition, offsetY, onBlur, disabled]
  )

  return (
    <Grid
      container
      className={classNames(classes.root, classContainer, {
        [classes.marginBottom]: !noMargin,
        [classes.leftLabel]: labelPosition === 'left',
        [classes.topLabel]: labelPosition === 'top',
        [classes.bottomLabel]: labelPosition === 'bottom',
        [classes.rightLabel]: labelPosition === 'right'
      })}
    >
      <div className={classes.labelRoot}>
        {label} {isOptional && <i>({t('optional')})</i>}
      </div>
      <CustomPopup
        trigger={
          <div className={classNames(classes.button, buttonWrapper)}>
            <FormControlInput
              type="text"
              value={formatValue}
              marginBottom={false}
              formControlContainerClass={classes.inputContainer}
              formControlInputClass={classNames(classes.inputInput, {
                [classes.disablePointerEvents]: disabled
              })}
              icon={
                <i
                  className={classNames(
                    'fa-regular fa-calendar-days',
                    classes.inputIcon,
                    {
                      [classes.disablePointerEvents]: disabled
                    }
                  )}
                />
              }
              placeholder={placeholder}
              error={error}
              touched={touched}
              isOptional={isOptional}
              disabled={disabled}
              {...props}
            />
            {isClearable && value && (
              <i
                className={classNames('fa-regular fa-xmark', classes.clearIcon)}
                onClick={clearValue}
              />
            )}
          </div>
        }
      >
        <Grid
          container
          alignItems="center"
          justify="space-between"
          className={classNames({
            'DatePicker-dark': theme.type === 'dark'
          })}
        >
          <DayPickerSingleDateController
            date={value || null}
            onDateChange={handle}
            {...(isOutsideRange && { isOutsideRange })}
            focused
          />
        </Grid>
      </CustomPopup>
    </Grid>
  )
}

export default withTheme(withStyles(styles)(FormControlSingleDatePicker))

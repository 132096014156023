import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import _get from 'lodash/get'

import DefaultModal from 'components/Modal/DefaultModal'
import TabsGroup, { createTab } from 'components/Tabs/TabsGroup'
import Scrollbars from 'components/Scrollbars'
import ModalPlaylistItem from 'components/Pages/Media/ModalPlaylistItem'
import { AssociationModalLoader } from 'components/Loaders'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import ScheduleListRow from 'components/Pages/Schedule/ScheduleListRow'
import TemplateListRow from 'components/Pages/Template/TemplateListRow'
import { mediaAssociationsSelector } from 'selectors/mediaSelectors'
import { playlistAssociationsSelector } from 'selectors/playlistSelectors'
import { templateAssociationsSelector } from 'selectors/templateSelectors'
import {
  getMediaAssociations,
  clearMediaAssociations
} from 'actions/mediaActions'
import {
  clearPlaylistAssociations,
  getPlaylistAssociations
} from 'actions/playlistActions'
import {
  clearTemplateAssociations,
  getTemplateAssociations
} from 'actions/templateActions'
import useLazyLoad from 'hooks/useLazyLoad'
import { entityConstants } from 'constants/index'
import ScreenPreviewModal from '../ScreenPreviewModal'
import { Text, TextWithTooltip } from 'components/Typography'
import ModalMediaItem from 'components/Pages/Media/ModalMediaItem'
import DependencyRow from 'components/Modal/DependencyModal/DependencyRow'
import ClientIcon from 'components/Modal/DependencyModal/ClientIcon'
import camelCaseToSplitCapitalize from 'utils/camelCaseToSplitCapitalize'
import { dependencyType } from 'constants/library'

const useStyles = makeStyles(() => ({
  placeholder: {
    height: 240
  },
  modalContent: {
    padding: '10px 20px'
  }
}))

const MAX_MODAL_WIDTH = 560

const EntityAssociationModal = ({ item = {}, entity, open, onCloseModal }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('translations')
  const classes = useStyles()

  const { selector, fetchAction, clearAction } = useMemo(() => {
    switch (entity) {
      case entityConstants.MediaLibrary:
        return {
          selector: mediaAssociationsSelector,
          fetchAction: getMediaAssociations,
          clearAction: clearMediaAssociations
        }
      case entityConstants.PlaylistLibrary:
        return {
          selector: playlistAssociationsSelector,
          fetchAction: getPlaylistAssociations,
          clearAction: clearPlaylistAssociations
        }
      case entityConstants.TemplateLibrary:
        return {
          selector: templateAssociationsSelector,
          fetchAction: getTemplateAssociations,
          clearAction: clearTemplateAssociations
        }
      default:
        return {
          selector: () => ({ response: {}, meta: {}, isFetching: false }),
          fetchAction: () => {},
          clearAction: () => {}
        }
    }
  }, [entity])

  const handleFetch = useCallback(
    (params = {}) => {
      if (item?.id) {
        dispatch(fetchAction(item.id, params))
      }
    },
    [dispatch, fetchAction, item]
  )

  const handleClear = useCallback(() => dispatch(clearAction()), [
    dispatch,
    clearAction
  ])

  const { response, isFetching, meta } = useSelector(selector)

  const [selectedTab, setSelectedTab] = useState('')

  const handleTabChange = useCallback((e, value) => {
    if (value) {
      setSelectedTab(value)
    }
  }, [])

  const parserAssociatedResponse = useCallback((prevData, response) => {
    return {
      media: [...(prevData?.Media || []), ...(response?.Media || [])],
      playlists: [...(prevData?.Playlist || []), ...(response?.Playlist || [])],
      templates: [...(prevData?.Template || []), ...(response?.Template || [])],
      schedules: [...(prevData?.Schedule || []), ...(response?.Schedule || [])],
      entities: [...(prevData?.Entities || []), ...(response?.Entities || [])]
    }
  }, [])

  const { data } = useLazyLoad({
    response,
    meta,
    isFetching,
    fetcher: handleFetch,
    responseParser: parserAssociatedResponse,
    onClear: handleClear
  })

  const createAssociationTabs = useCallback(
    entities => {
      return entities
        .map(entity => {
          switch (entity) {
            case entityConstants.MediaLibrary:
              return data.media?.length ? createTab(t('Media'), entity) : null
            case entityConstants.PlaylistLibrary:
              return data.playlists?.length
                ? createTab(t('Playlists'), entity)
                : null
            case entityConstants.TemplateLibrary:
              return data.templates?.length
                ? createTab(t('Templates'), entity)
                : null
            case entityConstants.ScheduleLibrary:
              return data.schedules?.length
                ? createTab(t('Schedules'), entity)
                : null
            case dependencyType.entities:
              return data.entities?.length
                ? createTab(t('Entities'), entity)
                : null
            default:
              return null
          }
        })
        .filter(tab => tab)
    },
    [data, t]
  )

  const tabs = useMemo(() => {
    switch (entity) {
      case entityConstants.MediaLibrary:
        return createAssociationTabs([
          entityConstants.MediaLibrary,
          entityConstants.PlaylistLibrary,
          entityConstants.TemplateLibrary,
          entityConstants.ScheduleLibrary,
          dependencyType.entities
        ])
      case entityConstants.PlaylistLibrary:
        return createAssociationTabs([
          entityConstants.TemplateLibrary,
          entityConstants.ScheduleLibrary
        ])
      case entityConstants.TemplateLibrary:
        return createAssociationTabs([entityConstants.ScheduleLibrary])
      default:
        return []
    }
  }, [entity, createAssociationTabs])

  useEffect(() => {
    if (tabs.length) {
      setSelectedTab(tabs[0].value)
    }
  }, [tabs])

  const renderTabContent = useMemo(() => {
    switch (selectedTab) {
      case entityConstants.MediaLibrary:
        return _get(data, 'media', []).map(media => (
          <ModalMediaItem key={media.id} withLink item={media} />
        ))
      case entityConstants.PlaylistLibrary:
        return _get(data, 'playlists', []).map(playlist => (
          <ModalPlaylistItem
            key={playlist.id}
            withLink
            selectType="none"
            data={playlist}
          />
        ))
      case entityConstants.ScheduleLibrary:
        return _get(data, 'schedules', []).map(schedule => (
          <ScheduleListRow
            key={schedule.id}
            item={schedule}
            selectType="none"
            withLink
          />
        ))
      case entityConstants.TemplateLibrary:
        return _get(data, 'templates', []).map(template => (
          <TemplateListRow
            key={template.id}
            item={template}
            selectType="none"
            withLink
          />
        ))
      case dependencyType.entities:
        return _get(data, 'entities', []).map(row => (
          <DependencyRow
            key={row.id}
            item={{
              iconComponent: (
                <ClientIcon serviceLevel={row.entity?.serviceLevel} />
              ),
              title: camelCaseToSplitCapitalize(row.mediaType),
              subTitle: row.entity.name,
              noDate: true
            }}
          />
        ))
      default:
        return null
    }
  }, [selectedTab, data])

  const getTabWidth = useCallback(tabs => {
    const maxWidth = MAX_MODAL_WIDTH / tabs.length - 5
    if (maxWidth > 150) return 150

    return maxWidth
  }, [])

  return (
    <DefaultModal
      modalTitle={
        <Grid container>
          <TextWithTooltip
            maxWidth={300}
            variant="big"
            weight="bold"
            component="h2"
            color="title.primary"
          >
            {item.title}
          </TextWithTooltip>
          <Text
            display="inline"
            variant="big"
            weight="bold"
            component="h2"
            color="title.primary"
          >{` | ${t('Associations')}`}</Text>
        </Grid>
      }
      open={open}
      onCloseModal={onCloseModal}
      onClickSave={onCloseModal}
      withActions={false}
      contentClass={classes.modalContent}
    >
      {isFetching ? (
        <AssociationModalLoader />
      ) : (
        <>
          <TabsGroup
            tabs={tabs}
            value={selectedTab}
            onChange={handleTabChange}
            tabWidth={getTabWidth(tabs)}
          />
          <Scrollbars autoHeight autoHeightMin={200} autoHeightMax="80vh">
            {!tabs.length && typeof data === 'object' && (
              <EmptyPlaceholder
                text={t('No Associations')}
                rootClassName={classes.placeholder}
              />
            )}
            {renderTabContent}
          </Scrollbars>
        </>
      )}
      <ScreenPreviewModal targetReducer="playlist" />
      <ScreenPreviewModal targetReducer="schedule" />
      <ScreenPreviewModal targetReducer="template" />
    </DefaultModal>
  )
}

EntityAssociationModal.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired
  }),
  entity: PropTypes.oneOf([
    entityConstants.MediaLibrary,
    entityConstants.PlaylistLibrary,
    entityConstants.TemplateLibrary
  ]).isRequired,
  open: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired
}

export default EntityAssociationModal

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { _get } from 'utils/lodash'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import moment from 'moment'

import { FormControlDateTimePicker } from 'components/Form/index'
import Text from 'components/Typography/Text'
import Spacing from 'components/Containers/Spacing'

const styles = ({ palette, type, shapes, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  separator: {
    height: shapes.height.primary,
    display: 'flex',
    padding: '0 10px',
    backgroundColor: palette[type].pages.media.general.card.header.background,
    alignItems: 'center',
    color: palette[type].pages.media.general.card.header.color
  },
  unsetWidth: {
    width: 'unset'
  },
  multiline: {
    flexDirection: 'column',
    width: '100%'
  },
  marginRight: {
    marginRight: spacing(2)
  }
})

const FormControlDateTimeRangePicker = ({
  multiline,
  classes,
  startDate,
  endDate,
  nameStartDate,
  nameEndDate,
  startPopupPosition = 'top',
  endPopupPosition = 'top',
  format = '',
  dateTimeDelimiter,
  disabled,
  onChange,
  errors,
  t,
  maskValue = 'MMM DD, YYYY',
  keepTooltipInside,
  offsetY,
  startLabel,
  endLabel,
  isOptional,
  setInitValue,
  isClearable,
  initialDate,
  withSeparator = true
}) => {
  return (
    <div
      className={classNames(classes.root, { [classes.multiline]: multiline })}
    >
      <FormControlDateTimePicker
        label={startLabel}
        name={nameStartDate}
        initialValue={startDate}
        handleChange={onChange}
        classRoot={classNames({
          [classes.unsetWidth]: !multiline,
          [classes.marginRight]: !withSeparator
        })}
        format={format}
        dateTimeDelimiter={dateTimeDelimiter}
        maskValue={maskValue}
        error={_get(errors, `${nameStartDate}`)}
        position={startPopupPosition}
        disabled={disabled}
        timeValueFormat="HH:mm:ss"
        keepTooltipInside={keepTooltipInside}
        offsetY={offsetY}
        isOptional={isOptional}
        setInitValue={setInitValue}
        isClearable={isClearable}
        initialDate={initialDate}
        popupPosition
      />
      {withSeparator && !multiline && (
        <Text rootClassName={classes.separator}>{t('TO')}</Text>
      )}
      {multiline && <Spacing />}
      <FormControlDateTimePicker
        name={nameEndDate}
        label={endLabel}
        initialValue={endDate}
        handleChange={onChange}
        classRoot={classNames({ [classes.unsetWidth]: !multiline })}
        format={format}
        dateTimeDelimiter={dateTimeDelimiter}
        maskValue={maskValue}
        error={_get(errors, `${nameEndDate}`)}
        position={endPopupPosition}
        allowDateTimeOffset={true}
        disabled={disabled}
        timeValueFormat="HH:mm:ss"
        keepTooltipInside={keepTooltipInside}
        offsetY={offsetY}
        isOptional={isOptional}
        setInitValue={setInitValue}
        isClearable={isClearable}
        initialDate={moment().endOf('day')}
      />
    </div>
  )
}

FormControlDateTimeRangePicker.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

export default withTranslation('translations')(
  withStyles(styles)(FormControlDateTimeRangePicker)
)

import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { createSuccessInvalidator } from 'utils/apiUtils'

export const magicFolderApi = createAppApi('magicFoldersQuery', {
  tagTypes: [apiTags.magicFoldersPlaylist],
  endpoints: builder => ({
    postMagicFolderPlaylist: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.magicFoldersPlaylist]),
      query: data => ({
        method: 'POST',
        url: '/playlist/onedrive',
        data
      })
    }),
    putMagicFolderPlaylist: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.magicFoldersPlaylist]),
      query: ({ id, data }) => ({
        method: 'PUT',
        url: `/playlist/onedrive/${id}`,
        data
      })
    })
  })
})

export const {
  usePostMagicFolderPlaylistMutation,
  usePutMagicFolderPlaylistMutation
} = magicFolderApi

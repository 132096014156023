import { useCallback, useEffect } from 'react'

import { instanceTypes, vuePreviews } from 'constants/api'
import { VUE_PREVIEW } from 'constants/localStorage'
import { useLazyGetDocsQuery } from '../../api/appApi'
import { useUserRole } from '../tableLibrary'

const VUE_API_TRIGGER = 'toggleVuePreview'
const DOCS_TRIGGER = 'getDocs'

const toggleVuePreview = () => {
  const currentPreview = localStorage.getItem(VUE_PREVIEW)
  let enabledPreview

  if (!currentPreview || currentPreview === vuePreviews.v2) {
    enabledPreview = vuePreviews.v3
  } else {
    enabledPreview = vuePreviews.v2
  }

  localStorage.setItem(VUE_PREVIEW, enabledPreview)

  // eslint-disable-next-line
  console.info(`Enabled ${enabledPreview} preview`)
}

export default function useWindowMessageListeners() {
  const role = useUserRole()
  const [getDocs, docsQuery] = useLazyGetDocsQuery()

  const onMessageReceive = useCallback(
    ({ data }) => {
      if (
        data === VUE_API_TRIGGER &&
        [instanceTypes.dev, instanceTypes.local].includes(
          process.env.REACT_APP_INSTANCE
        )
      ) {
        toggleVuePreview()
      } else if (
        data === DOCS_TRIGGER &&
        [instanceTypes.dev, instanceTypes.local].includes(
          process.env.REACT_APP_INSTANCE
        ) &&
        role.system
      ) {
        // eslint-disable-next-line
        console.info('Fetching docs. Please wait')
        getDocs()
      }
    },
    [role, getDocs]
  )

  useEffect(
    () => {
      window.addEventListener('message', onMessageReceive)
      return () => {
        window.removeEventListener('message', onMessageReceive)
      }
    },
    // eslint-disable-next-line
    [onMessageReceive]
  )

  useEffect(() => {
    if (docsQuery.error) {
      console.error('Failed to fetch docs')
    }

    if (docsQuery.data) {
      document.open()
      document.write(docsQuery.data)
      document.close()
    }
  }, [docsQuery])
}

import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DayPickerSingleDateController } from 'react-dates'
import { Grid, withStyles, withTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Popup from 'components/Popup'
import FormControlLabel from 'components/Form/FormControlLabel'
import { BlueButton } from 'components/Buttons'
import { FormControlChips, FormControlInput } from 'components/Form'
import Spacing from 'components/Containers/Spacing'
import { simulateEvent } from 'utils'
import { PopupPosition } from 'utils/styles'
import { DATE_TIME_VIEW_FORMAT } from 'constants/dateTimeFormats'
import { popupPosition } from 'constants/stylesConstants'

import 'react-dates/lib/css/_datepicker.css'
import '../../styles/forms/_datepicker.scss'

const styles = ({ colors }) => {
  return {
    root: {
      zIndex: 1 //popup fix
    },
    inputIcon: {
      color: colors.light,
      position: 'absolute',
      right: 10,
      top: 8,
      cursor: 'pointer'
    },
    inputWrapper: {
      position: 'relative'
    }
  }
}

const popupOverlayStyle = {
  zIndex: 10
}

const popupContentStyle = {
  padding: 0,
  zIndex: 11,
  width: 'min-content'
}

const FormControlMultipleDateTimePicker = ({
  classes,
  onChange = f => f,
  values,
  withTime = true,
  format = DATE_TIME_VIEW_FORMAT,
  position = popupPosition.TOP_CENTER,
  customClasses = {},
  classRoot,
  disabled,
  name,
  fromCurrentDate = true,
  offsetY,
  isFutureDisabled = false,
  timeValueFormat = 'HH:mm',
  theme,
  label,
  isOptional,
  ...restProps
}) => {
  const { t } = useTranslation()

  const [value, setValue] = useState('')
  const [date, setDate] = useState(moment())
  const [time, setTime] = useState(
    moment().startOf('day').format(timeValueFormat)
  )

  const handleDateChange = useCallback(
    e => {
      setDate(e)
    },
    [setDate]
  )

  const handleAdd = useCallback(() => {
    onChange(
      simulateEvent(name, [
        ...(Array.isArray(values) ? values : []),
        { label: value, value }
      ])
    )
    setTime(moment().startOf('day').format(timeValueFormat))
  }, [values, value, timeValueFormat, name, onChange])

  const changeValue = useCallback(() => {
    if (withTime && time) {
      const [hours, minutes, seconds] = time.split(':')

      const currentDate = date.clone()

      currentDate.hours(hours).minutes(minutes)

      if (seconds) {
        currentDate.seconds(seconds)
      }

      setValue(currentDate.format(format))
    } else {
      setValue(date.format(format))
    }
  }, [withTime, time, setValue, format, date])

  useEffect(() => {
    changeValue()
    // eslint-disable-next-line
  }, [date])

  useEffect(() => {
    if (value) changeValue()
    // eslint-disable-next-line
  }, [time])

  return (
    <Grid container className={classNames(classes.root, classRoot)}>
      <FormControlLabel
        label={label}
        isOptional={isOptional}
        rootClassName={customClasses.labelClass}
      />
      <Grid
        container
        className={classNames(classes.inputWrapper, customClasses.inputWrapper)}
      >
        <FormControlChips
          isMulti
          handleChange={onChange}
          name={name}
          values={values}
          marginBottom={false}
          disabled={disabled}
          components={{
            DropdownIndicator: () => <i />,
            Menu: () => null
          }}
          {...restProps}
        />
        <Popup
          on="click"
          offsetY={offsetY}
          position={position}
          disabled={disabled}
          overlayStyle={popupOverlayStyle}
          contentStyle={popupContentStyle}
          trigger={
            <i
              className={classNames(
                'fa-regular fa-calendar-days',
                classes.inputIcon
              )}
            />
          }
        >
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className={classNames({
              'DatePicker-dark': theme.type === 'dark'
            })}
          >
            <DayPickerSingleDateController
              date={date}
              hideKeyboardShortcutsPanel
              onDateChange={handleDateChange}
              isOutsideRange={day =>
                fromCurrentDate
                  ? isFutureDisabled
                    ? moment().diff() < 0
                    : moment()
                        .startOf('day')
                        .diff(moment(day).startOf('day'), 'days') > 0
                  : false
              }
            />
            <Spacing
              variant={0}
              direction="row"
              justify={withTime ? 'space-between' : 'flex-end'}
              paddingHor={1}
              paddingVert={1}
            >
              {withTime && (
                <FormControlInput
                  marginBottom={false}
                  type="time"
                  value={time}
                  onChange={e => setTime(e.target.value)}
                />
              )}
              <BlueButton
                onClick={handleAdd}
                iconClassName="fa-regular fa-circle-plus"
                disabled={(values || []).some(chip => chip.value === value)}
              >
                {t('Add')}
              </BlueButton>
            </Spacing>
          </Grid>
        </Popup>
      </Grid>
    </Grid>
  )
}

FormControlMultipleDateTimePicker.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  values: PropTypes.array,
  format: PropTypes.string,
  withTime: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  fullWidth: PropTypes.bool,
  position: PopupPosition,
  customClasses: PropTypes.object,
  classRoot: PropTypes.string,
  disabled: PropTypes.bool,
  fromCurrentDate: PropTypes.bool,
  offsetY: PropTypes.number,
  isFutureDisabled: PropTypes.bool,
  timeValueFormat: PropTypes.string,
  label: PropTypes.string,
  isOptional: PropTypes.bool
}

export default withTheme(withStyles(styles)(FormControlMultipleDateTimePicker))

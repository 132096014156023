import React, { useEffect, useCallback, useRef, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import {
  Typography,
  Grid,
  withStyles,
  Dialog,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { BlueButton } from 'components/Buttons'
import { sortedLanguages } from 'constants/languages'
import { getLanguage, setLanguage } from 'utils/language'
import Scrollbars from 'components/Scrollbars'
import useEscKeyDownListener from 'hooks/useEscKeyDownListener'
import { clearWeatherStorage } from 'utils/weather'

const styles = theme => {
  const { type, palette, fontSize, fontWeight, lineHeight } = theme
  return {
    dialogContainer: {
      background: 'transparent',
      maxWidth: '1100px',
      width: '100%',
      marginTop: '80px'
    },
    headerContainer: {
      margin: '0px',
      padding: '0px',
      backgroundColor: palette[type].card.greyHeader.background,
      borderBottom: `1px solid ${palette[type].pages.adminSettings.content.border}`
    },
    header: {
      padding: '10px 20px 5px 20px',
      height: palette[type].dialog.header.height
    },
    title: {
      fontSize: fontSize.big,
      lineHeight: lineHeight.big,
      fontWeight: fontWeight.bold,
      color: palette[type].dialog.title
    },
    closeButton: {
      position: 'relative',
      color: palette[type].dialog.closeButton
    },

    contentRoot: {
      padding: '0px',
      backgroundColor: palette[type].languageSelector.background
    },
    content: {
      display: 'grid',
      grid: 'repeat(1,1fr) / repeat(7,1fr)',
      gap: '32px',
      width: '100%',
      padding: '32px',
      backgroundColor: palette[type].languageSelector.background
    },

    languageContainer: {
      height: 'fit-content',
      maxHeight: '50px',
      maxWidth: '140px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    singleLanguageContainer: {
      cursor: 'pointer',
      background: 'transparent',
      transition: 'opacity 400ms',
      opacity: 0.75,
      '&:hover': {
        opacity: 1,
        '& > p': {
          fontWeight: 'bold',
          color: palette[type].card.titleColor
        }
      }
    },
    selectedLanguage: {
      opacity: 1,
      '& > p': {
        fontWeight: 'bold',
        color: palette[type].card.titleColor
      }
    },

    flag: {
      width: 36,
      color: palette[type].header.account.color
    },
    languageName: {
      fontSize: '13px',
      paddingLeft: '12px',
      transition: 'all 400ms',
      color: palette[type].languageSelector.color
    },

    actionBar: {
      height: '55px',
      margin: '0px',
      padding: '0 20px',
      backgroundColor: palette[type].sideModal.action.background,
      borderTop: `1px solid ${palette[type].pages.adminSettings.content.border}`
    },
    actionButton: {
      margin: '0px',
      '&:first-child': {
        marginRight: '16px'
      }
    },
    actionLabel: {
      fontSize: '13px',
      lineHeight: '22px'
    },
    scrollbarRoot: {
      minHeight: '588px',
      maxHeight: 'calc(100vh - 370px) !important'
    }
  }
}

const LanguageSelector = ({ t, classes, setLanguageSelectorIsOpen, route }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const initialLanguage = useMemo(() => getLanguage(), [])
  const isFirstRun = useRef(true)

  const closeModal = useCallback(() => setLanguageSelectorIsOpen(false), [
    setLanguageSelectorIsOpen
  ])

  useEscKeyDownListener(closeModal)

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    closeModal()
    //eslint-disable-next-line
  }, [route])
  // eslint-disable-next-line
  useEffect(() => void setSelectedLanguage(initialLanguage), [])

  const onLanguageChange = useCallback(
    async code => {
      await clearWeatherStorage()
      await setLanguage(code)
      closeModal()
    },
    [closeModal]
  )

  return (
    <Dialog
      open
      onClose={closeModal}
      maxWidth={'md'}
      classes={{ paper: classes.dialogContainer }}
    >
      <DialogTitle disableTypography className={classes.headerContainer}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.header}
        >
          <Typography variant="h1" className={classes.title}>
            {t('Select Language')}
          </Typography>
          <IconButton className={classes.closeButton} onClick={closeModal}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent classes={{ root: classes.contentRoot }}>
        <Scrollbars className={classes.scrollbarRoot}>
          <Grid
            container
            direction="row"
            wrap="wrap"
            justify="flex-start"
            alignItems="center"
            className={classes.content}
          >
            {sortedLanguages.map(({ code, icon, alias }) => (
              <Grid
                key={code}
                item
                xs={3}
                className={classes.languageContainer}
              >
                <Grid
                  container
                  justify="flex-start"
                  direction="row"
                  alignItems="center"
                  wrap="nowrap"
                  className={[
                    classes.singleLanguageContainer,
                    code === selectedLanguage ? classes.selectedLanguage : ''
                  ].join(' ')}
                  onClick={() => setSelectedLanguage(code)}
                >
                  <img src={icon} className={classes.flag} alt={alias} />
                  <Typography className={classes.languageName}>
                    {alias}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Scrollbars>
      </DialogContent>

      <DialogActions className={classes.actionBar}>
        <BlueButton
          classes={{ root: classes.actionButton, label: classes.actionLabel }}
          onClick={() => onLanguageChange(selectedLanguage)}
          iconClassName="fa-regular fa-circle-check"
        >
          {t('Save')}
        </BlueButton>
      </DialogActions>
    </Dialog>
  )
}

LanguageSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  setLanguageSelectorIsOpen: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired
}

export default withTranslation('translations')(
  withStyles(styles)(LanguageSelector)
)

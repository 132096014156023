import React from 'react'
import { Grid, Link, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Text } from 'components/Typography'

const useStyles = makeStyles(() => ({
  errorSubDescriptionText: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    maxWidth: '90%'
  },
  linkText: {
    cursor: 'pointer',
    color: 'white'
  },
  marginRight: {
    marginRight: 5
  }
}))

const ErrorSubDescription = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container item alignItems="center" direction="column">
      <Grid container item justify="center">
        <Text
          rootClassName={classNames(
            classes.errorSubDescriptionText,
            classes.marginRight
          )}
          color="white"
        >
          {t(
            'If the problem persists, please report this error message to the'
          )}
        </Text>
        <Link
          className={classNames(
            classes.errorSubDescriptionText,
            classes.linkText
          )}
          href="https://mvix.com/submit-a-ticket/"
          target="_blank"
        >
          Client Success Team
        </Link>
        <Text rootClassName={classes.errorSubDescriptionText} color="white">
          .
        </Text>
      </Grid>
    </Grid>
  )
}

export default ErrorSubDescription

import { HTML_CONTENT, libraryViews } from 'constants/library'
import featureConstants from 'constants/featureConstants'
import { viewTypes } from './libraryConstants'

const {
  Feeds,
  RSSFeed,
  DemoFeeds,
  MediaRSS,
  YouTube,
  Radio,
  CustomWidget,
  News,
  Twitch,
  VideoTutorials
} = featureConstants

const systemPrefix = '/system'

const libraryGroups = {
  workplacePoster: `${systemPrefix}/workplace-posters-library`,
  packageLibrary: `${systemPrefix}/packages-library`,
  [HTML_CONTENT]: `${systemPrefix}/html-contents-library`,
  [RSSFeed]: `${systemPrefix}/rss-feeds-library`,
  [Feeds]: `${systemPrefix}/feeds`,
  [DemoFeeds]: `${systemPrefix}/demo-feeds`,
  [MediaRSS]: `${systemPrefix}/media-rss`,
  [YouTube]: `${systemPrefix}/youtube`,
  [Radio]: `${systemPrefix}/radio`,
  [CustomWidget]: `${systemPrefix}/custom-widget`,
  [News]: `${systemPrefix}/news`,
  [Twitch]: `${systemPrefix}/twitch`,
  [VideoTutorials]: `${systemPrefix}/tutorial-videos-library`
}

const generateLibraryRoutes = prefix => ({
  root: libraryGroups[prefix],
  add: libraryGroups[prefix] + '/add',
  tags: libraryGroups[prefix] + '/tags',
  edit: libraryGroups[prefix] + '/:id/edit',
  categories: libraryGroups[prefix] + '/categories'
})

const libraryRoutes = Object.keys(libraryGroups).reduce(
  (accum, prefix) => ({
    ...accum,
    [prefix]: generateLibraryRoutes(prefix)
  }),
  {}
)

const routeByName = {
  ...libraryRoutes,
  users: {
    root: 'users-library',
    add: 'users-library/add',
    groups: 'users-library/group',
    edit: 'users-library/:id/edit',
    permissions: 'users-library/:id/permissions/:entity',
    groupsPermission: 'users-library/group/:id/permissions/:entity',
    clone: 'users-library/:id/clone'
  },
  user: {
    userProfile: 'user-profile'
  },
  eula: {
    root: 'eula-terms',
    add: 'eula-terms/add',
    edit: 'eula-terms/:id/edit'
  },

  clientUsers: {
    root: `${systemPrefix}/org-client-users`,
    add: `${systemPrefix}/org-client-users/add`,
    edit: `${systemPrefix}/org-client-users/:id/edit`,
    eulaClientRoot: `${systemPrefix}/org-client-user`,
    eulaTerms: `${systemPrefix}/org-client-user/:id/eula-terms`
  },
  licenses: {
    root: `${systemPrefix}/licenses`
  },
  clients: {
    root: 'clients-library',
    edit: 'clients-library/:id/edit',
    notes: (clientId = ':id') => `clients-library/note/${clientId}`,
    licenses: (clientId = ':id') => `clients-library/license/${clientId}`,
    billing: (clientId = ':id') => `clients-library/billing/${clientId}`,
    add: 'clients-library/add',
    groups: 'clients-library/groups',
    superAdminSettings: 'clients-library/:id/super-admin-settings',
    WLSuperAdminSettings:
      'clients-library/:id/white-label-super-admin-settings',
    superAdminSettingsWithId: id =>
      `clients-library/${id}/super-admin-settings`,
    WLSuperAdminSettingsWithId: id =>
      `clients-library/${id}/white-label-super-admin-settings`,
    editWithId: id => `clients-library/${id}/edit`
  },
  playlist: {
    root: 'playlist-library',
    edit: 'playlist-library/:playlistId/edit',
    create: 'playlist-library/create',
    groups: 'playlist-library/groups',
    interactive: 'playlist-library/interactive-playlist/create',
    editInteractive: 'playlist-library/interactive-playlist/:playlistId/edit',
    smart: 'playlist-library/smart-playlist',
    editSmart: 'playlist-library/smart-playlist/:playlistId/edit',
    magicFolders: 'playlist-library/magic-folders-playlist',
    editMagicFolders:
      'playlist-library/magic-folders-playlist/:playlistId/edit',
    editInteractivePlaylist: id =>
      `playlist-library/interactive-playlist/${id}/edit`,
    editSmartPlaylist: id => `playlist-library/smart-playlist/${id}/edit`,
    editMagicFolderPlaylist: id =>
      `playlist-library/magic-folders-playlist/${id}/edit`,
    editWithId: id => `playlist-library/${id}/edit`
  },
  schedule: {
    root: 'schedule-library',
    list: 'schedule-library/list',
    calendar: 'schedule-library/calendar',
    edit: 'schedule-library/list/:scheduleId/edit',
    editCalendar: 'schedule-library/calendar/:scheduleId/edit',
    groups: 'schedule-library/groups',
    groupsList: 'schedule-library/list/groups',
    groupsGrid: 'schedule-library/calendar/groups',
    publish: 'schedule-library/schedule-publish',
    calendarPublish: 'schedule-library/calendar/schedule-publish',
    editWithId: id => `schedule-library/list/${id}/edit`,
    editCalendarWithId: id => `schedule-library/calendar/${id}/edit`,
    timeline: 'schedule-timeline',
    timelineEdit: 'schedule-timeline/edit/:scheduleId',
    editTimelineWithId: id => `schedule-timeline/edit/${id}`,
    clone: 'schedule-library/list/:scheduleId/clone',
    cloneWithId: id => `schedule-library/list/${id}/clone`,
    cloneCalendar: 'schedule-library/calendar/:scheduleId/clone',
    cloneCalendarWithId: id => `schedule-library/calendar/${id}/clone`
  },
  template: {
    root: 'template-library',
    list: 'template-library/list',
    grid: 'template-library/grid',
    groups: 'template-library/list/groups',
    groupsGrid: 'template-library/grid/groups',
    groupsAdvancedList: `template-library/${libraryViews.advancedList}/groups`,
    create: 'template-library/list/create-template',
    createGrid: 'template-library/grid/create-template',
    createAdvancedList: `template-library/${libraryViews.advancedList}/create-template`,
    preset: 'template-library/list/create-template/preset',
    presetGrid: 'template-library/grid/create-template/preset',
    edit: 'template-library/list/create-template/:id?',
    editWithId: id => `template-library/list/create-template/${id}`,
    editGrid: 'template-library/grid/create-template/:id?',
    editGridWithId: id => `template-library/grid/create-template/${id}`,
    templateGallery: 'template-library/list/create-template/template-gallery',
    advancedList: `template-library/${libraryViews.advancedList}`,
    editAdvancedList: `template-library/${libraryViews.advancedList}/create-template/:id?`,
    editAdvancedListWithId: id =>
      `template-library/${libraryViews.advancedList}/create-template/${id}`
  },
  templateBackground: {
    root: 'template-background',
    list: 'template-background/list',
    grid: 'template-background/grid',
    create: 'template-background/list/create-background',
    createGrid: 'template-background/grid/create-background',
    edit: 'template-background/list/edit-background/:id',
    editGrid: 'template-background/grid/edit-background/:id',
    editWithId: id => `template-background/list/edit-background/${id}`,
    editWithIdGrid: id => `template-background/grid/edit-background/${id}`
  },
  device: {
    root: 'device-library',
    list: 'device-library/list',
    grid: `device-library/${viewTypes.GRID}`,
    location: `device-library/${viewTypes.LOCATION}`,
    toLibrary: (view = 'list') => `device-library/${view}`,
    listGridAdvanced: `device-library/:view(list|${viewTypes.GRID}|${libraryViews.advancedList})`,
    advancedList: `device-library/${libraryViews.advancedList}`,
    //for Groups modal
    'advanced-list': `device-library/${libraryViews.advancedList}`,
    edit: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/edit/:id`,
    clone: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/clone/:id`,
    cloneGrid: 'device-library/grid/clone/:id',
    cloneLocation: 'device-library/location/clone/:id',
    goToAdd: (view = 'list') => `device-library/${view}/add-device`,
    goToEdit: (id, view = 'list') => `device-library/${view}/edit/${id}`,
    goToClone: (id, view = 'list') => `device-library/${view}/clone/${id}`,
    editGrid: 'device-library/grid/edit/:id',
    editLocation: 'device-library/location/edit/:id',
    goToEditGrid: id => `device-library/grid/edit/${id}`,
    goToCloneGrid: id => `device-library/grid/clone/${id}`,
    goToEditLocation: id => `device-library/location/edit/${id}`,
    goToCloneLocation: id => `device-library/location/clone/${id}`,
    editCloseLink: backTo => `device-library/${backTo}`,
    add: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/add-device`,
    addGrid: 'device-library/grid/add-device',
    addLocation: 'device-library/location/add-device',
    screenPreview: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/screen-previews`,
    toScreenPreview: (view = 'list') =>
      `device-library/${view}/screen-previews`,
    channelsPreview: 'device-library/grid/channel-previews',
    groups: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/groups`,
    toGroups: (view = 'list') => `device-library/${view}/groups`,
    groupsList: 'device-library/list/groups',
    groupsGrid: 'device-library/grid/groups',
    groupsLocation: 'device-library/location/groups',
    note: 'device-library/(list|grid|location)/note/:id',
    goToNote: (id, view = 'list') => `device-library/${view}/note/${id}`,
    goToHealth: (id, view = 'list') =>
      `device-library/${view}/device-health/${id}`,
    health: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/device-health/:id`,
    healthGrid: 'device-library/grid/device-health/:id',
    healthLocation: 'device-library/location/device-health/:id',
    healthCloseLink: backTo => `device-library/${backTo}`,
    alerts: {
      set: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/set-alerts`,
      match: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/set-alerts/:id`,
      getByName: (name, view = 'list') =>
        `device-library/${view}/set-alerts/${name}`
    },
    viewerMetrics: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/viewer-metrics/:id`,
    viewerMetricsGrid: 'device-library/grid/viewer-metrics/:id',
    viewerMetricsLocation: 'device-library/location/viewer-metrics/:id',
    goToViewerMetrics: (id, view) =>
      `device-library/${view}/viewer-metrics/${id}`,
    viewerMetricsCloseLink: backTo => `device-library/${backTo}`,
    goToIpProviders: (view = 'list') => `device-library/${view}/ip-providers`,
    ipProviders: `device-library/:view(list|${viewTypes.GRID}|${viewTypes.LOCATION}|${libraryViews.advancedList})/ip-providers`,
    ipProvidersGrid: 'device-library/grid/ip-providers'
  },
  deviceNOC: {
    root: 'device-noc',
    overview: 'device-noc/overview',
    preview: 'device-noc/preview',
    health: 'device-noc/health',
    diagram: 'device-noc/diagram',
    uptime: 'device-noc/uptime',
    alertAlarm:
      'device-noc/:viewType(overview|preview|health|diagram|uptime)/alert-alarm',
    goToAlertAlarm: viewType => `device-noc/${viewType}/alert-alarm`
  },
  deviceTypes: {
    root: 'device-types',
    list: 'device-types/list',
    grid: 'device-types/grid',
    add: 'device-types/list/add-type',
    edit: 'device-types/list/edit/:id',
    goToEdit: id => `device-types/list/edit/${id}`,
    editCloseLink: backTo => `device-types/${backTo}`,
    deviceConfigurations: 'device-types/list/device-configurations'
  },
  deviceModels: {
    root: 'device-models',
    list: 'device-models/list',
    grid: 'device-models/grid',
    add: 'device-models/list/add',
    edit: 'device-models/list/edit/:id',
    goToEdit: id => `device-models/list/edit/${id}`,
    editCloseLink: backTo => `device-models/${backTo}`
  },
  designGallery: {
    root: 'design-gallery',
    addDesign: 'design-gallery/design',
    list: 'design-gallery/list',
    edit: 'design-gallery/design/:id',
    goToEdit: id => `design-gallery/design/${id}`
  },
  fontLibrary: {
    root: 'font-library',
    add: 'font-library/add-font'
  },
  appVersions: {
    root: 'application-library',
    list: 'application-library/list',
    add: 'application-library/list/add',
    edit: 'application-library/list/edit/:id',
    goToEdit: id => `application-library/list/edit/${id}`,
    editCloseLink: backTo => `application-library/${backTo}`
  },
  clientLicenses: {
    root: 'client-licenses',
    add: 'client-licenses/add',
    edit: 'client-licenses/edit/:id',
    goToEdit: id => `client-licenses/edit/${id}`
  },
  media: {
    root: 'media-library',
    list: 'media-library/list',
    grid: `media-library/${viewTypes.GRID}`,
    groupsList: 'media-library/list/groups',
    groupsGrid: 'media-library/grid/groups',
    create: 'media-library/list/media/add/general/file',
    createGrid: `media-library/grid/media/add/general/file`,
    edit: 'media-library/media/edit/:mode/:currentTab/:id',
    addEdit: 'media/edit/:mode/:ownTabName',
    clone: 'media-library/media/clone/:mode/:currentTab/:id',
    favoriteList: 'media-library/list/media/add/favorite',
    favoriteGrid: 'media-library/grid/media/add/favorite'
  },
  tutorialVideos: {
    root: 'tutorial-videos',
    create: 'tutorial-videos-library/media/add',
    edit: 'tutorial-videos-library/media/edit:id'
  },
  room: {
    root: 'room-library',
    create: 'room-library/add',
    edit: 'room-library/edit/:id',
    goToEdit: id => `room-library/edit/${id}`
  },
  dashboard: {
    root: `${systemPrefix}/dashboard`
  },
  tag: {
    root: 'tags-library'
  },
  menuMaker: {
    root: 'menu-maker-library',
    add: 'menu-maker-library/:variant/add',
    goToAdd: variant => `menu-maker-library/${variant}/add`,
    edit: `menu-maker-library/:variant/edit/:id`,
    goToEdit: (variant, id) => `menu-maker-library/${variant}/edit/${id}`,
    integrations: 'menu-maker-library/integrations',
    integrationProvider: `menu-maker-library/integrations/:provider`,
    goToProvider: provider => `menu-maker-library/integrations/${provider}`,
    addDesign: 'menu-maker-library/design',
    editDesign: 'menu-maker-library/design/:id/edit',
    goToEditDesign: id => `menu-maker-library/design/${id}/edit`,
    editPreset: 'menu-maker-library/design/preset/:presetId',
    goToEditPreset: id => `menu-maker-library/design/preset/${id}`,
    viewDesign: 'menu-maker-library/design/:id/',
    goToViewDesign: id => `menu-maker-library/design/${id}`
  },
  report: {
    root: 'report-library',
    create: 'report-library/create',
    edit: 'report-library/edit/:id',
    goToEdit: id => `report-library/edit/${id}`,
    generate: 'report-library/generate/:id?',
    goToGenerate: id => `report-library/generate/${id ? id : ''}`,
    download: 'report/:reportId/file/:fileId'
  },
  notifications: {
    root: 'notifications-library',
    add: 'notifications-library/add',
    edit: 'notifications-library/edit/:id',
    goToEdit: id => `notifications-library/edit/${id}`,
    editCloseLink: backTo => `notifications-library/${backTo}`,
    createEmailTemplate: 'notifications-library/email-template',
    editCloneEmailTemplate: 'notifications-library/email-template/:mode/:id',
    goToEditEmailTemplate: id =>
      `notifications-library/email-template/edit/${id}`,
    goToCloneEmailTemplate: id =>
      `notifications-library/email-template/clone/${id}`
  },
  reportApiUsage: {
    root: 'reports-api-usage'
  },
  thirdPartyReport: {
    root: 'third-party-reports'
  },
  proofOfPlayReports: {
    root: 'proof-of-play-reports'
  },
  contentApprove: {
    root: '/content-approve'
  },
  signIn: {
    root: '/sign-in'
  },
  reseller: {
    client: '/reseller/clients-library',
    clientUser: '/reseller/client-users-library',
    user: '/reseller/users-library'
  },
  public: {
    root: `/public/:token`,
    deviceNoc: {
      root: `device-noc`,
      overview: 'device-noc/overview',
      preview: 'device-noc/preview',
      health: 'device-noc/health',
      diagram: 'device-noc/diagram',
      uptime: 'device-noc/uptime'
    }
  },
  features: {
    root: 'feature-management',
    edit: 'feature-management/:id/edit',
    goToEdit: id => `feature-management/${id}/edit`
  },
  designGalleryCategories: {
    root: 'media-library/:view/design-gallery-categories',
    goToRoot: view => `media-library/${view}/design-gallery-categories`
  },
  timeoutError: '/timeout-error',
  serverError: '/server-error',
  maintenanceError: '/maintenance-error',
  activateDevice: '/activate-device'
}

export const getPublicRoute = url => {
  return `/public/:token/${url}`
}

export default routeByName

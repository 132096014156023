import React, { memo, useCallback } from 'react'
import { Divider, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import {
  DropdownButtonListItem,
  DropdownHoverListItem,
  DropdownHoverListItemIcon,
  DropdownHoverListItemText
} from 'components/Dropdowns'
import List from 'components/List'
import ProcessingFlashDots from 'components/Pages/Media/ProcessingFlashDots'

const styles = ({ typography, palette, type }) => ({
  value: {
    color: palette[type].tagCard.item.color,
    fontFamily: typography.fontFamily,
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '-0.01px',
    lineHeight: '15px',
    padding: '5px 0 0 5px'
  },
  rowActionList: {
    background: palette[type].tableLibrary.body.row.dropdown.list.background,
    overflow: 'hidden',
    borderRadius: 6,
    minWidth: 170
  },
  disabled: {
    textAlign: 'left',
    padding: '5px 16px',
    borderBottom: `1px solid ${palette[type].tableLibrary.body.row.dropdown.list.borderBottom}`,
    opacity: 0.5,
    display: 'flex',
    alignItems: 'center',
    color: palette[type].tableLibrary.body.row.dropdown.list.disabled
  },
  icon: {
    fontSize: 17
  }
})

const ActionLinksDropdown = ({
  actionLinks,
  classes,
  activeValueClass = ''
}) => {
  const setProps = useCallback((action, index) => {
    const key = `row-action-${index}`
    const component = action.clickAction ? 'button' : Link
    const disabled = action.disabled
    const clickAction = action.clickAction ? action.clickAction : f => f

    const onClick = e => {
      e._fromAction = true
      clickAction(e)
    }
    const to = {
      pathname:
        action.to && action.to.pathname ? action.to.pathname : action.to,
      data: action.data || undefined
    }

    const target = action.target || undefined

    return {
      key,
      component,
      to,
      target,
      onClick,
      disabled
    }
  }, [])
  const renderContent = useCallback(
    action => (
      <>
        {action?.icon && (
          <DropdownHoverListItemIcon>
            <i className={classNames(action.icon, classes.icon)} />
          </DropdownHoverListItemIcon>
        )}
        {action?.value !== undefined ? (
          <span className={classNames(classes.value, activeValueClass)}>
            {action.value}
          </span>
        ) : null}
        {action.loading ? (
          <ProcessingFlashDots />
        ) : (
          <DropdownHoverListItemText
            primary={action?.label}
            className="DropdownHoverListItemText"
          />
        )}
      </>
    ),
    [classes, activeValueClass]
  )

  return (
    <List component="nav" disablePadding className={classes.rowActionList}>
      {actionLinks
        .filter(action =>
          action.hasOwnProperty('render') ? action.render : true
        )
        .map((action, index) => {
          if (action.divider) {
            return <Divider key={`row-action-${index}`} />
          } else if (action.disabled) {
            return (
              <div
                className={classes.disabled}
                key={index}
                onClick={e => e.stopPropagation()}
              >
                {renderContent(action)}
              </div>
            )
          } else if (action.isDropDownButton) {
            return (
              <DropdownButtonListItem {...setProps(action, index)}>
                {renderContent(action)}
              </DropdownButtonListItem>
            )
          } else {
            return (
              <DropdownHoverListItem
                {...setProps(action, index)}
                className="DropdownHoverListItemText"
              >
                {renderContent(action)}
              </DropdownHoverListItem>
            )
          }
        })}
    </List>
  )
}

export default withStyles(styles)(memo(ActionLinksDropdown))

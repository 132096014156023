import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { _debounce, _uniqBy, _get, _differenceWith } from 'utils/lodash'

import FormControlReactSelect from './FormControlReactSelect'
import { simulateEvent } from 'utils/formik'
import { sortByLabel } from 'utils/libraryUtils'
import { storedOptionsSelector } from 'selectors/new/filterSelectors'
import { setStoredOptions } from 'actions/filtersActions'

const ClearIndicator = ({ onClick, innerValue, innerProps, ...props }) => {
  return (
    <>
      {innerValue && (
        <components.ClearIndicator
          innerProps={{
            ...innerProps,
            onMouseDown: onClick,
            onTouchEnd: onClick
          }}
          {...props}
        />
      )}
    </>
  )
}

const styles = ({ colors }) => ({
  errorText: {
    margin: 0,
    marginTop: -15,
    color: colors.error,
    fontSize: 10,
    lineHeight: 1.5
  }
})

const FormControlAutocompleteNew = ({
  validationFunc = () => true,
  validationErrorMessage = '',
  getOptions = f => f,
  limit = 20,
  withResetValue = false,
  selectComponent: SelectComponent = FormControlReactSelect,
  isSearchable = true,
  isResettable = false,
  value,
  components,
  role = '',
  initialResponse = {
    data: [],
    meta: { currentPage: 1 },
    error: ''
  },
  initialFetchValue,
  classes,
  name,
  onChange,
  uniqueOptions = false,
  isClearable = false,
  isSort = false,
  staticRequestParams = {},
  onFocus,
  createdValue,
  hideOptions = [],
  hideOptionsStrict = [],
  optionsDependency,
  setResponseData,
  staticOptions = [],
  isGroupOptions = false,
  groupName,
  staticGroupOptions = [],
  groupParser,
  storedDataTransformer,
  ...props
}) => {
  const dispatch = useDispatch()

  const storedOptions = useSelector(storedOptionsSelector)

  const [{ data, meta, error }, setResponse] = useState(initialResponse)
  const [initialFetchData, setInitialFetchData] = useState([])
  const [innerValue, setInnerValue] = useState(initialResponse.meta.value || '')
  const [validationError, setValidationError] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(
    (value, page = 1) => {
      if (getOptions) {
        setLoading(true)

        getOptions(value, { limit, page, ...staticRequestParams }).then(
          ({ data, meta, error }) => {
            setResponse(prevState =>
              error
                ? {
                    data: [],
                    meta: { currentPage: 1 },
                    error: ''
                  }
                : {
                    data:
                      meta.currentPage > 1
                        ? [...prevState.data, ...data]
                        : data,
                    meta,
                    error: ''
                  }
            )
            setLoading(false)
          }
        )
      }
    },
    [getOptions, limit, staticRequestParams]
  )

  useEffect(() => {
    if (data && setResponseData) {
      setResponseData(data)
    }
    //eslint-disable-next-line
  }, [data])

  const onInputChangeHandler = useMemo(
    () =>
      _debounce((value, { action }) => {
        if (!validationFunc(value || '')) {
          setValidationError(true)
        } else {
          setValidationError(false)
          action === 'input-change' && fetchData(value)
          setInnerValue(value)
        }
      }, 300),
    [fetchData, validationFunc]
  )

  const handleMenuScrollToBottom = useCallback(() => {
    const { currentPage, lastPage } = meta
    if ((!lastPage || currentPage < lastPage) && !loading) {
      fetchData(innerValue, currentPage + 1)
    }
  }, [fetchData, meta, innerValue, loading])

  const handleSearchReset = useCallback(() => {
    setInnerValue('')
    fetchData('')
    if (withResetValue) {
      onChange(simulateEvent(props.name, ''))
    }
  }, [fetchData, onChange, props, withResetValue])

  const handleChange = useCallback(
    e => {
      const { name, value, label } = e.target

      if (storedDataTransformer) {
        const [transformedValue, transformedLabel] = storedDataTransformer({
          value,
          label
        })

        dispatch(
          setStoredOptions(name, [
            { value: transformedValue, label: transformedLabel }
          ])
        )
      } else {
        dispatch(setStoredOptions(name, [{ value, label }]))
      }

      onChange(e)
    },
    [storedDataTransformer, onChange, dispatch]
  )

  const handleFocus = useCallback(
    e => {
      if (!data.length) {
        fetchData('')
      }
      if (onFocus) {
        onFocus(e)
      }
    },
    [data, fetchData, onFocus]
  )

  useEffect(
    () => {
      if (initialFetchValue) {
        const options = _get(storedOptions, name, [])
        if (
          options.length &&
          options.some(({ value }) => value === initialFetchValue)
        ) {
          setInitialFetchData(options)
        } else {
          getOptions &&
            getOptions(value, {
              limit,
              page: 1,
              exact: true,
              ...staticRequestParams
            }).then(({ data, error }) => {
              if (!error) {
                dispatch(setStoredOptions(name, data))
                setInitialFetchData(data)
              }
            })
        }
      }
    },
    // eslint-disable-next-line
    [initialFetchValue]
  )

  useEffect(() => {
    if (createdValue && createdValue.data) {
      handleSearchReset()
    }
    // eslint-disable-next-line
  }, [createdValue])

  useEffect(() => {
    if (optionsDependency) {
      setResponse({
        data: [],
        meta: { currentPage: 1 },
        error: ''
      })
    }
    // eslint-disable-next-line
  }, [optionsDependency])

  const options = useMemo(() => {
    const _options =
      initialFetchData.length || uniqueOptions
        ? sortByLabel(
            _uniqBy([...staticOptions, ...data, ...initialFetchData], 'value')
          )
        : [...staticOptions, ...data]

    if (hideOptionsStrict?.length) {
      return _differenceWith(
        _options,
        hideOptionsStrict,
        (a, b) => a.value === b.value || a.label === b.label
      )
    } else {
      return _differenceWith(_options, hideOptions, (a, b) => a.value === b)
    }
  }, [
    data,
    initialFetchData,
    uniqueOptions,
    hideOptions,
    hideOptionsStrict,
    staticOptions
  ])

  const groupedOptions = useMemo(() => {
    if (!isGroupOptions) return options

    return groupParser
      ? groupParser(options)
      : [
          ...(staticGroupOptions || []),
          {
            label: groupName || name,
            options
          }
        ]
  }, [
    options,
    isGroupOptions,
    groupName,
    name,
    staticGroupOptions,
    groupParser
  ])

  return (
    <>
      <SelectComponent
        {...props}
        createdValue={createdValue}
        name={name}
        onFocus={handleFocus}
        onChange={handleChange}
        isLoading={loading}
        handleInputChange={onInputChangeHandler}
        isSearchable={isSearchable}
        handleMenuScrollToBottom={handleMenuScrollToBottom}
        options={groupedOptions}
        error={validationError || error || props.error}
        isClearable={isClearable}
        isSort={isSort}
        components={
          isResettable
            ? {
                ...components,
                ClearIndicator: props => (
                  <ClearIndicator
                    innerValue={innerValue}
                    onClick={handleSearchReset}
                    {...props}
                  />
                )
              }
            : components
        }
        value={value}
      />
      {validationError && (
        <div className={classes.errorText}>{validationError}</div>
      )}
    </>
  )
}

FormControlAutocompleteNew.propTypes = {
  getOptions: PropTypes.func,
  initialResponse: PropTypes.shape({
    data: PropTypes.array,
    meta: PropTypes.object,
    error: PropTypes.string
  }),
  selectComponent: PropTypes.elementType,
  isSearchable: PropTypes.bool,
  isResettable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSort: PropTypes.bool,
  isCreatable: PropTypes.bool,
  withResetValue: PropTypes.bool,
  role: PropTypes.string,
  validationFunc: PropTypes.func,
  validationErrorMessage: PropTypes.string,
  limit: PropTypes.number,
  initialFetchValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  uniqueOptions: PropTypes.bool,
  staticRequestParams: PropTypes.object,
  isGroupOptions: PropTypes.bool,
  groupName: PropTypes.string,
  staticGroupOptions: PropTypes.array
}

export default withStyles(styles)(FormControlAutocompleteNew)

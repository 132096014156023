import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Spacing from 'components/Containers/Spacing'
import { Text } from 'components/Typography'
import Tooltip from 'components/Tooltip'
import { getResolution, getSize } from 'utils/mediaUtils'
import ShareIndicator from 'components/ShareIndicator'
import MediaInfoIcon from 'components/Pages/Media/MediaInfoIcon'

const useStyles = makeStyles(({ colors }) => ({
  icon: ({ iconColor }) => ({
    fontSize: 18,
    color: iconColor || colors.light
  })
}))

const MediaCardBottomInfo = ({ media, isSmallCard = false }) => {
  const { t } = useTranslation()

  const { feature } = media

  const classes = useStyles({ iconColor: feature?.color })

  const size = getSize(media)
  const resolution = getResolution(media)

  return (
    <Spacing
      direction="row"
      justify="space-between"
      borderTop={1}
      variant={0}
      paddingHor={1}
      paddingVert={1}
    >
      <Grid item container xs={4} alignItems="center">
        <Tooltip arrow title={t(feature?.alias)}>
          <i className={classNames(feature?.icon, classes.icon)} />
        </Tooltip>
        <ShareIndicator item={media} />
        <MediaInfoIcon media={media} popupPosition="bottom center" />
      </Grid>
      <Grid
        item
        container
        xs={isSmallCard ? 2 : 4}
        alignItems="center"
        justify="center"
      >
        <Text variant="small">{size && !isSmallCard ? `${size} MB` : ''}</Text>
      </Grid>
      <Grid
        item
        container
        xs={isSmallCard ? 6 : 4}
        alignItems="center"
        justify="flex-end"
      >
        <Text variant="small">
          {isSmallCard && size
            ? `${size} MB`
            : resolution
            ? resolution
            : t('Responsive')}
        </Text>
      </Grid>
    </Spacing>
  )
}

export default MediaCardBottomInfo

import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Spacing from 'components/Containers/Spacing'
import { Text } from 'components/Typography'
import Tooltip from 'components/Tooltip'
import { getResolution } from 'utils/templateUtils'
import { templateConstants } from 'constants/index'
import ShareIndicator from 'components/ShareIndicator'
import StatusInfoIcon from 'components/Icons/StatusInfoIcon'
import TemplateGalleryIndicator from 'components/Icons/TemplateGalleryIndicator'
import useStatusInfo from 'hooks/statusInfo/useStatusInfo'

const useStyles = makeStyles(({ colors }) => ({
  icon: ({ iconColor }) => ({
    fontSize: 18,
    color: iconColor || colors.light
  }),
  iconWrapperContainer: {
    width: '15px',
    height: '12px',
    transform: 'scale(1.2)'
  },
  iconWrapper: {
    display: 'block'
  }
}))

const TemplateCardBottomInfo = ({ template }) => {
  const { t } = useTranslation()
  const { getTemplateStatusInfo } = useStatusInfo()

  const { templateType } = template

  const templateTypeInfo =
    templateConstants.templateTypes[templateType] ||
    templateConstants.templateTypes.Standard

  const classes = useStyles({ iconColor: templateTypeInfo.color })

  const resolution = getResolution(template)

  return (
    <Spacing
      direction="row"
      justify="space-between"
      borderTop={1}
      variant={0}
      paddingHor={1}
      paddingVert={1}
    >
      <Grid item container xs={6} alignItems="center">
        <Tooltip arrow title={t(templateTypeInfo.title)}>
          {templateTypeInfo.iconComponent ? (
            <div className={classes.iconWrapperContainer}>
              <templateTypeInfo.iconComponent
                className={classes.icon}
                wrapperClassName={classes.iconWrapper}
              />
            </div>
          ) : (
            <i
              className={classNames(
                templateTypeInfo.iconHelperClass,
                classes.icon
              )}
            />
          )}
        </Tooltip>
        <ShareIndicator item={template} />
        <TemplateGalleryIndicator template={template} />
        <StatusInfoIcon data={template} getStatusInfo={getTemplateStatusInfo} />
      </Grid>
      <Grid item container xs={6} alignItems="center" justify="flex-end">
        <Text variant="small">{resolution ? resolution : t('Responsive')}</Text>
      </Grid>
    </Spacing>
  )
}

export default TemplateCardBottomInfo

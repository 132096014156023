import React from 'react'
import { withStyles } from '@material-ui/core'
import { Chart } from 'react-google-charts'

const styles = ({ palette, type, colors, typography }) => ({
  root: {
    width: '100%',
    height: '100%',
    borderRadius: '4px',

    '& svg': {
      '& [column-id="Active"],[column-id="Inactive"],[column-id="Other"]': {
        '& g': {
          '& text': {
            fill: colors.title.primary[type]
          }
        }
      },

      '& rect': {
        fill: palette[type].card.background
      },

      '& text': {
        fontFamily: typography.fontFamily
      }
    }
  }
})

const textStyle = { color: '#000000', fontSize: 10 }

const ReportPieChart = ({ classes, data, activeColor }) => {
  const options = {
    is3D: true,
    slices: {
      0: {
        color: activeColor,
        textStyle
      },
      1: { color: '#c1c1c1', textStyle },
      2: { color: '#FF9800', textStyle }
    },
    width: '100%',
    height: '100%',
    chartArea: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 20,
      width: '100%',
      height: '100%'
    },
    legend: {
      position: 'right',
      alignment: 'center',
      textStyle: {
        fontSize: 12
      }
    },
    pieSliceText: 'value'
  }

  return (
    <div className={classes.root}>
      <Chart
        chartType="PieChart"
        data={[['Card', 'Total Requests'], ...data]}
        options={options}
      />
    </div>
  )
}

export default withStyles(styles)(ReportPieChart)

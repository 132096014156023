import React from 'react'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Spacing from 'components/Containers/Spacing'
import Tooltip from 'components/Tooltip'
import { templateConstants } from 'constants/index'
import ShareIndicator from 'components/ShareIndicator'
import { TagChip } from 'components/Chip'
import StatusInfoIcon from 'components/Icons/StatusInfoIcon'
import useStatusInfo from 'hooks/statusInfo/useStatusInfo'

const useStyles = makeStyles(({ colors, spacing }) => ({
  icon: ({ iconColor }) => ({
    fontSize: 18,
    color: iconColor || colors.light
  }),
  zonesChipWrap: {
    marginLeft: spacing(1)
  },
  cardActionBtn: {
    minWidth: '32px',
    paddingLeft: '5px',
    paddingRight: '5px',
    boxShadow: '0 1px 0 0 rgba(216, 222, 234, 0.5)',
    color: '#0a83c8',

    '&:hover': {
      borderColor: '#1c5dca',
      backgroundColor: '#1c5dca',
      color: '#f5f6fa'
    }
  },
  paletteIcon: {
    fontSize: 18
  },
  cardActionBtnNew: {
    minWidth: '32px',
    boxShadow: '0 1px 0 0 rgba(216, 222, 234, 0.5)',
    padding: '6px',
    color: '#0a83c8',

    '&:hover': {
      borderColor: '#1c5dca',
      backgroundColor: '#1c5dca',
      color: '#f5f6fa'
    }
  }
}))

const TemplateCardBottomInfo = ({ template, onEdit }) => {
  const { t } = useTranslation()
  const { getTemplateStatusInfo } = useStatusInfo()

  const { templateType } = template

  const templateTypeInfo =
    templateConstants.templateTypes[templateType] ||
    templateConstants.templateTypes.Standard

  const classes = useStyles({ iconColor: templateTypeInfo.color })

  return (
    <Spacing
      direction="row"
      justify="space-between"
      borderTop={1}
      variant={0}
      paddingHor={1}
      paddingVert={1}
    >
      <Grid item container xs={8} alignItems="center">
        <span className={classes.zonesChipWrap}>
          <TagChip
            label={t('no of zones', {
              count: template.noOfZone
            })}
          />
        </span>
        <ShareIndicator item={template} />
        <StatusInfoIcon data={template} getStatusInfo={getTemplateStatusInfo} />
      </Grid>
      <Grid item container xs={4} alignItems="center" justify="flex-end">
        <Tooltip arrow title={t('Copy & Customize')}>
          <IconButton className={classes.cardActionBtnNew} onClick={onEdit}>
            <i
              className={classNames(
                'fa-duotone fa-palette',
                classes.paletteIcon
              )}
            />
          </IconButton>
        </Tooltip>
      </Grid>
    </Spacing>
  )
}

export default TemplateCardBottomInfo
